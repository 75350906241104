import { createSlice } from '@reduxjs/toolkit';
import { getUserFromLocalStorage } from '@/utils/authUtils';

const user = getUserFromLocalStorage();

const slice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: user,
  },
  reducers: {
    setCurrentUser(state, { payload: { user } }) {
      state.currentUser = user;
    },
  },
});

export const { setCurrentUser } = slice.actions;

export default slice.reducer;
