import { notification } from 'antd';

import api from '@/api';
import history from '@/history';
import { setGoals, setTargetGoal, setTargetOrganization } from '@/store/goal';
import { doRequest, finishRequest } from '@/store/request';
import { createErrors } from '@/actions/errorActions';
import { transformErrors } from '@/utils/errorUtils';

export const fetchTargetOrganization = (id) => async (dispatch) => {
  dispatch(doRequest('fetchTargetOrganization'));

  try {
    const { data: organization } = await api.get(`/organizations/${id}`);
    dispatch(setTargetOrganization({ target: organization }));
  } catch(error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchTargetOrganization'));
  }
};

export const fetchGoals = ({ organizationId }) => async (dispatch) => {
  dispatch(doRequest('fetchGoals'));

  try {
    const { data: items } = await api.get('/goals', {
      params: {
        organizationId,
      },
    });
    dispatch(setGoals({ items }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchGoals'));
  }
};

export const fetchTargetGoal = (userId, year) => async (dispatch) => {
  dispatch(doRequest('fetchTargetGoal'));

  try {
    const { data: goal } = await api.get(`/goals/${userId}/${year}`);
    dispatch(setTargetGoal({ target: goal }));
  } catch(error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchTargetGoal'));
  }
};

export const createGoal = (userId, data) => async (dispatch) => {
  dispatch(doRequest('createGoal'));

  try {
    await api.post(`/goals/${userId}`, data);
    notification.success({
      message: 'Create goal',
      description: 'Created goal successfully',
    });
    history.push('/goals');
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('createGoal'));
  }
};

export const updateGoal = (userId, year, data) => async (dispatch) => {
  dispatch(doRequest('updateGoal'));

  try {
    await api.put(`/goals/${userId}/${year}`, data);
    dispatch(setTargetGoal({ target: null }));
    dispatch(clearTargetOrganization());

    notification.success({
      message: 'Update goal',
      description: 'Updated goal successfully',
    });
    history.push('/goals');
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('updateGoal'));
  }
};

export const clearTargetOrganization = () => async (dispatch) => {
  dispatch(setGoals({ items: [] }));
  dispatch(setTargetOrganization({ target: null }));
};
