import { Roles } from '@/constants';

export const canViewAdminMenu = (user) => {
  return [Roles.Admin].includes(user?.type);
};

export const accessableDashboardDepartmentLinks = (user) => {
  const departmentLinks = [
    {
      id: 1,
      key: 'dashboardSerice',
      path: '/dashboard?departmentId=1',
      title: 'Service',
      disabled: false,
    },
    {
      id: 2,
      key: 'dashboardSales',
      path: '/dashboard?departmentId=2',
      title: 'Sales',
      disabled: false,
    },
    {
      id: 3,
      key: 'dashboardPlumbing',
      path: '/dashboard?departmentId=3',
      title: 'Plumbing',
      disabled: false,
    },
    {
      id: 4,
      key: 'dashboardElectrical',
      path: '/dashboard?departmentId=4',
      title: 'Electrical',
      disabled: false,
    },
  ];
  if (!user.departmentIds) {
    return [
      Roles.Admin,
      Roles.Trainer,
      Roles.Owner,
      Roles.Manager,
    ].includes(user?.type)
      ? departmentLinks
      : [
          {
            id: 0,
            key: 'dashboardNone',
            title: 'No Accessible Department Found',
            disabled: true,
          },
        ];
  }
  return departmentLinks.filter(({ id }) => user.departmentIds?.includes(id.toString()));
};
