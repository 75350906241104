import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Typography, Button, Form, InputNumber, Row, Col, Divider } from 'antd';

import ErrorMessage from '@/components/ErrorMessage';

const { Title, Text } = Typography;

const formSections = [
  {
    key: 'service',
    title: 'Service',
    inputs: [
      {
        label: 'Avg Service Ticket',
        name: 'service_avg_service_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Demand Ticket',
        name: 'service_avg_demand_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Maintenance Ticket',
        name: 'service_avg_maintenance_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Tune Up Ticket',
        name: 'service_avg_tune_up_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Replacement Ticket',
        name: 'service_avg_replacement_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Truck Revenue',
        name: 'service_avg_truck_revenue',
        type: 'currency',
      },
    ],
  },
  {
    key: 'service-close-rates',
    title: 'Service Close Rates',
    inputs: [
      {
        label: 'Opportunity Close Rate',
        name: 'service_opportunity_close_rate',
        type: 'percentage',
      },
      {
        label: 'Avg Maintenance Close Rate',
        name: 'service_avg_maintenance_close_rate',
        type: 'percentage',
      },
      {
        label: 'IAQ Close Rate',
        name: 'service_iaq_close_rate',
        type: 'percentage',
      },
      {
        label: 'Duct Cleaning Close Rate',
        name: 'service_duct_cleaning_close_rate',
        type: 'percentage',
      },
      {
        label: 'Water Heater Close Rate',
        name: 'service_water_heater_close_rate',
        type: 'percentage',
      },
      {
        label: 'Generator Close Rate',
        name: 'service_generator_close_rate',
        type: 'percentage',
      },
    ],
  },
  {
    key: 'sales',
    title: 'Sales',
    inputs: [
      {
        label: 'Avg Replacement Ticket',
        name: 'sales_avg_replacement_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Close Rate New Systems (Cold Lead)',
        name: 'sales_avg_close_rate_new_systems_cold_lead',
        type: 'percentage',
      },
      {
        label: 'Avg Close Rate New Systems (Turn Over)',
        name: 'sales_avg_close_rate_new_systems_turn_over',
        type: 'percentage',
      },
      {
        label: 'Avg Close Rate Water Heaters',
        name: 'sales_avg_close_rate_water_heaters',
        type: 'percentage',
      },
      {
        label: 'Avg Close Rate Generators',
        name: 'sales_avg_close_rate_generators',
        type: 'percentage',
      },
      {
        label: 'Avg Close Rate Duct Modifications',
        name: 'sales_avg_close_rate_duct_modifications',
        type: 'percentage',
      },
      {
        label: 'Avg Close Rate Duct Cleaning',
        name: 'sales_avg_close_rate_duct_cleaning',
        type: 'percentage',
      },
    ],
  },
  {
    key: 'plumbing',
    title: 'Plumbing',
    inputs: [
      {
        label: 'Avg Service Ticket',
        name: 'plumbing_avg_service_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Demand Ticket',
        name: 'plumbing_avg_demand_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Maintenance Ticket',
        name: 'plumbing_avg_maintenance_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Tune Up Ticket',
        name: 'plumbing_avg_tune_up_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Truck Revenue',
        name: 'plumbing_avg_truck_revenue',
        type: 'currency',
      },
      {
        label: 'Avg Project Ticket',
        name: 'plumbing_avg_project_ticket',
        type: 'currency',
      },
    ],
  },
  {
    key: 'electrical',
    title: 'Electrical',
    inputs: [
      {
        label: 'Avg Service Ticket',
        name: 'electrical_avg_service_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Demand Ticket',
        name: 'electrical_avg_demand_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Maintenance Ticket',
        name: 'electrical_avg_maintenance_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Tune Up Ticket',
        name: 'electrical_avg_tune_up_ticket',
        type: 'currency',
      },
      {
        label: 'Avg Truck Revenue',
        name: 'electrical_avg_truck_revenue',
        type: 'currency',
      },
      {
        label: 'Avg Project Ticket',
        name: 'electrical_avg_project_ticket',
        type: 'currency',
      },
    ],
  },
];

const KpiForm = (props) => {
  const {
    submitting,
    formObject,
    formErrors,
    onSubmit,
  } = props;

  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      form.resetFields();
    }
  }, [form, formObject, updated]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      initialValues={formObject}
      onValuesChange={() => setUpdated(true)}
      colon={false}
      layout="vertical"
      className="content-container__form"
      hideRequiredMark
    >
      <ErrorMessage errors={formErrors} />
      {formSections.map((formSection) => (
        <div key={formSection.key} className="form-section">
          <Title
            level={4}
            className="content-container__title"
            style={{ marginBottom: 24 }}
          >
            {formSection.title}
          </Title>
          {formSection.inputs.map((input) => (
            <Row key={input.name}>
              <Col span={12}>
                <Text>{input.label}</Text>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={input.name}
                  rules={[
                    {
                      required: true,
                      message: 'Value is required',
                    },
                  ]}
                  hasFeedback
                  className={`form-item-${input.type}`}
                >
                  <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
          <Divider style={{ marginTop: 8 }} />
        </div>
      ))}
      <div className="section">
        <Row>
          <Col
            offset={12}
            span={12}
          >
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={submitting} block>
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

KpiForm.propTypes = {
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  formObject: PropTypes.object,
  formErrors: PropTypes.arrayOf(PropTypes.string),
};

export default KpiForm;
