import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Typography } from 'antd';
import ErrorMessage from '@/components/ErrorMessage';

import { login } from '@/actions/authActions';
import { getErrors } from '@/selectors/errorSelectors';
import { getCurrentUser } from '@/selectors/authSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import logoImage from '@/assets/images/logo.png';
import './index.scss';

const { Title } = Typography;

class LoginContainer extends Component {
  handleLogin = (values) => {
    const { dispatchLogin } = this.props;
    dispatchLogin(values);
  };

  render() {
    const { submitting, currentUser, errors } = this.props;

    if (currentUser) {
      return <Redirect to="/" />;
    }

    return (
      <div className="authentication-container login-container">
        <Row gutter={16} justify="center" align="middle">
          <Col flex="250px">
            <img src={logoImage} className="authentication-container__logo" alt="logo" />
          </Col>
          <Col flex="1">
            <div className="authentication-container__form">
              <Title level={2} className="authentication-container__title">
                Login
              </Title>
              <ErrorMessage errors={errors} />
              <Form
                name="login-form"
                layout="vertical"
                onFinish={this.handleLogin}
                colon={false}
                hideRequiredMark
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { type: 'email', message: 'Email is not valid' },
                    { required: true, message: 'Email is required' },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Password is required' }]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" loading={submitting}>
                    Login
                  </Button>
                </Form.Item>
              </Form>
              <div className="authentication-container__helper">
                <Link to="/forgot-password" className="authentication-container__helper-item">
                  Forgot your password?
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

LoginContainer.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  submitting: PropTypes.bool,
  currentUser: PropTypes.object,
  dispatchLogin: PropTypes.func,
};

const submittingLogin = createRequestLoadingSelector(['login']);

const mapStateToProps = (state) => {
  return {
    errors: getErrors(state),
    submitting: submittingLogin(state),
    currentUser: getCurrentUser(state),
  };
};

export default connect(mapStateToProps, {
  dispatchLogin: login,
})(LoginContainer);
