import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Typography, notification } from 'antd';

import ErrorMessage from '@/components/ErrorMessage';
import logoImage from '@/assets/images/logo.png';
import { getCurrentUser } from '@/selectors/authSelectors';
import api from '@/api';
import './index.scss';

const { Title } = Typography;

class ActiveAccountContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: [],
    };
  }

  handleActiveAccount = async ({ password, passwordConfirmation }) => {
    const { history } = this.props;
    const params = new URLSearchParams(history.location.search);
    const token = params.get('token');

    this.setState({ loading: true });

    try {
      await api.post('/auth/active', {
        token,
        password,
        passwordConfirmation,
      });
      this.setState({
        loading: false,
        errors: [],
      });
      notification.success({
        message: 'Active account',
        description: 'Your account has been actived successfully',
      });
      history.push('/login');
    } catch (error) {
      this.setState({
        loading: false,
        errors: [error.response?.data?.message || 'Unable to active account'],
      });
      console.error(error);
    }
  };

  render() {
    const { currentUser } = this.props;
    const { loading, errors } = this.state;

    if (currentUser) {
      return <Redirect to="/" />;
    }

    return (
      <div className="authentication-container active-account-container">
        <Row gutter={16} justify="center" align="middle">
          <Col flex="250px">
            <img src={logoImage} alt="logo" className="authentication-container__logo" />
          </Col>
          <Col flex="1">
            <div className="authentication-container__form">
              <Title level={2} className="authentication-container__title">
                Active Account
              </Title>
              <ErrorMessage errors={errors} />
              <Form
                name="active-account-form"
                layout="vertical"
                onFinish={this.handleActiveAccount}
                colon={false}
                hideRequiredMark
              >
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Password is required',
                    },
                    {
                      min: 8,
                      message: 'Password must have atleast 8 characters',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="passwordConfirmation"
                  label="Password confirmation"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Password confirmation is required',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Password confirmation is not matched with password'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" loading={loading}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUser(state),
  };
};

export default withRouter(connect(mapStateToProps)(ActiveAccountContainer));
