import { sortBy } from 'lodash';

export const getJobList = (state) => {
  return {
    page: state.job.page,
    limit: state.job.limit,
    items: state.job.items,
    sort: state.job.sort,
    order: state.job.order,
    totalCount: state.job.totalCount,
    filteredOrganizationId: state.job.filteredOrganizationId,
    filteredUserId: state.job.filteredUserId,
    filteredFromDate: state.job.filteredFromDate,
    filteredToDate: state.job.filteredToDate,
  };
};

export const getTargetJob = (state) => {
  return state.job.target;
};

export const getFilteredOrganizationUsers = (state) => {
  return sortBy(state.job.filteredOrganizationUsers, (user) => `${user.firstName} ${user.lastName}`.toLowerCase());
};

export const getTargetOrganization = (state) => {
  return state.job.targetOrganization;
};
