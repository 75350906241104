import { Roles } from '@/constants';

export const canManageOrganization = (user) => {
  return [Roles.Admin, Roles.Trainer].includes(user?.type);
};

export const canViewOrganization = (user) => {
  return canManageOrganization(user);
};

export const canCreateOrganization = (user) => {
  return canManageOrganization(user);
};

export const canUpdateOrganization = (user) => {
  return canManageOrganization(user);
};

export const canDeleteOrganization = (user) => {
  return canManageOrganization(user);
};
