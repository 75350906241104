import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Alert, Row, Col, Form, Input, Button, Typography, notification } from 'antd';

import ErrorMessage from '@/components/ErrorMessage';
import logoImage from '@/assets/images/logo.png';
import api from '@/api';
import './index.scss';

const { Title } = Typography;

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: [],
    };
  }

  handleForgotPassword = async ({ email }) => {
    const { history } = this.props;
    this.setState({ loading: true });

    try {
      await api.post('/auth/forgot-password', {
        email,
      });
      this.setState({
        loading: false,
        errors: [],
      });
      notification.success({
        message: 'Forgot password',
        description: 'Reset password instruction has been sent to your email address',
      });
      history.push('/login');
    } catch (error) {
      this.setState({
        loading: false,
        errors: [error.response?.data?.message || 'Unable to send reset password request'],
      });
      console.error(error);
    }
  };

  render() {
    const { currentUser } = this.props;
    const { loading, errors } = this.state;

    if (currentUser) {
      return <Redirect to="/" />;
    }

    return (
      <div className="authentication-container forgot-password-container">
        <Row gutter={16} justify="center" align="middle">
          <Col flex="250px">
            <img
              src={logoImage}
              className="authentication-container__logo"
              alt="logo"
            />
          </Col>
          <Col flex="1">
            <div className="authentication-container__form">
              <Title level={2} className="authentication-container__title">
                Forgot Password
              </Title>
              <Alert
                className='forgot-password-container__notice'
                message="Notice"
                // eslint-disable-next-line max-len
                description="A reset password link has been sent. Do check your spam folder if it doesn't arrive in your inbox."
                type="warning"
                showIcon
              />
              <ErrorMessage errors={errors} />
              <Form
                name="forgor-password-form"
                layout="vertical"
                onFinish={this.handleForgotPassword}
                colon={false}
                hideRequiredMark
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { type: 'email', message: 'Email is not valid' },
                    { required: true, message: 'Email is required' },
                  ]}
                  hasFeedback
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" loading={loading}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
              <div className="authentication-container__helper">
                <div className="authentication-container__helper-item">
                  <Link to="/login">Back to login</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    currentUser: auth.currentUser,
  };
};

export default withRouter(connect(mapStateToProps)(ForgotPasswordContainer));
