import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'goal',
  initialState: {
    items: [],
    target: null,
    loading: false,
    targetOrganization: null,
  },
  reducers: {
    setGoals(state, { payload: { items } }) {
      state.items = items;
    },
    setTargetGoal(state, { payload: { target } }) {
      state.target = target;
    },
    setTargetOrganization(state, { payload: { target } }) {
      state.targetOrganization = target;
    },
  },
});

export const {
  setGoals,
  setTargetGoal,
  setTargetOrganization,
} = slice.actions;

export default slice.reducer;
