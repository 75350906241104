import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col, Typography, Spin, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import OrganizationForm from '@/forms/OrganizationForm';

import {
  fetchOrganizations,
  fetchTargetOrganization,
  updateOrganization,
  deleteOrganization,
} from '@/actions/organizationActions';
import { fetchDepartments } from '@/actions/departmentActions';
import { fetchJobTypes } from '@/actions/jobTypeActions';
import { fetchOpportunityTypes } from '@/actions/opportunityTypeActions';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getSelectableDepartments } from '@/selectors/departmentSelectors';
import { getSelectableJobTypes } from '@/selectors/jobTypeSelectors';
import { getSelectableOpportunityTypes } from '@/selectors/opportunityTypeSelectors';
import { getSelectableOrganizations, getTargetOrganization } from '@/selectors/organizationSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import { canDeleteOrganization } from '@/policies/organizationPolicies';
import './index.scss';

const { Title } = Typography;

const getParentOrganizationType = (organizationType) => {
  const typeMappings = {
    Distributor: 'Manufacturer',
    Contractor: 'Distributor',
  };
  return typeMappings[organizationType] || null;
};

class OrganizationUpdateContainer extends Component {
  componentDidMount() {
    const {
      dispatchFetchDepartments,
      dispatchFetchJobTypes,
      dispatchFetchOpportunityTypes,
      dispatchFetchTargetOrganization,
      dispatchFetchOrganizations,
      match: { params },
    } = this.props;

    dispatchFetchDepartments();
    dispatchFetchJobTypes();
    dispatchFetchOpportunityTypes();
    dispatchFetchOrganizations();
    dispatchFetchTargetOrganization(params.id);
  }

  handleDelete = () => {
    const { dispatchDeleteOrganization, targetOrganization } = this.props;
    dispatchDeleteOrganization(targetOrganization.id);
  }

  handleSubmit = (values) => {
    const { dispatchUpdateOrganization, targetOrganization } = this.props;
    dispatchUpdateOrganization(targetOrganization.id, values);
  };

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const {
      errors,
      loading,
      submitting,
      currentUser,
      targetOrganization,
      selectableDepartments,
      selectableJobTypes,
      selectableOpportunityTypes,
      selectableOrganizations,
    } = this.props;

    return (
      <LayoutContainer contentClassName={['content-container', 'organization-update-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Row gutter={16} align='middle'>
              <Col flex="1">
                <Title level={2} className="content-container__title">
                  Edit Organization
                </Title>
                <Breadcrumb
                  items={[
                    { key: 'home', link: '/', title: 'Home' },
                    { key: 'organizations', link: '/organizations', title: 'Organizations' },
                    { key: 'update', title: 'Edit Organization' },
                  ]}
                  className="content-container__breadcrumb"
                />
              </Col>
              <Col flex="0">
                {canDeleteOrganization(currentUser) && (
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={this.handleDelete}
                    loading={submitting}
                  >
                    Delete
                  </Button>
                )}
              </Col>
            </Row>
            {!targetOrganization || loading ? (
              <Spin />
            ) : (
              <OrganizationForm
                submitting={submitting}
                submitTitle="Update Organization"
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formObject={{
                  id: targetOrganization.id,
                  name: targetOrganization.name,
                  type: targetOrganization.type,
                  parentId: targetOrganization.parentId,
                  departmentIds: targetOrganization.departmentIds || [],
                  jobTypeIds: targetOrganization.jobTypes.map(({ id }) => id),
                  opportunityTypeIds: targetOrganization.opportunityTypes.map(({ id }) => id),
                  organizationGroups: targetOrganization.organizationGroups || [],
                  revenueThreshold: targetOrganization.revenueThreshold || {},
                  accountNumber: targetOrganization.accountNumber,
                  disabled: targetOrganization.disabled,
                }}
                formErrors={errors}
                jobTypeSelectOptions={selectableJobTypes}
                opportunityTypeSelectOptions={selectableOpportunityTypes}
                organizationDepartmentSelectOptions={selectableDepartments}
                parentOrganizationSelectOptions={selectableOrganizations.filter(
                  ({ type }) => type === getParentOrganizationType(targetOrganization.type),
                )}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

OrganizationUpdateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  currentUser: PropTypes.object,
  targetOrganization: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectableDepartments: PropTypes.arrayOf(PropTypes.object),
  selectableJobTypes: PropTypes.arrayOf(PropTypes.object),
  selectableOrganizations: PropTypes.arrayOf(PropTypes.object),
  dispatchFetchDepartments: PropTypes.func,
  dispatchFetchJobTypes: PropTypes.func,
  dispatchFetchOpportunityTypes: PropTypes.func,
  dispatchFetchTargetOrganization: PropTypes.func,
  dispatchFetchOrganizations: PropTypes.func,
  dispatchUpdateOrganization: PropTypes.func,
  dispatchDeleteOrganization: PropTypes.func,
};

const loadingData = createRequestLoadingSelector([
  'fetchDepartments',
  'fetchJobTypes',
  'fetchOpportunityTypes',
  'fetchOrganizations',
  'fetchTargetOrganization',
]);
const submittingOrganization = createRequestLoadingSelector([
  'updateOrganization',
  'deleteOrganization',
]);

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  loading: loadingData(state),
  submitting: submittingOrganization(state),
  targetOrganization: getTargetOrganization(state),
  selectableDepartments: getSelectableDepartments(state),
  selectableJobTypes: getSelectableJobTypes(state),
  selectableOpportunityTypes: getSelectableOpportunityTypes(state),
  selectableOrganizations: getSelectableOrganizations(state),
  currentUser: getCurrentUser(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchDepartments: fetchDepartments,
    dispatchFetchJobTypes: fetchJobTypes,
    dispatchFetchOpportunityTypes: fetchOpportunityTypes,
    dispatchFetchTargetOrganization: fetchTargetOrganization,
    dispatchFetchOrganizations: fetchOrganizations,
    dispatchUpdateOrganization: updateOrganization,
    dispatchDeleteOrganization: deleteOrganization,
  })(OrganizationUpdateContainer),
);
