import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Typography, Button, Space, Form, Input, InputNumber } from 'antd';

import ErrorMessage from '@/components/ErrorMessage';
import { capitalize } from '@/utils/stringUtils';

const { Paragraph } = Typography;

const GoalStrategyForm = (props) => {
  const {
    submitting,
    submitTitle,
    formObject,
    formErrors,
    onCancel,
    onSubmit,
  } = props;

  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      form.resetFields();
    }
  }, [form, formObject, updated]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      initialValues={formObject}
      onValuesChange={() => setUpdated(true)}
      colon={false}
      layout="vertical"
      className="content-container__form"
      hideRequiredMark
    >
      <ErrorMessage
        errors={formErrors}
      />
      <Paragraph>
        Please input the information below:
      </Paragraph>
      <Form.Item
        name="year"
        label="Year"
        rules={[
          { required: true, message: 'Year is required' },
        ]}
        hasFeedback
      >
        <InputNumber placeholder="Year" min={0} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: 'Name is required' },
        ]}
        hasFeedback
      >
        <Input
          placeholder="Name"
          style={{ width: '100%' }}
          disabled
        />
      </Form.Item>
      {['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].map((attr) => (
        <Form.Item
          key={attr}
          name={attr}
          label={capitalize(attr)}
          rules={[
            { required: true, message: 'Value is required' },
          ]}
          hasFeedback
          className='form-item-percentage'
        >
          <InputNumber
            min={0}
            style={{ width: '100%' }}
          />
        </Form.Item>
      ))}
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {submitTitle || 'Submit'}
          </Button>
          {onCancel && (
            <Button htmlType="button" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

GoalStrategyForm.propTypes = {
  submitting: PropTypes.bool,
  submitTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  formObject: PropTypes.shape({
    id: PropTypes.number,
    year: PropTypes.number,
    name: PropTypes.string,
    jan: PropTypes.number,
    feb: PropTypes.number,
    mar: PropTypes.number,
    apr: PropTypes.number,
    may: PropTypes.number,
    jun: PropTypes.number,
    jul: PropTypes.number,
    aug: PropTypes.number,
    sep: PropTypes.number,
    oct: PropTypes.number,
    nov: PropTypes.number,
    dec: PropTypes.number,
  }),
  formErrors: PropTypes.arrayOf(PropTypes.string),
};

export default GoalStrategyForm;
