import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Routes from '@/routes';
import { checkin } from '@/actions/authActions';
import { clearErrors } from '@/actions/errorActions';
import { getCurrentUser } from '@/selectors/authSelectors';

import '@/styles/index.scss';
import './index.scss';

class AppContainer extends Component {
  componentDidMount() {
    const {
      history,
      currentUser,
      dispatchClearErrors,
      dispatchCheckin,
    } = this.props;

    this.unlistenHistory = history.listen(() => {
      dispatchClearErrors();
    });

    if (currentUser) {
      dispatchCheckin();
    }
  }

  componentWillUnmount() {
    this.unlistenHistory();
  }

  render() {
    return (
      <div className="app__container">
        <Routes />
      </div>
    );
  }
}

AppContainer.propTypes = {
  history: PropTypes.object,
  currentUser: PropTypes.object,
  dispatchCheckin: PropTypes.func,
  dispatchClearErrors: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchClearErrors: clearErrors,
    dispatchCheckin: checkin,
  })(AppContainer),
);
