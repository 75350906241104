import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Select, DatePicker, Button, Typography, Space, Table } from 'antd';
import { sortBy, sum, sumBy, isNull, uniqBy } from 'lodash';
import LayoutContainer from '@/containers/LayoutContainer';

import {
  fetchJobStats,
  fetchGoalAmount,
  fetchOrganizations,
  fetchOrganizationUsers,
  fetchOpportunityRevenues,
  fetchOpportunityRevenuesByUnit,
} from '@/actions/revenueActions';
import { fetchJobTypes } from '@/actions/jobTypeActions';
import { fetchOpportunityTypes } from '@/actions/opportunityTypeActions';
import { getJobTypes } from '@/selectors/jobTypeSelectors';
import { getOpportunityTypes } from '@/selectors/opportunityTypeSelectors';
import { getCurrentUser } from '@/selectors/authSelectors';
import { selectSearchByChildrenText } from '@/utils/selectUtils';
import { displayCurrency, displayPercent } from '@/utils/numberUtils';
import { formatDateAsString } from '@/utils/dateUtils';
import {
  MasterRoles,
  ManagerRoles,
  WorkerRoles,
  OrganizationType,
  SERVICE_DEPARTMENT_ID,
  SALE_DEPARTMENT_ID,
  PLUMBING_DEPARTMENT_ID,
  ELECTRICAL_DEPARTMENT_ID,
} from '@/constants';
import './index.scss';
import { getOrganizations, getTargetOrganization } from '@/selectors/organizationSelectors';
import OrganizationListContainer from '../OrganizationListContainer';

const { Title, Text } = Typography;
const { Option } = Select;

const serviceRevenueColumns = [
  {
    title: 'Job Type',
    dataIndex: 'type',
    key: 'type',
    width: '30%',
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    key: 'revenue',
  },
  {
    title: 'Jobs Run',
    dataIndex: 'jobsRun',
    key: 'jobsRun',
  },
  {
    title: 'Average',
    dataIndex: 'avgJob',
    key: 'avgJob',
  },
];

const newSystemServiceColumns = [
  {
    title: 'Opportunities Run',
    dataIndex: 'run',
    key: 'run',
    width: '25%',
  },
  {
    title: 'Sold',
    dataIndex: 'sold',
    key: 'sold',
    width: '15%',
  },
  {
    title: 'Close Rate',
    dataIndex: 'closeRate',
    key: 'closeRate',
    width: '20%',
  },
  {
    title: 'Average',
    dataIndex: 'avgRevenue',
    key: 'avgRevenue',
    width: '20%',
  },
  {
    title: 'Total',
    dataIndex: 'totalRevenue',
    key: 'totalRevenue',
    className: 'table__cell-highlight',
    width: '20%',
  },
];

const techLeadTurnOverColumns = [
  {
    title: 'Opportunities Run',
    dataIndex: 'run',
    key: 'run',
    width: '30%',
  },
  {
    title: 'Leads Generated',
    dataIndex: 'sold',
    key: 'sold',
    width: '30%',
  },
  {
    title: 'Close Rate',
    dataIndex: 'closeRate',
    key: 'closeRate',
  },
];

const newSystemSalesColumns = [
  {
    title: 'Estimates Run',
    dataIndex: 'run',
    key: 'run',
    width: '25%',
  },
  {
    title: 'Sold',
    dataIndex: 'sold',
    key: 'sold',
    width: '15%',
  },
  {
    title: 'Close Rate',
    dataIndex: 'closeRate',
    key: 'closeRate',
    width: '20%',
  },
  {
    title: 'Average',
    dataIndex: 'avgRevenue',
    key: 'avgRevenue',
    width: '20%',
  },
  {
    title: 'Total',
    dataIndex: 'totalRevenue',
    key: 'totalRevenue',
    className: 'table__cell-highlight',
    width: '20%',
  },
];

const combinedRevenueColumns = [
  {
    key: 'actual',
    title: 'Actual',
    dataIndex: 'actual',
    className: 'table__cell-actual',
  },
  {
    key: 'goal',
    title: 'Goal',
    dataIndex: 'goal',
    className: 'table__cell-goal',
  },
];

const raiseTheBarColumns = [
  {
    title: '',
    dataIndex: 'unitName',
    key: 'unitName',
  },
  {
    title: (
      <Text>
        Avg Service
        <br />
        Ticket
      </Text>
    ),
    align: 'center',
    dataIndex: 'avgServiceTicket',
    key: 'avgServiceTicket',
  },
  {
    title: (
      <Text>
        Avg Replacement
        <br />
        Ticket
      </Text>
    ),
    align: 'center',
    dataIndex: 'avgReplaceTicket',
    key: 'avgReplaceTicket',
  },
  {
    title: (
      <Text>
        Avg Total
        <br />
        Revenue
      </Text>
    ),
    align: 'center',
    dataIndex: 'avgTotalRevenue',
    key: 'avgTotalRevenue',
  },
];

const opportunityTypeRevenueColumns = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Sold',
    dataIndex: 'sold',
    key: 'sold',
  },
  {
    title: 'Close Rates',
    dataIndex: 'closeRate',
    key: 'closeRate',
  },
  {
    title: 'Average',
    dataIndex: 'avgRevenue',
    key: 'avgRevenue',
  },
  {
    title: 'Revenue',
    dataIndex: 'revenue',
    key: 'revenue',
  },
];

const startDateOfYear = moment().startOf('year');
const currentDate = moment();

const defaultFilterForm = {
  contractorOrganizationId: null,
  organizationGroupId: null,
  userId: null,
  fromDate: startDateOfYear,
  toDate: currentDate,
};

class DashboardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterData: {
        organizations: null,
        organizationUsers: null,
        targetOrganization: null,
      },
      filterForm: {
        ...defaultFilterForm,
      },
      filterFormInitialize: {
        ...defaultFilterForm,
      },
      jobStats: null,
      goalAmount: null,
      opportunityRevenues: null,
      opportunityRevenuesByUnit: null,
      customizedJobStats: null,
      customizedOpportunityRevenues: null,
      loadingOrganizations: false,
      loadingOrganizationUsers: false,
      loadingRevenuesByUnit: false,
      loadingRevenues: false,
      loadingCustomizedRbjt: false,
      customizedRbjtJobTypeId: null,
      customizedRtbJobTypeId: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const {
      dispatchFetchJobTypes,
      dispatchFetchOpportunityTypes,
    } = this.props;

    dispatchFetchJobTypes();
    dispatchFetchOpportunityTypes();

    const departmentId = this.getDepartmentId();
    this.fetchDataByDepartment(departmentId);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search: prevSearch },
    } = prevProps;
    const {
      location: { search },
    } = this.props;

    if (search !== prevSearch) {
      window.location.reload();
    }
  }

  fetchDataByDepartment = (departmentId) => {
    const { currentUser } = this.props;

    this.setState({
      loadingOrganizations: true,
    });

    fetchOrganizations()
      .then((organizations) => {
        const targetOrganization = organizations.find((org) =>
          currentUser.organizationId
            ? `${org.id}` === `${currentUser.organizationId}`
            : org.type === OrganizationType.Manufacturer,
        );
        const organizationId = targetOrganization.id;
        const organizationGroupId = this.hasEmployeeRole() ? currentUser.organizationGroupId : null;
        const userId = this.hasEmployeeRole() ? currentUser.id : null;
        const fetchParams = {
          userId,
          departmentId,
          organizationId,
          organizationGroupId,
          fromDate: formatDateAsString(defaultFilterForm.fromDate),
          toDate: formatDateAsString(defaultFilterForm.toDate),
        };
        this.setState({
          filterData: {
            organizations,
            targetOrganization,
          },
          loadingOrganizations: false,
        });
        if (this.hasOwnerOrEmployeeRole()) {
          const contractOrgName = targetOrganization.name;
          const contractorOrganizationId = currentUser.organizationId;
          this.formRef.current.setFieldsValue({
            contractOrgName,
            contractorOrganizationId,
            organizationGroupId,
            userId,
          });
          this.setState({
            filterForm: {
              ...defaultFilterForm,
              contractorOrganizationId,
              organizationGroupId,
              userId,
            },
            filterFormInitialize: {
              ...defaultFilterForm,
              contractorOrganizationId,
              organizationGroupId,
              userId,
            },
          });
          this.fetchOrganizationUsers(contractorOrganizationId);
        }
        this.fetchRevenues(fetchParams);
        this.fetchRevenuesByUnit(fetchParams);
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loadingOrganizations: false,
        });
      });
  };

  fetchOrganizationUsers = (contractorOrganizationId) => {
    const { filterData } = this.state;
    this.setState({ loadingOrganizationUsers: true });
    fetchOrganizationUsers(contractorOrganizationId)
      .then((organizationUsers) => {
        this.setState({
          filterData: {
            ...filterData,
            organizationUsers,
          },
          loadingOrganizationUsers: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          loadingOrganizationUsers: false,
        });
      });
  };

  fetchRevenues = async ({ organizationId, organizationGroupId, departmentId, userId, fromDate, toDate }) => {
    this.setState({ loadingRevenues: true });

    try {
      const fetchParams = { organizationId, organizationGroupId, departmentId, userId, fromDate, toDate };
      const [opportunityRevenues, jobStats, { goalAmount }] = await Promise.all([
        fetchOpportunityRevenues(fetchParams),
        fetchJobStats(fetchParams),
        fetchGoalAmount(fetchParams),
      ]);
      this.setState({
        jobStats,
        goalAmount,
        opportunityRevenues,
        loadingRevenues: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        loadingRevenues: false,
      });
    }
  };

  fetchCustomizedRbjt = async ({
    organizationId,
    organizationGroupId,
    departmentId,
    userId,
    jobTypeId,
    fromDate,
    toDate,
  }) => {
    this.setState({ loadingCustomizedRbjt: true });

    try {
      const fetchParams = {
        organizationId,
        organizationGroupId,
        departmentId,
        userId,
        jobTypeId,
        fromDate,
        toDate,
      };
      const [opportunityRevenues, jobStats] = await Promise.all([
        fetchOpportunityRevenues(fetchParams),
        fetchJobStats(fetchParams),
      ]);
      this.setState({
        customizedOpportunityRevenues: opportunityRevenues,
        customizedJobStats: jobStats,
        loadingCustomizedRbjt: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        loadingCustomizedRbjt: false,
      });
    }
  };

  fetchRevenuesByUnit = async ({ organizationId, organizationGroupId, departmentId, jobTypeId, fromDate, toDate }) => {
    this.setState({ loadingRevenuesByUnit: true });

    try {
      const opportunityRevenuesByUnit = await fetchOpportunityRevenuesByUnit({
        organizationId,
        organizationGroupId,
        departmentId,
        jobTypeId,
        fromDate,
        toDate,
      });
      this.setState({
        opportunityRevenuesByUnit,
        loadingRevenuesByUnit: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        loadingRevenuesByUnit: false,
      });
    }
  };

  getDepartmentId = () => {
    const {
      location: { search },
    } = this.props;
    const params = new URLSearchParams(search);
    const departmentId = params.get('departmentId');

    return departmentId;
  };

  getPageTitle = () => {
    const departmentId = this.getDepartmentId();

    return (
      {
        1: 'Dashboard: Service',
        2: 'Dashboard: Sales',
        3: 'Dashboard: Plumbing',
        4: 'Dashboard: Electrical',
      }[departmentId] || 'Dashboard'
    );
  };

  getContractorOrganizations = () => {
    const { currentUser } = this.props;
    let {
      filterData: { organizations },
    } = this.state;

    if (!organizations) {
      return [];
    }


    const departmentId = this.getDepartmentId();
    let targetOrganization = organizations;
    
    targetOrganization = targetOrganization.filter(({ disabled }) => !disabled);
    
    organizations = organizations.filter(({ type, disabled }) => type === OrganizationType.Contractor && !disabled);

    targetOrganization = targetOrganization.filter(({ id }) => `${ id }` === `${currentUser.organizationId}`);

    if (currentUser.organizationId) { 
      if (currentUser.type === 'Distributor' || targetOrganization[0].type === 'Distributor') {
        organizations = organizations.filter(({ parentId }) => `${ parentId }` == `${currentUser.organizationId}`);
      } else {
        organizations = organizations.filter(({ id }) => `${ id }` === `${currentUser.organizationId}`);
      }
    }
    if (departmentId) {
      organizations = organizations.filter(({ departmentIds }) => departmentIds?.includes(departmentId));
    }

    organizations = sortBy(organizations, (organization) => organization.name?.toLowerCase());
    
    if (currentUser.type === 'Distributor') {
      organizations = targetOrganization.concat(organizations);
   }

   

   

    return organizations;
  };

  getJobTypes = (extraJobTypeIds = []) => {
    const {
      filterData: { organizations, targetOrganization },
    } = this.state;
    const { jobTypes: allJobTypes = [] } = this.props;
    const departmentId = this.getDepartmentId();

    if (!targetOrganization) {
      return [];
    }

    let jobTypes = [];
    if (
      targetOrganization.type === OrganizationType.Manufacturer ||
      targetOrganization.type === OrganizationType.Distributor
    ) {
      const child1Organizations = organizations.filter((org) => `${org.parentId}` === `${targetOrganization.id}`);
      for (const child1Organization of child1Organizations) {
        jobTypes.push(...child1Organization.jobTypes);
        if (child1Organization.type === OrganizationType.Distributor) {
          const child2Organizations = organizations.filter((org) => `${org.parentId}` === `${child1Organization.id}`);
          for (const child2Organization of child2Organizations) {
            jobTypes.push(...child2Organization.jobTypes);
          }
        }
      }
    }
    if (targetOrganization.type === OrganizationType.Contractor) {
      jobTypes = [...targetOrganization.jobTypes];
    }
    if (extraJobTypeIds && extraJobTypeIds.length > 0) {
      extraJobTypeIds.forEach((extraJobTypeId) => {
        const extraJobType = allJobTypes.find((jt) => `${jt.id}` === `${extraJobTypeId}`);
        jobTypes.push(extraJobType);
      });
    }
    if (departmentId) {
      jobTypes = jobTypes.filter(
        (jobType) =>
          !jobType.departmentIds?.length || jobType.departmentIds?.includes(`${departmentId}`),
      );
    }
    jobTypes = uniqBy(jobTypes, 'id');
    jobTypes = sortBy(jobTypes, (jobType) => jobType.name?.toLowerCase());
    return jobTypes;
  };

  getOpportunityTypes = (extraOpportunityTypes = []) => {
    const {
      filterData: { organizations, targetOrganization },
    } = this.state;
    const { opportunityTypes: allOpportunityTypes = [] } = this.props;
    const departmentId = this.getDepartmentId();

    if (!targetOrganization) {
      return [];
    }

    let opportunityTypes = [];
    if (
      targetOrganization.type === OrganizationType.Manufacturer ||
      targetOrganization.type === OrganizationType.Distributor
    ) {
      const child1Organizations = organizations.filter((org) => `${org.parentId}` === `${targetOrganization.id}`);
      for (const child1Organization of child1Organizations) {
        opportunityTypes.push(...child1Organization.opportunityTypes);
        if (child1Organization.type === OrganizationType.Distributor) {
          const child2Organizations = organizations.filter((org) => `${org.parentId}` === `${child1Organization.id}`);
          for (const child2Organization of child2Organizations) {
            opportunityTypes.push(...child2Organization.opportunityTypes);
          }
        }
      }
    }
    if (targetOrganization.type === OrganizationType.Contractor) {
      opportunityTypes = [...targetOrganization.opportunityTypes];
    }
    if (extraOpportunityTypes && extraOpportunityTypes.length > 0) {
      extraOpportunityTypes.forEach((extraOpportunityTypeId) => {
        const extraJobType = allOpportunityTypes.find((ot) => ot.id?.toString() === extraOpportunityTypeId?.toString());
        opportunityTypes.push(extraJobType);
      });
    }
    if (departmentId) {
      opportunityTypes = opportunityTypes.filter(
        (opportunityType) =>
          !opportunityType.departmentIds?.length ||
          opportunityType.departmentIds?.includes(`${departmentId}`),
      );
    }
    opportunityTypes = uniqBy(opportunityTypes, 'id');
    opportunityTypes = sortBy(opportunityTypes, (opportunityType) => opportunityType.name?.toLowerCase());
    return opportunityTypes;
  };

  getFilteringOrganizationGroups = () => {
    const {
      filterForm: { contractorOrganizationId },
      filterData: { organizations },
    } = this.state;
    const { currentUser } = this.props;

    if (!contractorOrganizationId || !organizations) {
      return [];
    }

    const organization = organizations.find(({ id }) => `${id}` === `${contractorOrganizationId}`);
    let organizationGroups = organization?.organizationGroups || [];

    if (WorkerRoles.includes(currentUser.type)) {
      organizationGroups = organizationGroups.filter(
        (organizationGroup) =>
          !currentUser.organizationGroupId || `${organizationGroup.id}` === `${currentUser.organizationGroupId}`,
      );
    }

    return sortBy(organizationGroups, (organizationGroup) => organizationGroup.name?.toLowerCase());
  };

  getOrganizationUsers = () => {
    const {
      filterData: { organizationUsers },
      filterForm: { organizationGroupId },
    } = this.state;
    const { currentUser } = this.props;
    const departmentId = this.getDepartmentId();

    if (!organizationUsers) {
      return [];
    }

    let filteredUsers = organizationUsers.filter((user) => !user.disabled);
    if (departmentId) {
      filteredUsers = filteredUsers.filter((user) => !user.departmentIds || user.departmentIds.includes(`${departmentId}`));
    }
    if (organizationGroupId) {
      filteredUsers = filteredUsers.filter((user) => user.organizationGroupId === organizationGroupId);
    }
    if (WorkerRoles.includes(currentUser.type)) {
      filteredUsers = filteredUsers.filter((user) => user.id === currentUser.id);
    }
    filteredUsers = sortBy(filteredUsers, (user) => `${user.firstName} ${user.lastName}`.toLowerCase());
    return filteredUsers;
  };

  getServiceRevenueDataSource = () => {
    const { jobStats, opportunityRevenues } = this.state;
    if (!opportunityRevenues || !jobStats) {
      return [];
    }

    const revenueByJobTypeMapper = opportunityRevenues.revenueByJobType.reduce(
      (mapper, item) => ({
        ...mapper,
        [item.jobTypeId]: item.totalRevenue,
      }),
      {},
    );
    const totalJobCountByJobTypeMapper = jobStats.totalCountByJobType.reduce(
      (mapper, item) => ({
        ...mapper,
        [item.jobTypeId]: item.totalCount,
      }),
      {},
    );
    const jobTypes = this.getJobTypes(Object.keys(revenueByJobTypeMapper));

    const result = jobTypes.map((jobType) => {
      const revenue = revenueByJobTypeMapper[jobType.id] || 0;
      const jobCount = totalJobCountByJobTypeMapper[jobType.id] || 0;

      return {
        key: jobType.id,
        type: jobType.name,
        jobsRun: jobCount,
        revenue: displayCurrency(revenue),
        avgJob: displayCurrency(jobCount !== 0 ? revenue / jobCount : 0),
      };
    });

    const totalRevenue = sum(jobTypes.map((jobType) => revenueByJobTypeMapper[jobType.id] || 0));
    const totalJobCount = sum(jobTypes.map((jobType) => totalJobCountByJobTypeMapper[jobType.id] || 0));

    result.push({
      key: 'total',
      type: 'Total Service Revenue',
      revenue: displayCurrency(totalRevenue),
      jobsRun: totalJobCount,
      avgJob: displayCurrency(totalJobCount !== 0 ? totalRevenue / totalJobCount : 0),
    });

    return result;
  };

  getNewSystemServiceDataSource = () => {
    const { jobStats, opportunityRevenues } = this.state;
    if (!opportunityRevenues || !jobStats) {
      return [];
    }

    return [
      {
        key: '#serviceCall',
        run: jobStats.totalCountOfOpportunityRun,
        sold: jobStats.totalCountOfNewSystemFromServiceSold,
        closeRate: displayPercent(
          jobStats.totalCountOfOpportunityRun !== 0
            ? jobStats.totalCountOfNewSystemFromServiceSold / jobStats.totalCountOfOpportunityRun
            : 0,
        ),
        avgRevenue: displayCurrency(
          jobStats.totalCountOfNewSystemFromServiceSold !== 0
            ? opportunityRevenues.revenue.totalNewSystemRevenueFromService /
                jobStats.totalCountOfNewSystemFromServiceSold
            : 0,
        ),
        totalRevenue: displayCurrency(opportunityRevenues.revenue.totalNewSystemRevenueFromService),
      },
    ];
  };

  getTechLeadTurnOverDataSource = () => {
    const { jobStats, opportunityRevenues } = this.state;
    if (!opportunityRevenues || !jobStats) {
      return [];
    }

    return [
      {
        key: '#techLeadTurnOver',
        run: jobStats.totalCountOfOpportunityRun,
        sold: jobStats.totalCountOfTechLeadTurnOver,
        closeRate: displayPercent(
          jobStats.totalCountOfOpportunityRun !== 0
            ? jobStats.totalCountOfTechLeadTurnOver / jobStats.totalCountOfOpportunityRun
            : 0,
        ),
      },
    ];
  };

  getNewSystemSalesDataSource = () => {
    const { jobStats, opportunityRevenues } = this.state;
    if (!opportunityRevenues || !jobStats) {
      return [];
    }

    return [
      {
        key: '#estimate',
        run: jobStats.totalCountOfEstimateRun,
        sold: jobStats.totalCountOfNewSystemFromSaleSold,
        closeRate: displayPercent(
          jobStats.totalCountOfEstimateRun !== 0
            ? jobStats.totalCountOfNewSystemFromSaleSold / jobStats.totalCountOfEstimateRun
            : 0,
        ),
        avgRevenue: displayCurrency(
          jobStats.totalCountOfNewSystemFromSaleSold !== 0
            ? opportunityRevenues.revenue.totalNewSystemRevenueFromEstimate /
                jobStats.totalCountOfNewSystemFromSaleSold
            : 0,
        ),
        totalRevenue: displayCurrency(opportunityRevenues.revenue.totalNewSystemRevenueFromEstimate),
      },
    ];
  };

  getNewProjectDataSource = () => {
    return [
      {
        key: '#newProject',
        run: 'n/a',
        sold: 'n/a',
        closeRate: 'n/a',
        avgRevenue: 'n/a',
        totalRevenue: 'n/a',
      },
    ];
  };

  getCombinedRevenueDataSource = () => {
    const { opportunityRevenues, goalAmount } = this.state;

    if (!opportunityRevenues || isNull(goalAmount)) {
      return [];
    }

    return [
      {
        key: '#key',
        actual: displayCurrency(opportunityRevenues.revenue.totalRevenue || 0),
        goal: displayCurrency(goalAmount),
      },
    ];
  };

  getOpportunityTypeRevenueInByJobTypeDataSource = () => {
    const {
      jobStats,
      customizedJobStats,
      opportunityRevenues,
      customizedOpportunityRevenues,
      customizedRbjtJobTypeId,
    } = this.state;
    const departmentId = this.getDepartmentId();
    const usedJobStats = customizedRbjtJobTypeId ? customizedJobStats : jobStats;
    const usedOpportunityRevenues = customizedRbjtJobTypeId ? customizedOpportunityRevenues : opportunityRevenues;

    if (!usedJobStats || !usedOpportunityRevenues) {
      return [];
    }

    const revenueByOpportunityTypeMapper = usedOpportunityRevenues.revenueByOpportunityType.reduce(
      (mapper, item) => ({
        ...mapper,
        [item.opportunityTypeId]: item.totalRevenue,
      }),
      {},
    );
    const totalJobCountByOpportunityTypeMapper = usedJobStats.totalCountByOpportunityType.reduce(
      (mapper, item) => ({
        ...mapper,
        [item.opportunityTypeId]: item.totalCount || 0,
      }),
      {},
    );
    const soldCountByOpportunityTypeMapper = usedOpportunityRevenues.revenueByOpportunityType.reduce(
      (mapper, item) => ({
        ...mapper,
        [item.opportunityTypeId]: item.totalOpportunityCount,
      }),
      {},
    );
    const opportunityTypes = this.getOpportunityTypes(Object.keys(revenueByOpportunityTypeMapper));
    const totalJobCount = usedJobStats.totalCount;
    const totalSoldCount = sumBy(usedOpportunityRevenues.revenueByOpportunityType, 'totalOpportunityCount');
    const totalRevenueOfAllOpportunityType = sumBy(usedOpportunityRevenues.revenueByOpportunityType, 'totalRevenue');
    const totalJobCountOfAllOpportunityType = usedJobStats.totalCountByAllOpportunityType || 0;

    let result = opportunityTypes.map((opportunityType) => {
      const revenue = revenueByOpportunityTypeMapper[opportunityType.id] || 0;
      const jobCount = totalJobCountByOpportunityTypeMapper[opportunityType.id] || 0;
      const soldCount = soldCountByOpportunityTypeMapper[opportunityType.id] || 0;

      return {
        key: opportunityType.id,
        type: opportunityType.name,
        revenue: displayCurrency(revenue),
        avgRevenue: displayCurrency(soldCount !== 0 ? revenue / soldCount : 0),
        sold: soldCount,
        percent: displayPercent(
          totalRevenueOfAllOpportunityType !== 0 ? revenue / totalRevenueOfAllOpportunityType : 0,
        ),
        closeRate: displayPercent(totalJobCount !== 0 ? jobCount / totalJobCount : 0),
      };
    });

    if (`${customizedRbjtJobTypeId}` === SERVICE_DEPARTMENT_ID) {
      result = result.filter(({ key }) => `${key}` !== SERVICE_DEPARTMENT_ID);
    }

    result.unshift({
      key: '#zeroDollarJobs',
      type: 'Zero Dollar Jobs',
      revenue: displayCurrency(0),
      avgRevenue: displayCurrency(0),
      sold: totalJobCount - totalJobCountOfAllOpportunityType,
      percent: displayPercent(0),
      closeRate: displayPercent(
        totalJobCount !== 0
          ? (totalJobCount - totalJobCountOfAllOpportunityType) / totalJobCount
          : 0,
      ),
    });

    if ([SERVICE_DEPARTMENT_ID, SALE_DEPARTMENT_ID].includes(`${departmentId}`)) {
      const totalRevenueOfAllOpportunityType = sumBy(usedOpportunityRevenues.revenueByOpportunityType, 'totalRevenue');
      const revenue = opportunityRevenues.revenue.totalTechLeadTurnOverRevenue || 0;

      result.push({
        key: '#technicianLeadTurnovers',
        type: 'Technician Lead Turnovers',
        revenue: displayCurrency(revenue),
        avgRevenue: displayCurrency(
          usedJobStats.totalCountOfTechLeadTurnOver !== 0 ? revenue / usedJobStats.totalCountOfTechLeadTurnOver : 0,
        ),
        sold: usedJobStats.totalCountOfTechLeadTurnOver,
        percent: displayPercent(
          totalRevenueOfAllOpportunityType !== 0 ? revenue / totalRevenueOfAllOpportunityType : 0,
        ),
        closeRate: displayPercent(
          usedJobStats.totalCountOfOpportunityRun !== 0
            ? usedJobStats.totalCountOfTechLeadTurnOver / usedJobStats.totalCountOfOpportunityRun
            : 0,
        ),
      });
    }

    result.push({
      key: '#total',
      type: 'Total',
      revenue: displayCurrency(totalRevenueOfAllOpportunityType),
      avgRevenue: displayCurrency(
        totalSoldCount !== 0
          ? totalRevenueOfAllOpportunityType / totalSoldCount
          : 0,
      ),
      sold: totalSoldCount,
      percent: displayPercent(totalRevenueOfAllOpportunityType !== 0 ? 1 : 0),
      closeRate: displayPercent(totalJobCount !== 0 ? totalJobCountOfAllOpportunityType / totalJobCount : 0),
    });

    return result;
  };

  getRaiseTheBarDataSource = () => {
    const {
      filterData: { targetOrganization },
      filterForm: { organizationGroupId },
      opportunityRevenuesByUnit,
    } = this.state;
    const { currentUser } = this.props;

    if (!opportunityRevenuesByUnit || !opportunityRevenuesByUnit.unitCollection) {
      return [];
    }

    let usedUnitCollection = opportunityRevenuesByUnit.unitCollection;
    if (
      targetOrganization.type === OrganizationType.Contractor &&
      opportunityRevenuesByUnit.unitType === 'User' &&
      organizationGroupId
    ) {
      usedUnitCollection = usedUnitCollection.filter(
        (item) => `${item.organizationGroupId}` === `${organizationGroupId}`,
      );
    }
    if (
      targetOrganization.type === OrganizationType.Contractor &&
      opportunityRevenuesByUnit.unitType === 'User' &&
      WorkerRoles.includes(currentUser.type)
    ) {
      usedUnitCollection = usedUnitCollection.filter(
        (item) => `${item.id}` === `${currentUser.id}`,
      );
    }

    const units = usedUnitCollection
      .sort((dt1, dt2) => {
        const dt1AvgTotalRevenue =
          (dt1.jobCount || 0) + (dt1.newSystemJobCount || 0) !== 0
            ? (dt1.revenue + dt1.newSystemRevenue) / (dt1.jobCount + dt1.newSystemJobCount)
            : 0;
        const dt2AvgTotalRevenue =
          (dt2.jobCount || 0) + (dt2.newSystemJobCount || 0) !== 0
            ? (dt2.revenue + dt2.newSystemRevenue) / (dt2.jobCount + dt2.newSystemJobCount)
            : 0;
        return dt2AvgTotalRevenue - dt1AvgTotalRevenue;
      })
      .map((dt) => ({
        key: dt.id,
        unitName: dt.name,
        avgServiceTicket: displayCurrency(dt.jobCount !== 0 ? dt.revenue / dt.jobCount : 0),
        avgReplaceTicket: displayCurrency(dt.newSystemJobCount !== 0 ? dt.newSystemRevenue / dt.newSystemJobCount : 0),
        avgTotalRevenue: displayCurrency(
          (dt.jobCount || 0) + (dt.newSystemJobCount || 0) !== 0
            ? (dt.revenue + dt.newSystemRevenue) / (dt.jobCount + dt.newSystemJobCount)
            : 0,
        ),
      }));

    if (
      targetOrganization.type === OrganizationType.Contractor &&
      opportunityRevenuesByUnit.unitType === 'User' &&
      WorkerRoles.includes(currentUser.type)
    ) {
      return units;
    }

    return [
      {
        key: '#average',
        unitName: `${targetOrganization.type} Average`,
        avgServiceTicket: displayCurrency(
          opportunityRevenuesByUnit.totalJobCount !== 0
            ? opportunityRevenuesByUnit.totalRevenue / opportunityRevenuesByUnit.totalJobCount
            : 0,
        ),
        avgReplaceTicket: displayCurrency(
          opportunityRevenuesByUnit.newSystemTotalJobCount !== 0
            ? opportunityRevenuesByUnit.newSystemTotalRevenue / opportunityRevenuesByUnit.newSystemTotalJobCount
            : 0,
        ),
        avgTotalRevenue: displayCurrency(
          (opportunityRevenuesByUnit.totalJobCount || 0) + (opportunityRevenuesByUnit.newSystemTotalJobCount || 0) !== 0
            ? (opportunityRevenuesByUnit.totalRevenue + opportunityRevenuesByUnit.newSystemTotalRevenue) /
                (opportunityRevenuesByUnit.totalJobCount + opportunityRevenuesByUnit.newSystemTotalJobCount)
            : 0,
        ),
        highlight: true,
      },
      ...units,
    ];
  };

  hasAdminRole = () => {
    const { currentUser } = this.props;
    return MasterRoles.includes(currentUser?.type);
  };

  hasOwnerOrEmployeeRole = () => {
    const { currentUser } = this.props;
    return [...ManagerRoles, ...WorkerRoles].includes(currentUser?.type);
  };

  hasEmployeeRole = () => {
    const { currentUser } = this.props;
    return WorkerRoles.includes(currentUser?.type);
  }

  handleChangeFilterForm = (changedValues, formValues) => {
    const {
      contractorOrganizationId: changedContractorOrganizationId,
      organizationGroupId: changedOrganizationGroupId,
    } = changedValues;
    const { contractorOrganizationId: nextContractorOrganizationId } = formValues;

    if (changedContractorOrganizationId) {
      this.fetchOrganizationUsers(changedContractorOrganizationId);
    }

    const filterForm = {
      contractorOrganizationId: nextContractorOrganizationId,
      organizationGroupId: changedContractorOrganizationId ? null : formValues.organizationGroupId,
      userId: changedContractorOrganizationId || changedOrganizationGroupId ? null : formValues.userId,
      fromDate: formValues.fromDate,
      toDate: formValues.toDate,
    };
    this.formRef.current.setFieldsValue(filterForm);
    this.setState({ filterForm });
  };

  handleSearch = (values) => {
    const { filterData } = this.state;
    const {
      currentUser,
      location: { search },
    } = this.props;
    const params = new URLSearchParams(search);
    const departmentId = params.get('departmentId');
    const { contractorOrganizationId, organizationGroupId, userId, fromDate, toDate } = values;

    const targetOrganizationId = contractorOrganizationId || currentUser?.organizationId;
    const targetOrganization = filterData.organizations.find((organization) =>
      targetOrganizationId
        ? `${organization.id}` === `${targetOrganizationId}`
        : organization.type === OrganizationType.Manufacturer,
    );

    this.setState({
      filterData: {
        ...filterData,
        targetOrganization,
      },
      customizedJobStats: null,
      customizedOpportunityRevenues: null,
      customizedRbjtJobTypeId: null,
      customizedRtbJobTypeId: null,
    });
    const fetchParams = {
      userId,
      departmentId,
      organizationGroupId,
      organizationId: targetOrganization.id,
      fromDate: formatDateAsString(fromDate),
      toDate: formatDateAsString(toDate),
    };
    this.fetchRevenues(fetchParams);
    this.fetchRevenuesByUnit(fetchParams);
  };

  handleResetForm = () => {
    const { filterData, filterFormInitialize } = this.state;

    this.formRef.current.setFieldsValue(filterFormInitialize);
    this.setState({
      filterData: {
        ...filterData,
        organizationUsers: [],
      },
      filterForm: {
        ...filterFormInitialize,
      },
      customizedJobStats: null,
      customizedOpportunityRevenues: null,
      customizedRbjtJobTypeId: null,
      customizedRtbJobTypeId: null,
    });
    this.handleSearch(filterFormInitialize);
  };

  handleChangeRbjtJobTypeId = (jobTypeId) => {
    const {
      filterForm: { organizationGroupId, userId, fromDate, toDate },
      filterData: { targetOrganization },
    } = this.state;
    const departmentId = this.getDepartmentId();

    if (jobTypeId) {
      this.setState({
        customizedRbjtJobTypeId: jobTypeId,
      });
      this.fetchCustomizedRbjt({
        userId,
        jobTypeId,
        departmentId,
        organizationGroupId,
        organizationId: targetOrganization.id,
        fromDate: formatDateAsString(fromDate),
        toDate: formatDateAsString(toDate),
      });
    } else {
      this.setState({
        customizedJobStats: null,
        customizedOpportunityRevenues: null,
        customizedRbjtJobTypeId: null,
      });
    }
  };

  handleChangeRtbJobTypeId = (jobTypeId) => {
    const {
      filterForm: { organizationGroupId, fromDate, toDate },
      filterData: { targetOrganization },
    } = this.state;
    const departmentId = this.getDepartmentId();

    this.setState({
      customizedRtbJobTypeId: jobTypeId,
    });
    this.fetchRevenuesByUnit({
      jobTypeId,
      departmentId,
      organizationGroupId,
      organizationId: targetOrganization.id,
      fromDate: formatDateAsString(fromDate),
      toDate: formatDateAsString(toDate),
    });
  };

  render() {
    const {
      filterForm,
      filterFormInitialize,
      jobStats,
      opportunityRevenues,
      opportunityRevenuesByUnit,
      loadingOrganizationUsers,
      loadingOrganizations,
      loadingRevenuesByUnit,
      loadingRevenues,
      loadingCustomizedRbjt,
      customizedRbjtJobTypeId,
      customizedRtbJobTypeId,
    } = this.state;
    const { currentUser } = this.props;
    const selectedContractor = !!filterForm.contractorOrganizationId;
    const departmentId = this.getDepartmentId();
    const organizationGroups = this.getFilteringOrganizationGroups();

    return (
      <LayoutContainer contentClassName={['content-container', 'dashboard-container']}>
        <Title level={2} className="content-container__title">
          {this.getPageTitle()}
        </Title>
        <Form
          name="dashboard-search"
          layout="vertical"
          className="content-container__search-form"
          onValuesChange={this.handleChangeFilterForm}
          initialValues={filterFormInitialize}
          ref={this.formRef}
          onFinish={this.handleSearch}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="contractorOrganizationId" label="Contractor">
                <Select
                  showSearch
                  placeholder="Select Contractor"
                  optionFilterProp="children"
                  filterOption={selectSearchByChildrenText}
                  loading={loadingOrganizations}
                  defaultValue={ManagerRoles.includes(currentUser.type) 
                    ? this.getContractorOrganizations().at(0) 
                    : undefined
                  }
                >
                  {MasterRoles.includes(currentUser.type) && <Option value={null}>All</Option>}
                  {this.getContractorOrganizations().map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="userId" label="Employee">
                <Select
                  showSearch
                  placeholder="Select Employee"
                  optionFilterProp="children"
                  filterOption={selectSearchByChildrenText}
                  loading={loadingOrganizations || loadingOrganizationUsers}
                >
                  {[...MasterRoles, ...ManagerRoles].includes(currentUser.type) && <Option value={null}>All</Option>}
                  {this.getOrganizationUsers().map(({ id, firstName, lastName }) => (
                    <Option key={id} value={id}>
                      {`${firstName} ${lastName}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="fromDate"
                label="From Date"
                rules={[
                  {
                    required: true,
                    message: 'From date is required',
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="toDate"
                label="To Date"
                rules={[
                  {
                    required: true,
                    message: 'To date is required',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, toDate) {
                      const fromDate = getFieldValue('fromDate');
                      if (fromDate && toDate && toDate < fromDate) {
                        return Promise.reject(new Error('To date must be greater than or equal to from date'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} align="middle">
            <Col span={8}>
              {organizationGroups.length > 0 && (
                <Form.Item name="organizationGroupId" label="Subgroup">
                  <Select
                    showSearch
                    placeholder="Select Subgroup"
                    optionFilterProp="children"
                    filterOption={selectSearchByChildrenText}
                    loading={loadingOrganizations}
                    disabled={!selectedContractor}
                  >
                    {[...MasterRoles, ...ManagerRoles].includes(currentUser.type) && <Option value={null}>All</Option>}
                    {organizationGroups.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col span={16} style={{ textAlign: 'right' }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
                <Button htmlType="button" onClick={this.handleResetForm}>
                  Reset
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        <div className="content-container__section">
          <Title level={4} className="content-container__subtitle">
            Service Revenue
          </Title>
          <Table
            rowKey="key"
            loading={isNull(opportunityRevenues) || isNull(jobStats) || loadingRevenues}
            dataSource={this.getServiceRevenueDataSource()}
            columns={serviceRevenueColumns}
            className="content-container__table"
            pagination={false}
            rowClassName={({ key }) =>
              key === 'total' ? 'table__row-highlight-blue table__row-highlight-blue-total-row' : null
            }
            scroll={{
              x: 'max-content',
            }}
          />
        </div>
        <div className="content-container__section">
          <Row gutter={24}>
            {[SERVICE_DEPARTMENT_ID, SALE_DEPARTMENT_ID].includes(`${departmentId}`) && (
              <Col span={12} style={{ marginBottom: 24 }}>
                <Title level={4} className="content-container__subtitle">
                  New System From Service
                </Title>
                <Table
                  rowKey="key"
                  loading={isNull(opportunityRevenues) || isNull(jobStats) || loadingRevenues}
                  dataSource={this.getNewSystemServiceDataSource()}
                  columns={newSystemServiceColumns}
                  className="content-container__table"
                  pagination={false}
                  scroll={{
                    x: 'max-content',
                  }}
                />
              </Col>
            )}
            {[SERVICE_DEPARTMENT_ID, SALE_DEPARTMENT_ID, PLUMBING_DEPARTMENT_ID, ELECTRICAL_DEPARTMENT_ID].includes(`${departmentId}`) && (
              <Col span={12} style={{ marginBottom: 24 }}>
                <Title level={4} className="content-container__subtitle">
                  Technician Lead Turnovers
                </Title>
                <Table
                  rowKey="key"
                  loading={isNull(opportunityRevenues) || isNull(jobStats) || loadingRevenues}
                  dataSource={this.getTechLeadTurnOverDataSource()}
                  columns={techLeadTurnOverColumns}
                  className="content-container__table"
                  pagination={false}
                  scroll={{
                    x: 'max-content',
                  }}
                />
              </Col>
            )}
            {[SERVICE_DEPARTMENT_ID, SALE_DEPARTMENT_ID].includes(`${departmentId}`) && (
              <Col span={12} style={{ marginBottom: 24 }}>
                <Title level={4} className="content-container__subtitle">
                  New System From Estimate
                </Title>
                <Table
                  rowKey="key"
                  loading={isNull(opportunityRevenues) || isNull(jobStats) || loadingRevenues}
                  dataSource={this.getNewSystemSalesDataSource()}
                  columns={newSystemSalesColumns}
                  className="content-container__table"
                  pagination={false}
                  scroll={{
                    x: 'max-content',
                  }}
                />
              </Col>
            )}
          </Row>
        </div>
        <div className="content-container__section">
          <Row gutter={24} style={{ marginTop: -24 }}>
            <Col span={24}>
              <Title level={4} className="content-container__subtitle">
                Combined Revenue
              </Title>
              <Table
                rowKey="key"
                loading={isNull(opportunityRevenues) || isNull(jobStats) || loadingRevenues}
                dataSource={this.getCombinedRevenueDataSource()}
                columns={combinedRevenueColumns}
                className="content-container__table combined-revenue-table"
                pagination={false}
                scroll={{
                  x: 'max-content',
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="content-container__section">
          <Row gutter={24}>
            <Col span={12}>
              <div className="content-container__subsection">
                <Title level={4} className="content-container__subtitle">
                  Raise The Bar
                </Title>
                <Select
                  style={{
                    width: '100%',
                    marginTop: 8,
                    marginBottom: 24,
                  }}
                  placeholder="Select a job type"
                  value={customizedRtbJobTypeId}
                  onChange={this.handleChangeRtbJobTypeId}
                >
                  <Option value={null}>All</Option>
                  {this.getJobTypes().map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
                <Table
                  rowKey="key"
                  loading={isNull(opportunityRevenuesByUnit) || loadingRevenuesByUnit}
                  dataSource={this.getRaiseTheBarDataSource()}
                  columns={raiseTheBarColumns}
                  className="content-container__table"
                  rowClassName={({ highlight }) => (highlight ? 'table__row-grey-bold' : null)}
                  pagination={false}
                  scroll={{
                    x: 'max-content',
                  }}
                />
              </div>
            </Col>
            <Col span={12}>
              <Space direction="vertical" style={{ display: 'flex' }}>
                <div className="content-container__subsection">
                  <Title level={4} className="content-container__subtitle">
                    Revenue By Job Type
                  </Title>
                  <Select
                    style={{
                      width: '100%',
                      marginTop: 8,
                      marginBottom: 24,
                    }}
                    placeholder="Select a job type"
                    value={customizedRbjtJobTypeId}
                    onChange={this.handleChangeRbjtJobTypeId}
                  >
                    <Option value={null}>All</Option>
                    {this.getJobTypes().map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                  <Table
                    rowKey="key"
                    loading={isNull(opportunityRevenues) || loadingRevenues || loadingCustomizedRbjt}
                    dataSource={this.getOpportunityTypeRevenueInByJobTypeDataSource()}
                    columns={opportunityTypeRevenueColumns}
                    className="content-container__table"
                    rowClassName={({ key }) =>
                      key === '#total' ? 'table__row-highlight-blue table__row-highlight-blue-total-row' : null
                    }
                    pagination={false}
                    scroll={{
                      x: 'max-content',
                    }}
                  />
                </div>
              </Space>
            </Col>
          </Row>
        </div>
      </LayoutContainer>
    );
  }
}

DashboardContainer.propTypes = {
  currentUser: PropTypes.object,
  jobTypes: PropTypes.arrayOf(PropTypes.object),
  opportunityTypes: PropTypes.arrayOf(PropTypes.object),
  dispatchFetchJobTypes: PropTypes.func,
  dispatchFetchOpportunityTypes: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  opportunityTypes: getOpportunityTypes(state),
  jobTypes: getJobTypes(state),
});

export default withRouter(connect(mapStateToProps, {
  dispatchFetchJobTypes: fetchJobTypes,
  dispatchFetchOpportunityTypes: fetchOpportunityTypes,
})(DashboardContainer));
