import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Typography, notification } from 'antd';

import ErrorMessage from '@/components/ErrorMessage';
import logoImage from '@/assets/images/logo.png';
import api from '@/api';
import './index.scss';

const { Title } = Typography;

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: [],
    };
  }

  handleResetPassword = async ({ newPassword, newPasswordConfirmation }) => {
    const { history } = this.props;
    const params = new URLSearchParams(history.location.search);
    const token = params.get('token');

    this.setState({ loading: true });

    try {
      await api.post('/auth/reset-password', {
        token,
        newPassword,
        newPasswordConfirmation,
      });
      this.setState({
        loading: false,
        errors: [],
      });
      notification.success({
        message: 'Reset password',
        description: 'Your password has been updated successfully',
      });
      history.push('/login');
    } catch (error) {
      this.setState({
        loading: false,
        errors: [error.response?.data?.message || 'Unable to reset password'],
      });
      console.error(error);
    }
  };

  render() {
    const { currentUser } = this.props;
    const { loading, errors } = this.state;

    if (currentUser) {
      return <Redirect to="/" />;
    }

    return (
      <div className="authentication-container reset-password-container">
        <Row gutter={16} justify="center" align="middle">
          <Col flex="250px">
            <img src={logoImage} alt="logo" className="authentication-container__logo" />
          </Col>
          <Col flex="1">
            <div className="authentication-container__form">
              <Title level={2} className="authentication-container__title">
                Reset Password
              </Title>
              <ErrorMessage errors={errors} />
              <Form
                name="reset-password-form"
                layout="vertical"
                onFinish={this.handleResetPassword}
                colon={false}
                hideRequiredMark
              >
                <Form.Item
                  name="newPassword"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: 'New password is required',
                    },
                    {
                      min: 8,
                      message: 'New password must have atleast 8 characters',
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="newPasswordConfirmation"
                  label="New password confirmation"
                  dependencies={['newPassword']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'New password confirmation is required',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('New password confirmation is not matched with new password'));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" loading={loading}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    currentUser: auth.currentUser,
  };
};

export default withRouter(connect(mapStateToProps)(ResetPasswordContainer));
