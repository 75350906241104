import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button, Typography,Table, Tag } from 'antd';

import Breadcrumb from '@/components/Breadcumb';
import LayoutContainer from '@/containers/LayoutContainer';

import { fetchGoalStrategies } from '@/actions/goalStrategyActions';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getGoalStrategies } from '@/selectors/goalStrategySelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { canCreateGoalStrategy } from '@/policies/goalStrategyPolicies';
import { displayDateTime } from '@/utils/dateUtils';
import './index.scss';

const { Title } = Typography;

const goalStrategyColumns = [
  {
    title: '#',
    dataIndex: 'id',
    key: 'id',
    render: (id) => {
      return <Link to={`/goal-strategies/${id}/edit`}>#{id}</Link>;
    },
  },
  {
    title: 'Year',
    dataIndex: 'year',
    key: 'year',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    render: (name) => {
      return <Tag color="cyan">{name}</Tag>;
    },
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => {
      return createdAt ? displayDateTime(createdAt) : null;
    },
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (updatedAt) => {
      return updatedAt ? displayDateTime(updatedAt) : null;
    },
  },
];

class GoalStrategyListContainer extends Component {
  componentDidMount() {
    const { dispatchFetchGoalStrategies } = this.props;

    dispatchFetchGoalStrategies();
  }

  render() {
    const { loading, goalStrategies, currentUser } = this.props;

    return (
      <LayoutContainer contentClassName={['content-container', 'goal-strategy-list-container']}>
        <Row gutter={16} align="middle">
          <Col flex="1">
            <Title level={2} className="content-container__title">
              Goal Strategies
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'goal-strategies', link: '/goal-strategies', title: 'Goal Strategies' },
                { key: 'list', title: 'List' },
              ]}
              className="content-container__breadcrumb"
            />
          </Col>
          <Col flex="0">
            {canCreateGoalStrategy(currentUser) && (
              <Link to="/goal-strategies/new">
                <Button type="primary" icon={<PlusOutlined />}>
                  Create New Goal Strategy
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <div className="content-container__section">
          <Table
            rowKey="id"
            dataSource={goalStrategies}
            columns={goalStrategyColumns}
            loading={loading}
            scroll={{
              x: 'max-content',
            }}
            pagination={{
              total: goalStrategies.length,
              defaultPageSize: 50,
              defaultCurrent: 1,
            }}
          />
        </div>
      </LayoutContainer>
    );
  }
}

GoalStrategyListContainer.propTypes = {
  loading: PropTypes.bool,
  goalStrategies: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.object,
  dispatchFetchGoalStrategies: PropTypes.func,
};

const loadingOrganizations = createRequestLoadingSelector(['fetchGoalStrategies']);

const mapStateToProps = (state) => ({
  loading: loadingOrganizations(state),
  goalStrategies: getGoalStrategies(state),
  currentUser: getCurrentUser(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchGoalStrategies: fetchGoalStrategies,
  })(GoalStrategyListContainer),
);
