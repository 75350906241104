import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { Row, Col, Button, Typography, Spin, Space, Modal } from 'antd';
import { SendOutlined, DeleteOutlined } from '@ant-design/icons';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import UserForm from '@/forms/UserForm';

import { fetchTargetUser, updateUser, deleteUser, resendUserActivation } from '@/actions/userActions';
import { fetchOrganizations } from '@/actions/organizationActions';
import { fetchDepartments } from '@/actions/departmentActions';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getSelectableContractorOrganizations } from '@/selectors/organizationSelectors';
import { getSelectableDepartments } from '@/selectors/departmentSelectors';
import { getSelectableUserTypesByCurrentUser, getTargetUser } from '@/selectors/userSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import { canDeleteUser } from '@/policies/userPolicies';
import { MasterRoles } from '@/constants';
import './index.scss';

const { Title, Text } = Typography;

class UserUpdateContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleteConfirmModalVisible: false,
    };
  }

  componentDidMount() {
    const {
      dispatchFetchOrganizations,
      dispatchFetchDepartments,
      dispatchFetchTargetUser,
      match: { params },
    } = this.props;

    dispatchFetchOrganizations({ includes: 'organizationGroups' });
    dispatchFetchDepartments();
    dispatchFetchTargetUser(params.id);
  }

  handleSubmit = (values) => {
    const { dispatchUpdateUser, targetUser, location } = this.props;
    const submittedValues = cloneDeep(values);

    if (MasterRoles.includes(submittedValues.type)) {
      submittedValues.departmentIds = null;
    }

    dispatchUpdateUser(targetUser.id, submittedValues, {
      backUrl: location.state.backUrl,
    });
  }

  handleOpenDeleteConfirmModal = () => {
    this.setState({
      isDeleteConfirmModalVisible: true,
    });
  }

  handleCloseDeleteConfirmModal = () => {
    this.setState({
      isDeleteConfirmModalVisible: false,
    });
  }

  handleDelete = () => {
    const { dispatchDeleteUser, targetUser, location } = this.props;
    dispatchDeleteUser(targetUser.id, {
      backUrl: location.state.backUrl,
    });
    this.handleCloseDeleteConfirmModal();
  }

  handleResendActivation = () => {
    const { dispatchResendUserActivation, targetUser } = this.props;
    dispatchResendUserActivation(targetUser.id);
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      currentUser,
      resendingActivation,
      selectableOrganizations,
      selectableDepartments,
      selectableUserTypes,
      targetUser,
      errors,
      loading,
      submitting,
    } = this.props;
    const {
      isDeleteConfirmModalVisible,
    } = this.state;

    return (
      <LayoutContainer contentClassName={['content-container', 'user-update-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Row gutter={16} align='middle'>
              <Col flex="1">
                <Title level={2} className="content-container__title">
                  Edit User
                </Title>
                <Breadcrumb
                  items={[
                    { key: 'home', link: '/', title: 'Home' },
                    { key: 'users', link: '/users', title: 'Users' },
                    { key: 'update', title: 'Edit User' },
                  ]}
                  className="content-container__breadcrumb"
                />
              </Col>
              <Col flex="0">
                <Space>
                  <Button
                    type="primary"
                    icon={<SendOutlined />}
                    onClick={this.handleResendActivation}
                    loading={resendingActivation}
                    disabled={targetUser ? targetUser.isActived : true}
                  >
                    Resend Activation Email
                  </Button>
                  {canDeleteUser(currentUser) && (
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={this.handleOpenDeleteConfirmModal}
                      loading={submitting}
                    >
                      Delete
                    </Button>
                  )}
                </Space>
              </Col>
            </Row>
            {!targetUser || loading ? (
              <Spin />
            ) : (
              <UserForm
                submitting={submitting}
                submitTitle='Update User'
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formObject={{
                  id: targetUser.id,
                  email: targetUser.email,
                  firstName: targetUser.firstName,
                  lastName: targetUser.lastName,
                  disabled: targetUser.disabled,
                  organizationId: targetUser.organizationId,
                  organizationGroupId: targetUser.organizationGroupId,
                  departmentIds: targetUser.departmentIds || [],
                  type: targetUser.type,
                }}
                formErrors={errors}
                typeSelectOptions={selectableUserTypes}
                organizationSelectOptions={selectableOrganizations}
                departmentSelectOptions={selectableDepartments}
                canSelectOrganization={!currentUser.organizationId}
              />
            )}
          </Col>
        </Row>
        <Modal
          title="Are you sure?"
          visible={isDeleteConfirmModalVisible}
          onCancel={this.handleCloseDeleteConfirmModal}
          onOk={this.handleDelete}
          cancelText="No"
          okText="Yes"
          okButtonProps={{
            danger: true,
          }}
        >
          <Text>
            Deleting this user will remove any jobs associated with this user from your company&apos;s dashboard.
            If you wish to retain that data, disable the user instead deleting them.
          </Text>
        </Modal>
      </LayoutContainer>
    );
  }
}

UserUpdateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  targetUser: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectableOrganizations: PropTypes.arrayOf(PropTypes.object),
  selectableUserTypes: PropTypes.arrayOf(PropTypes.string),
  dispatchFetchOrganizations: PropTypes.func,
  dispatchFetchDepartments: PropTypes.func,
  dispatchFetchTargetUser: PropTypes.func,
  dispatchUpdateUser: PropTypes.func,
  dispatchDeleteUser: PropTypes.func,
  dispatchResendUserActivation: PropTypes.func,
};

const loadingData = createRequestLoadingSelector(['fetchOrganizations', 'fetchDepartments', 'fetchTargetUser']);
const submittingUser = createRequestLoadingSelector(['updateUser', 'deleteUser']);
const resendingUserActivation = createRequestLoadingSelector(['resendUserActivation']);

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  loading: loadingData(state),
  submitting: submittingUser(state),
  targetUser: getTargetUser(state),
  resendingActivation: resendingUserActivation(state),
  selectableUserTypes: getSelectableUserTypesByCurrentUser(state),
  selectableOrganizations: getSelectableContractorOrganizations(state),
  selectableDepartments: getSelectableDepartments(state),
  currentUser: getCurrentUser(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchOrganizations: fetchOrganizations,
    dispatchFetchDepartments: fetchDepartments,
    dispatchFetchTargetUser: fetchTargetUser,
    dispatchUpdateUser: updateUser,
    dispatchDeleteUser: deleteUser,
    dispatchResendUserActivation: resendUserActivation,
  })(UserUpdateContainer),
);
