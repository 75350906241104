import { Roles } from '@/constants';

export const canManageJobType = (user) => {
  return [Roles.Admin].includes(user?.type);
};

export const canViewJobType = (user) => {
  return canManageJobType(user);
};

export const canCreateJobType = (user) => {
  return canManageJobType(user);
};

export const canUpdateJobType = (user) => {
  return canManageJobType(user);
};

export const canDeleteJobType = (user) => {
  return canManageJobType(user);
};
