import { createSelector } from 'reselect';
import { getCurrentUser } from '@/selectors/authSelectors';
import { Roles } from '@/constants';

export const getUserTypes = (_state) => {
  return Object.values(Roles);
};

export const getSelectableUserTypesByCurrentUser = createSelector(
  [getCurrentUser],
  (currentUser) => {
    switch (currentUser?.type) {
      case Roles.Admin:
        return [
          Roles.Admin,
          Roles.Trainer,
          Roles.Owner,
          Roles.Manager,
          Roles.Employee,
        ];
      case Roles.Owner:
      case Roles.Manager:
        return [
          Roles.Owner,
          Roles.Manager,
          Roles.Employee,
        ];
      case Roles.Trainer:
        return [
          Roles.Trainer,
          Roles.Owner,
          Roles.Manager,
          Roles.Employee,
        ];
      default:
        return [];
    }
  }
);

export const getUserList = (state) => {
  return {
    page: state.user.page,
    limit: state.user.limit,
    sort: state.user.sort,
    order: state.user.order,
    items: state.user.items,
    totalCount: state.user.totalCount,
    filteredEmail: state.user.filteredEmail,
    filteredOrganizationId: state.user.filteredOrganizationId,
  };
};

export const getTargetUser = (state) => {
  return state.user.target;
};
