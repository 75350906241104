import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Typography, Spin } from 'antd';
import { sortBy, compact } from 'lodash';

import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import GoalForm from '@/forms/GoalForm';

import { fetchOrganizations } from '@/actions/organizationActions';
import { fetchGoalStrategies } from '@/actions/goalStrategyActions';
import { createGoal, fetchTargetOrganization } from '@/actions/goalActions';
import { getSelectableContractorOrganizations } from '@/selectors/organizationSelectors';
import { getTargetOrganization } from '@/selectors/goalSelectors';
import { getGoalStrategies } from '@/selectors/goalStrategySelectors';
import { getCurrentUser } from '@/selectors/authSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import { MasterRoles, ManagerRoles, WorkerRoles } from '@/constants';
import './index.scss';

const { Title } = Typography;

const customGoalStrategy = {
  id: 'customGoalStrategy',
  name: 'Custom',
  year: null,
  jan: 0,
  feb: 0,
  mar: 0,
  apr: 0,
  may: 0,
  jun: 0,
  jul: 0,
  aug: 0,
  sep: 0,
  oct: 0,
  nov: 0,
  dec: 0,
  isGlobal: false,
};

class GoalCreateContainer extends Component {
  componentDidMount() {
    const {
      currentUser,
      dispatchFetchOrganizations,
      dispatchFetchTargetOrganization,
      dispatchFetchGoalStrategies,
    } = this.props;

    if (MasterRoles.includes(currentUser?.type)) {
      dispatchFetchOrganizations();
    } else {
      dispatchFetchTargetOrganization(currentUser?.organizationId);
    }
    dispatchFetchGoalStrategies();
  }

  handleSubmit = (values) => {
    const { dispatchCreateGoal } = this.props;
    const { userId } = values;

    delete values.organizationId;
    delete values.userId;
    if (values.goalStrategyId === 'customGoalStrategy') {
      delete values.goalStrategyId;
    } else {
      delete values.goalStrategy;
    }

    dispatchCreateGoal(userId, values);
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  handleChangeOrganization = (organizationId) => {
    const { dispatchFetchTargetOrganization } = this.props;
    dispatchFetchTargetOrganization(organizationId);
  }

  render() {
    const {
      errors,
      loading,
      submitting,
      currentUser,
      targetOrganization,
      selectableOrganizations,
      selectableGoalStrategies,
      selectableUsers,
    } = this.props;

    const canSelectOrganization = MasterRoles.includes(currentUser?.type);
    const canSelectUser = [...MasterRoles, ...ManagerRoles].includes(currentUser?.type);

    return (
      <LayoutContainer contentClassName={['content-container', 'goal-create-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Title level={2} className="content-container__title">
              Create New Goal
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'goals', link: '/goals', title: 'Goals' },
                { key: 'create', title: 'Create New Goal' },
              ]}
              className="content-container__breadcrumb"
            />
            {loading ? (
              <Spin />
            ) : (
              <GoalForm
                mode="create"
                submitting={submitting}
                submitTitle='Create Goal'
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formObject={{
                  userId: !canSelectUser
                    ? currentUser.id
                    : null,
                  organizationId: targetOrganization?.id,
                }}
                formErrors={errors}
                canSelectUser={canSelectUser}
                canSelectOrganization={canSelectOrganization}
                userSelectOptions={selectableUsers}
                organizationSelectOptions={selectableOrganizations}
                goalStrategySelectOptions={selectableGoalStrategies}
                onChangeOrganization={this.handleChangeOrganization}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

GoalCreateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  currentUser: PropTypes.object,
  targetOrganization: PropTypes.object,
  selectableGoalStrategies: PropTypes.arrayOf(PropTypes.object),
  selectableOrganizations: PropTypes.arrayOf(PropTypes.object),
  selectableUsers: PropTypes.arrayOf(PropTypes.object),
  dispatchFetchOrganizations: PropTypes.func,
  dispatchFetchTargetOrganization: PropTypes.func,
  dispatchFetchGoalStrategies: PropTypes.func,
  dispatchCreateGoal: PropTypes.func,
};

const loadingData = createRequestLoadingSelector([
  'fetchOrganizations',
  'fetchTargetOrganization',
  'fetchGoalStrategies',
]);
const submittingGoal = createRequestLoadingSelector(['createGoal']);

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const targetOrganization = getTargetOrganization(state);
  const goalStrategies = getGoalStrategies(state);

  const selectableUsers = sortBy(
    targetOrganization?.users?.filter(({ type, disabled }) =>
      [...ManagerRoles, ...WorkerRoles].includes(type) && !disabled,
    ) || [],
    (user) => `${user.firstName} ${user.lastName}`.toLowerCase(),
  );
  const selectableGoalStrategies = sortBy(
    [...goalStrategies, customGoalStrategy],
    (goalStrategy) => goalStrategy.name?.toLowerCase(),
  );

  return {
    errors: getErrors(state),
    loading: loadingData(state),
    submitting: submittingGoal(state),
    selectableOrganizations: MasterRoles.includes(currentUser?.type)
      ? getSelectableContractorOrganizations(state)
      : compact([targetOrganization]),
    selectableGoalStrategies,
    selectableUsers,
    currentUser,
    targetOrganization,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchOrganizations: fetchOrganizations,
    dispatchFetchTargetOrganization: fetchTargetOrganization,
    dispatchFetchGoalStrategies: fetchGoalStrategies,
    dispatchCreateGoal: createGoal,
  })(GoalCreateContainer),
);
