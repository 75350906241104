import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compact, sortBy } from 'lodash';

import { Row, Col, Typography, Spin } from 'antd';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import JobForm from '@/forms/JobForm';

import { fetchDepartments } from '@/actions/departmentActions';
import { fetchOrganizations } from '@/actions/organizationActions';
import { fetchJobTargetOrganization, createJob } from '@/actions/jobActions';
import { getSelectableContractorOrganizations } from '@/selectors/organizationSelectors';
import { getTargetOrganization } from '@/selectors/jobSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getSelectableDepartments } from '@/selectors/departmentSelectors';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import { MasterRoles, ManagerRoles, WorkerRoles } from '@/constants';
import './index.scss';

const { Title } = Typography;

class JobCreateContainer extends Component {
  componentDidMount() {
    const {
      dispatchFetchDepartments,
      dispatchFetchOrganizations,
      dispatchFetchJobTargetOrganization,
      currentUser,
    } = this.props;

    if (MasterRoles.includes(currentUser?.type)) {
      dispatchFetchOrganizations();
    } else {
      dispatchFetchJobTargetOrganization(currentUser.organizationId);
    }
    dispatchFetchDepartments();
  }

  handleSubmit = (values) => {
    const { dispatchCreateJob, location } = this.props;

    dispatchCreateJob({
      ...values,
      date: values.date
        ? values.date.format('YYYY-MM-DD')
        : null,
    }, {
      backUrl: location.state.backUrl,
    });
  };

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleChangeOrganization = (organizationId) => {
    const { dispatchFetchJobTargetOrganization } = this.props;
    dispatchFetchJobTargetOrganization(organizationId);
  }

  render() {
    const {
      errors,
      loading,
      submitting,
      currentUser,
      targetOrganization,
      selectableJobTypes,
      selectableOpportunityTypes,
      selectableOrganizations,
      selectableDepartments,
      selectableUsers,
    } = this.props;
    const canSelectOrganization = MasterRoles.includes(currentUser?.type);
    const canSelectUser = [...MasterRoles, ...ManagerRoles].includes(currentUser?.type);

    return (
      <LayoutContainer contentClassName={['content-container', 'job-create-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Title level={2} className="content-container__title">
              Create New Job
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'jobs', link: '/jobs', title: 'Jobs' },
                { key: 'create', title: 'Create New Job' },
              ]}
              className="content-container__breadcrumb"
            />
            {loading ? (
              <Spin />
            ) : (
              <JobForm
                submitting={submitting}
                submitTitle='Create Job'
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formErrors={errors}
                formObject={{
                  id: null,
                  date: null,
                  name: null,
                  jobTypeId: null,
                  replacementOpp: false,
                  techLeadTurnOver: false,
                  opportunities: [],
                  userId: !canSelectUser
                    ? currentUser.id
                    : null,
                  organizationId: targetOrganization?.id,
                  departmentId: null,
                }}
                canSelectDepartment
                canSelectUser={canSelectUser}
                canSelectOrganization={canSelectOrganization}
                userSelectOptions={selectableUsers}
                jobTypeSelectOptions={selectableJobTypes}
                opportunityTypeSelectOptions={selectableOpportunityTypes}
                organizationSelectOptions={selectableOrganizations}
                departmentSelectOptions={selectableDepartments}
                onChangeOrganization={this.handleChangeOrganization}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

JobCreateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectableJobTypes: PropTypes.arrayOf(PropTypes.object),
  selectableOpportunityTypes: PropTypes.arrayOf(PropTypes.object),
  selectableOrganizations: PropTypes.arrayOf(PropTypes.object),
  selectableUsers: PropTypes.arrayOf(PropTypes.object),
  selectableDepartments: PropTypes.arrayOf(PropTypes.object),
  dispatchFetchDepartments: PropTypes.func,
  dispatchFetchJobTargetOrganization: PropTypes.func,
  dispatchCreateJob: PropTypes.func,
  targetOrganization: PropTypes.object,
  currentUser: PropTypes.object,
};

const loadingInputData = createRequestLoadingSelector([
  'fetchDepartments',
  'fetchOrganizations',
  'fetchJobTargetOrganization',
]);
const submittingOrganization = createRequestLoadingSelector(['createJob']);

const getOrganizationUsers = (organization, currentUser) => {
  if (!organization || !currentUser) {
    return [];
  }

  let users = organization.users.filter((user) => !user.disabled);
  if (WorkerRoles.includes(currentUser.type)) {
    users = users.filter(({ id }) => id.toString() === currentUser.id?.toString());
  }
  users = users.map((user) => {
    let fullname = `${user.firstName} ${user.lastName}`;

    if (ManagerRoles.includes(currentUser.type) && currentUser?.id === user.id) {
      fullname = `${fullname} (You)`;
    }

    return {
      ...user,
      fullname,
    };
  });
  users = sortBy(users, (user) => user.fullname?.toLowerCase());
  return users;
};

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const targetOrganization = getTargetOrganization(state);

  return {
    errors: getErrors(state),
    loading: loadingInputData(state),
    submitting: submittingOrganization(state),
    selectableDepartments: getSelectableDepartments(state),
    selectableOrganizations: MasterRoles.includes(currentUser?.type)
      ? getSelectableContractorOrganizations(state)
      : compact([targetOrganization]),
    selectableUsers: getOrganizationUsers(targetOrganization, currentUser),
    selectableJobTypes: targetOrganization?.jobTypes || [],
    selectableOpportunityTypes: targetOrganization?.opportunityTypes || [],
    targetOrganization,
    currentUser,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    dispatchCreateJob: createJob,
    dispatchFetchOrganizations: fetchOrganizations,
    dispatchFetchJobTargetOrganization: fetchJobTargetOrganization,
    dispatchFetchDepartments: fetchDepartments,
  })(JobCreateContainer),
);
