import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';

export const getJobTypes = (state) => {
  return state.jobType.items;
};

export const getTargetJobType = (state) => {
  return state.jobType.target;
};

export const getSelectableJobTypes = createSelector(getJobTypes, (jobTypes) => {
  const usedJobTypes = jobTypes.map(({ id, name, departmentIds }) => ({ id, name, departmentIds }));
  return sortBy(usedJobTypes, (jobType) => jobType.name?.toLowerCase());
});
