import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from '@/routes/PrivateRoute';
import NotFoundContainer from '@/containers/NotFoundContainer';
import LoginContainer from '@/containers/LoginContainer';
import ForgotPasswordContainer from '@/containers/ForgotPasswordContainer';
import ResetPasswordContainer from '@/containers/ResetPasswordContainer';
import ActiveAccountContainer from '@/containers/ActiveAccountContainer';
import DashboardContainer from '@/containers/DashboardContainer';
import HomeContainer from '@/containers/HomeContainer';
import UserListContainer from '@/containers/UserListContainer';
import UserCreateContainer from '@/containers/UserCreateContainer';
import UserUpdateContainer from '@/containers/UserUpdateContainer';
import OpportunityTypeListContainer from '@/containers/OpportunityTypeListContainer';
import OpportunityTypeCreateContainer from '@/containers/OpportunityTypeCreateContainer';
import OpportunityTypeUpdateContainer from '@/containers/OpportunityTypeUpdateContainer';
import OrganizationListContainer from '@/containers/OrganizationListContainer';
import OrganizationCreateContainer from '@/containers/OrganizationCreateContainer';
import OrganizationUpdateContainer from '@/containers/OrganizationUpdateContainer';
import JobListContainer from '@/containers/JobListContainer';
import JobCreateContainer from '@/containers/JobCreateContainer';
import JobUpdateContainer from '@/containers/JobUpdateContainer';
import JobTypeListContainer from '@/containers/JobTypeListContainer';
import JobTypeCreateContainer from '@/containers/JobTypeCreateContainer';
import JobTypeUpdateContainer from '@/containers/JobTypeUpdateContainer';
import GoalListContainer from '@/containers/GoalListContainer';
import GoalCreateContainer from '@/containers/GoalCreateContainer';
import GoalUpdateContainer from '@/containers/GoalUpdateContainer';
import GoalStrategyListContainer from '@/containers/GoalStrategyListContainer';
import GoalStrategyCreateContainer from '@/containers/GoalStrategyCreateContainer';
import GoalStrategyUpdateContainer from '@/containers/GoalStrategyUpdateContainer';
import RankingContainer from '@/containers/RankingContainer';
import KpisSetContainer from '@/containers/KpisSetContainer';

import {
  canViewUser,
  canCreateUser,
  canUpdateUser,
} from '@/policies/userPolicies';
import {
  canViewJobType,
  canCreateJobType,
  canUpdateJobType,
} from '@/policies/jobTypePolicies';
import {
  canViewOpportunityType,
  canCreateOpportunityType,
  canUpdateOpportunityType,
} from '@/policies/opportunityTypePolicies';
import {
  canViewOrganization,
  canCreateOrganization,
  canUpdateOrganization,
} from '@/policies/organizationPolicies';
import {
  canViewJob,
  canCreateJob,
  canUpdateJob,
} from '@/policies/jobPolicies';
import {
  canViewGoal,
  canCreateGoal,
  canUpdateGoal,
} from '@/policies/goalPolicies';
import {
  canViewGoalStrategy,
  canCreateGoalStrategy,
  canUpdateGoalStrategy,
} from '@/policies/goalStrategyPolicies';
import {
  canSetKpi,
} from '@/policies/kpiPolicies';

function Routes() {
  return (
    <Switch>
      <Route
        path='/login'
        component={LoginContainer}
      />
      <Route
        path='/forgot-password'
        component={ForgotPasswordContainer}
      />
      <Route
        path='/reset-password'
        component={ResetPasswordContainer}
      />
      <Route
        path='/activation'
        component={ActiveAccountContainer}
      />
      <PrivateRoute
        exact
        path='/'
        component={HomeContainer}
      />
      <PrivateRoute
        exact
        path='/dashboard'
        component={DashboardContainer}
      />
      <PrivateRoute
        exact
        path='/users'
        authorize={canViewUser}
        component={UserListContainer}
      />
      <PrivateRoute
        path='/users/new'
        authorize={canCreateUser}
        component={UserCreateContainer}
      />
      <PrivateRoute
        path='/users/:id/edit'
        authorize={canUpdateUser}
        component={UserUpdateContainer}
      />
      <PrivateRoute
        exact
        path='/job-types'
        authorize={canViewJobType}
        component={JobTypeListContainer}
      />
      <PrivateRoute
        path='/job-types/new'
        authorize={canCreateJobType}
        component={JobTypeCreateContainer}
      />
      <PrivateRoute
        path='/job-types/:id/edit'
        authorize={canUpdateJobType}
        component={JobTypeUpdateContainer}
      />
      <PrivateRoute
        exact
        path='/opportunity-types'
        authorize={canViewOpportunityType}
        component={OpportunityTypeListContainer}
      />
      <PrivateRoute
        path='/opportunity-types/new'
        authorize={canCreateOpportunityType}
        component={OpportunityTypeCreateContainer}
      />
      <PrivateRoute
        path='/opportunity-types/:id/edit'
        authorize={canUpdateOpportunityType}
        component={OpportunityTypeUpdateContainer}
      />
      <PrivateRoute
        exact
        path='/organizations'
        authorize={canViewOrganization}
        component={OrganizationListContainer}
      />
      <PrivateRoute
        path='/organizations/new'
        authorize={canCreateOrganization}
        component={OrganizationCreateContainer}
      />
      <PrivateRoute
        path='/organizations/:id/edit'
        authorize={canUpdateOrganization}
        component={OrganizationUpdateContainer}
      />
      <PrivateRoute
        exact
        path='/jobs'
        authorize={canViewJob}
        component={JobListContainer}
      />
      <PrivateRoute
        path='/jobs/new'
        authorize={canCreateJob}
        component={JobCreateContainer}
      />
      <PrivateRoute
        path='/jobs/:id/edit'
        authorize={canUpdateJob}
        component={JobUpdateContainer}
      />
      <PrivateRoute
        exact
        path='/goals'
        authorize={canViewGoal}
        component={GoalListContainer}
      />
      <PrivateRoute
        path='/goals/new'
        authorize={canCreateGoal}
        component={GoalCreateContainer}
      />
      <PrivateRoute
        path='/goals/edit/:userId/:year'
        authorize={canUpdateGoal}
        component={GoalUpdateContainer}
      />
      <PrivateRoute
        exact
        path='/goal-strategies'
        authorize={canViewGoalStrategy}
        component={GoalStrategyListContainer}
      />
      <PrivateRoute
        path='/goal-strategies/new'
        authorize={canCreateGoalStrategy}
        component={GoalStrategyCreateContainer}
      />
      <PrivateRoute
        path='/goal-strategies/:id/edit'
        authorize={canUpdateGoalStrategy}
        component={GoalStrategyUpdateContainer}
      />
      <PrivateRoute
        exact
        path='/kpis'
        authorize={canSetKpi}
        component={KpisSetContainer}
      />
      <PrivateRoute
        exact
        path='/rankings/:rankingType'
        component={RankingContainer}
      />
      <Route
        path='*'
        component={NotFoundContainer}
      />
    </Switch>
  );
}

export default Routes;
