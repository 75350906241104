import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';

export const getDepartments = (state) => {
  return state.department.items;
};

export const getSelectableDepartments = createSelector(getDepartments, (departments) => {
  const dpms = departments.map(({ id, name, hasRevenueThreshold }) => ({ id, name, hasRevenueThreshold }));
  return sortBy(dpms, (dpm) => dpm.name?.toLowerCase());
});
