import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Typography, Button, Space, Form, Input, Checkbox } from 'antd';

import ErrorMessage from '@/components/ErrorMessage';

const { Paragraph } = Typography;

const OpportunityTypeForm = (props) => {
  const {
    submitting,
    submitTitle,
    formObject,
    formErrors,
    departmentSelectOptions,
    onCancel,
    onSubmit,
  } = props;

  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      form.resetFields();
    }
  }, [form, formObject, updated]);

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      initialValues={formObject}
      onValuesChange={() => setUpdated(true)}
      colon={false}
      layout="vertical"
      className="content-container__form"
      hideRequiredMark
    >
      <ErrorMessage
        errors={formErrors}
      />
      <Paragraph>
        Please input the information below:
      </Paragraph>
      <Form.Item
        name="name"
        label="Name"
        rules={[
          { required: true, message: 'Name is required' },
        ]}
        hasFeedback
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item
        name="departmentIds"
        label="Departments"
        hasFeedback
      >
        <Checkbox.Group>
          {departmentSelectOptions.map(({ id, name }) => (
            <Checkbox value={`${id}`} key={id}>
              {name}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {submitTitle || 'Submit'}
          </Button>
          {onCancel && (
            <Button htmlType="button" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

OpportunityTypeForm.propTypes = {
  submitting: PropTypes.bool,
  submitTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  formObject: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  formErrors: PropTypes.arrayOf(PropTypes.string),
  departmentSelectOptions: PropTypes.arrayOf(PropTypes.object),
};

export default OpportunityTypeForm;
