import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col, Button, Typography, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import GoalStrategyForm from '@/forms/GoalStrategyForm';

import { fetchTargetGoalStrategy, updateGoalStrategy, deleteGoalStrategy } from '@/actions/goalStrategyActions';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getTargetGoalStrategy } from '@/selectors/goalStrategySelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { canDeleteGoalStrategy } from '@/policies/goalStrategyPolicies';
import { getErrors } from '@/selectors/errorSelectors';
import './index.scss';

const { Title } = Typography;

class GoalStrategyUpdateContainer extends Component {
  componentDidMount() {
    const {
      dispatchFetchTargetGoalStrategy,
      match: { params },
    } = this.props;
    dispatchFetchTargetGoalStrategy(params.id);
  }

  handleSubmit = (values) => {
    const { dispatchUpdateGoalStrategy, targetGoalStrategy } = this.props;
    dispatchUpdateGoalStrategy(targetGoalStrategy.id, values);
  }

  handleDelete = () => {
    const { dispatchDeleteGoalStrategy, targetGoalStrategy } = this.props;
    dispatchDeleteGoalStrategy(targetGoalStrategy.id);
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      currentUser,
      targetGoalStrategy,
      errors,
      loading,
      submitting,
    } = this.props;

    return (
      <LayoutContainer contentClassName={['content-container', 'goal-strategy-update-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Row gutter={16} align='middle'>
              <Col flex="1">
                <Title level={2} className="content-container__title">
                  Edit Goal Strategy
                </Title>
                <Breadcrumb
                  items={[
                    { key: 'home', link: '/', title: 'Home' },
                    { key: 'goal-strategies', link: '/goal-strategies', title: 'Goal Strategies' },
                    { key: 'update', title: 'Edit Goal Strategy' },
                  ]}
                  className="content-container__breadcrumb"
                />
              </Col>
              <Col flex="0">
                {canDeleteGoalStrategy(currentUser) && (
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={this.handleDelete}
                    loading={submitting}
                  >
                    Delete
                  </Button>
                )}
              </Col>
            </Row>
            {!targetGoalStrategy || loading ? (
              <Spin />
            ) : (
              <GoalStrategyForm
                submitting={submitting}
                submitTitle='Update Goal Strategy'
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formObject={targetGoalStrategy}
                formErrors={errors}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

GoalStrategyUpdateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  targetGoalStrategy: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
  dispatchFetchTargetGoalStrategy: PropTypes.func,
  dispatchUpdateGoalStrategy: PropTypes.func,
  dispatchDeleteGoalStrategy: PropTypes.func,
};

const loadingData = createRequestLoadingSelector(['fetchTargetGoalStrategy']);
const submittingGoalStrategy = createRequestLoadingSelector(['updateGoalStrategy', 'deleteGoalStrategy']);

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  loading: loadingData(state),
  submitting: submittingGoalStrategy(state),
  targetGoalStrategy: getTargetGoalStrategy(state),
  currentUser: getCurrentUser(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchTargetGoalStrategy: fetchTargetGoalStrategy,
    dispatchUpdateGoalStrategy: updateGoalStrategy,
    dispatchDeleteGoalStrategy: deleteGoalStrategy,
  })(GoalStrategyUpdateContainer),
);
