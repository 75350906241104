import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Table, Button, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { sortBy } from 'lodash';

import Breadcrumb from '@/components/Breadcumb';
import LayoutContainer from '@/containers/LayoutContainer';

import { fetchGoals, fetchTargetOrganization, clearTargetOrganization } from '@/actions/goalActions';
import { fetchOrganizations } from '@/actions/organizationActions';
import { getGoals, getTargetOrganization } from '@/selectors/goalSelectors';
import { getSelectableContractorOrganizations } from '@/selectors/organizationSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getCurrentUser } from '@/selectors/authSelectors';
import { canCreateGoal } from '@/policies/goalPolicies';
import { displayCurrency } from '@/utils/numberUtils';
import { selectSearchByChildrenText } from '@/utils/selectUtils';
import { WorkerRoles, ManagerRoles } from '@/constants';
import './index.scss';

const { Title, Text } = Typography;
const { Option } = Select;

const sortAttributeNumber = (attribute) => {
  return (x, y) => {
    const xValue = x[attribute] || 0;
    const yValue = y[attribute] || 0;

    return xValue - yValue;
  };
};

const sortAttributeString = (attribute) => {
  return (x, y) => {
    const xValue = (x[attribute] || '').toLowerCase();
    const yValue = (y[attribute] || '').toLowerCase();

    if (xValue < yValue) {
      return -1;
    }
    if (xValue > yValue) {
      return 1;
    }
    return 0;
  };
};

const generateGoalColumns = (currentUser, organizationId) => [
  {
    title: 'User',
    dataIndex: 'userName',
    key: 'userName',
    sorter: sortAttributeString('userName'),
    render: (userName, record) => {
      return (
        <Link
          to={`/goals/edit/${record.userId}/${record.year}${
            currentUser.organizationId ? '' : `?organizationId=${organizationId}`
          }`}
        >
          {userName}
        </Link>
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    sorter: sortAttributeNumber('totalAmount'),
    render: (totalAmount) => {
      return displayCurrency(totalAmount);
    },
  },
  {
    title: 'Year',
    dataIndex: 'year',
    key: 'year',
    sorter: sortAttributeNumber('year'),
  },
];

class GoalListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterdUserId: null,
    };
  }

  componentDidMount() {
    const {
      currentUser,
      dispatchOrganizations,
      dispatchFetchTargetOrganization,
      dispatchFetchGoals,
    } = this.props;

    dispatchOrganizations();
    if (currentUser && currentUser.organizationId) {
      dispatchFetchTargetOrganization(currentUser.organizationId);
      dispatchFetchGoals({ organizationId: currentUser.organizationId });

      if (WorkerRoles.includes(currentUser.type)) {
        this.setState({ filterdUserId: currentUser.id });
      } else {
        this.setState({ filterdUserId: null });
      }
    }
  }

  getSelectableUsers = () => {
    const { targetOrganization } = this.props;
    const users = targetOrganization
      ? targetOrganization.users
          .filter(({ type, disabled }) =>
            [...ManagerRoles, ...WorkerRoles].includes(type) && !disabled)
      : [];
    return sortBy(users, (user) => `${user.firstName} ${user.lastName}`.toLowerCase());
  };

  getTableGoals = () => {
    const { filterdUserId } = this.state;
    const { targetOrganization, goals } = this.props;

    const users = targetOrganization ? targetOrganization.users : [];
    const userNameMapper = users.reduce((memo, item) => ({
      ...memo,
      [item.id]: `${item.firstName} ${item.lastName}`
    }), {});

    return (filterdUserId
        ? goals.filter((goal) => `${goal.userId}` === `${filterdUserId}`)
        : goals
      ).map((goal) => ({
        ...goal,
        userName: userNameMapper[goal.userId],
        key: `${goal.userId}-${goal.year}`,
      }));
  };

  handleSelectOrganization = (organizationId) => {
    const {
      currentUser,
      dispatchFetchTargetOrganization,
      dispatchClearTargetOrganization,
      dispatchFetchGoals,
    } = this.props;

    if (organizationId) {
      dispatchFetchTargetOrganization(organizationId);
      dispatchFetchGoals({ organizationId });
    } else {
      dispatchClearTargetOrganization();
    }

    if (WorkerRoles.includes(currentUser.type)) {
      this.setState({ filterdUserId: currentUser.id });
    } else {
      this.setState({ filterdUserId: null });
    }
  }

  handleSelectUser = (filterdUserId) => {
    this.setState({ filterdUserId });
  };

  render() {
    const { filterdUserId } = this.state;
    const { currentUser, organizations, targetOrganization, loading } = this.props;
    const goalColumns = generateGoalColumns(currentUser, targetOrganization?.id);

    return (
      <LayoutContainer contentClassName={['content-container', 'goal-list-container']}>
        <Row>
          <Col span={16}>
            <Row gutter={16} align="middle">
              <Col flex="1">
                <Title level={2} className="content-container__title">
                  Goals
                </Title>
                <Breadcrumb
                  items={[
                    { key: 'home', link: '/', title: 'Home' },
                    { key: 'goals', title: 'Goals' },
                  ]}
                  className="content-container__breadcrumb"
                />
              </Col>
              <Col flex="0">
                {canCreateGoal(currentUser) && (
                  <Link to="/goals/new">
                    <Button type="primary" icon={<PlusOutlined />}>
                      Create New Goal
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
            <Row className="goal-list-container__filter">
              <Col span={6}>
                <Space direction="vertical" className="goal-list-container__filter-item">
                  <Text>Organization</Text>
                  <Select
                    placeholder="Select Organization"
                    className="goal-list-container__select"
                    optionFilterProp="children"
                    filterOption={selectSearchByChildrenText}
                    value={targetOrganization?.id}
                    loading={loading}
                    disabled={!!currentUser.organizationId}
                    onChange={this.handleSelectOrganization}
                    showSearch
                    allowClear
                  >
                    {organizations.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </Space>
              </Col>
              <Col span={6}>
                <Space direction="vertical" className="goal-list-container__filter-item">
                  <Text>User</Text>
                  <Select
                    showSearch
                    placeholder="Select User"
                    className="goal-list-container__select"
                    optionFilterProp="children"
                    filterOption={selectSearchByChildrenText}
                    onChange={this.handleSelectUser}
                    loading={loading}
                    disabled={!targetOrganization || WorkerRoles.includes(currentUser.type)}
                    value={filterdUserId}
                  >
                    <Option value={null}>
                      All
                    </Option>
                    {this.getSelectableUsers().map(({ id, firstName, lastName }) => (
                      <Option key={id} value={id}>
                        {`${firstName} ${lastName}`}
                      </Option>
                    ))}
                  </Select>
                </Space>
              </Col>
            </Row>
            <Table
              rowKey="key"
              dataSource={this.getTableGoals()}
              columns={goalColumns}
              loading={loading}
              pagination={false}
              scroll={{
                x: 'max-content',
              }}
            />
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

const loadingData = createRequestLoadingSelector(['fetchOrganizations', 'fetchTargetOrganization', 'fetchGoals']);

const mapStateToProps = (state) => ({
  goals: getGoals(state),
  loading: loadingData(state),
  currentUser: getCurrentUser(state),
  organizations: getSelectableContractorOrganizations(state),
  targetOrganization: getTargetOrganization(state),
});

export default connect(mapStateToProps, {
  dispatchOrganizations: fetchOrganizations,
  dispatchFetchTargetOrganization: fetchTargetOrganization,
  dispatchClearTargetOrganization: clearTargetOrganization,
  dispatchFetchGoals: fetchGoals,
})(GoalListContainer);
