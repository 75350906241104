import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'job',
  initialState: {
    page: 1,
    limit: 50,
    items: [],
    sort: null,
    order: null,
    target: null,
    totalCount: 0,
    filteredOrganizationUsers: [],
    filteredOrganizationId: null,
    filteredUserId: null,
    filteredFromDate: null,
    filteredToDate: null,
    targetOrganization: null,
  },
  reducers: {
    setJobs(state, { payload }) {
      state.page = payload.page;
      state.limit = payload.limit;
      state.items = payload.items;
      state.sort = payload.sort;
      state.order = payload.order;
      state.totalCount = payload.totalCount;
      state.filteredOrganizationId = payload.filteredOrganizationId;
      state.filteredUserId = payload.filteredUserId;
      state.filteredFromDate = payload.filteredFromDate;
      state.filteredToDate = payload.filteredToDate;
      state.loading = false;
    },
    setFilteredOrganizationUsers(state, { payload }) {
      state.filteredOrganizationUsers = payload.users;
    },
    setFilters(state, { payload }) {
      state.filteredOrganizationId = payload.filteredOrganizationId;
      state.filteredUserId = payload.filteredUserId;
      state.filteredFromDate = payload.filteredFromDate;
      state.filteredToDate = payload.filteredToDate;
    },
    setTargetJob(state, { payload: { target } }) {
      state.target = target;
    },
    setTargetOrganization(state, { payload: { targetOrganization } }) {
      state.targetOrganization = targetOrganization;
    },
  },
});

export const {
  setJobs,
  setFilteredOrganizationUsers,
  setFilters,
  setTargetJob,
  setTargetOrganization,
} = slice.actions;

export default slice.reducer;
