import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { notification } from 'antd';

import { getCurrentUser } from '@/selectors/authSelectors';

function PrivateRoute({ component: Component, currentUser, authorize, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        }

        if (authorize && !authorize(currentUser)) {
          notification.error({
            message: 'Permission Denied',
            description: 'You are not authorized to perform this action',
          });
          return <Redirect to={{ pathname: '/' }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUser(state),
  };
};

export default connect(mapStateToProps)(PrivateRoute);
