import { notification } from 'antd';

import api from '@/api';
import history from '@/history';
import { setGoalStrategies, setTargetGoalStrategy } from '@/store/goalStrategy';
import { doRequest, finishRequest } from '@/store/request';
import { createErrors } from '@/actions/errorActions';
import { transformErrors } from '@/utils/errorUtils';

export const fetchGoalStrategies = () => async (dispatch) => {
  dispatch(doRequest('fetchGoalStrategies'));

  try {
    const { data: items } = await api.get('/goal-strategies');
    dispatch(setGoalStrategies({ items }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchGoalStrategies'));
  }
};

export const fetchTargetGoalStrategy = (id) => async (dispatch) => {
  dispatch(doRequest('fetchTargetGoalStrategy'));

  try {
    const { data: goalStrategy } = await api.get(`/goal-strategies/${id}`);
    dispatch(setTargetGoalStrategy({ target: goalStrategy }));
  } catch(error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchTargetGoalStrategy'));
  }
};

export const createGoalStrategy = (data) => async (dispatch) => {
  dispatch(doRequest('createGoalStrategy'));

  try {
    await api.post('/goal-strategies', data);
    notification.success({
      message: 'Create goal strategy',
      description: 'Created goal strategy successfully',
    });
    history.push('/goal-strategies');
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('createGoalStrategy'));
  }
};

export const updateGoalStrategy = (id, data) => async (dispatch) => {
  dispatch(doRequest('updateGoalStrategy'));

  try {
    await api.put(`/goal-strategies/${id}`, data);
    dispatch(setTargetGoalStrategy({ target: null }));

    notification.success({
      message: 'Update goal strategy',
      description: 'Updated goal strategy successfully',
    });
    history.push('/goal-strategies');
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('updateGoalStrategy'));
  }
};

export const deleteGoalStrategy = (id) => async (dispatch) => {
  dispatch(doRequest('deleteGoalStrategy'));

  try {
    await api.delete(`/goal-strategies/${id}`);
    dispatch(setTargetGoalStrategy({ target: null }));

    notification.success({
      message: 'Delete goal strategy',
      description: 'Deleted goal strategy successfully',
    });
    history.push('/goal-strategies');
  } catch (error) {
    notification.error({
      message: 'Delete goal strategy',
      description: 'Unable to delete goal strategy',
    });
    console.error(error);
  } finally {
    dispatch(finishRequest('deleteGoalStrategy'));
  }
};
