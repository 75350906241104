import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col, Typography, Spin } from 'antd';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import OrganizationForm from '@/forms/OrganizationForm';

import { fetchDepartments } from '@/actions/departmentActions';
import { fetchJobTypes } from '@/actions/jobTypeActions';
import { fetchOpportunityTypes } from '@/actions/opportunityTypeActions';
import { fetchOrganizations, createOrganization } from '@/actions/organizationActions';
import { getSelectableDepartments } from '@/selectors/departmentSelectors';
import { getSelectableJobTypes } from '@/selectors/jobTypeSelectors';
import { getSelectableOpportunityTypes } from '@/selectors/opportunityTypeSelectors';
import { getSelectableOrganizations } from '@/selectors/organizationSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import './index.scss';

const { Title } = Typography;

const getParentOrganizationType = (organizationType) => {
  const typeMappings = {
    Distributor: 'Manufacturer',
    Contractor: 'Distributor',
  };
  return typeMappings[organizationType];
};

class OrganizationCreateContainer extends Component {
  componentDidMount() {
    const {
      dispatchFetchDepartments,
      dispatchFetchJobTypes,
      dispatchFetchOpportunityTypes,
      dispatchFetchOrganizations,
    } = this.props;

    dispatchFetchDepartments();
    dispatchFetchJobTypes();
    dispatchFetchOpportunityTypes();
    dispatchFetchOrganizations();
  }

  handleSubmit = (values) => {
    const { dispatchCreateOrganization } = this.props;
    dispatchCreateOrganization({ ...values, type: 'Contractor' });
  };

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const {
      errors,
      loading,
      submitting,
      selectableDepartments,
      selectableJobTypes,
      selectableOpportunityTypes,
      selectableOrganizations,
    } = this.props;

    return (
      <LayoutContainer contentClassName={['content-container', 'organization-create-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Title level={2} className="content-container__title">
              Create New Organization
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'organizations', link: '/organizations', title: 'Organizations' },
                { key: 'create', title: 'Create New Organization' },
              ]}
              className="content-container__breadcrumb"
            />
            {loading ? (
              <Spin />
            ) : (
              <OrganizationForm
                submitting={submitting}
                submitTitle="Create Organization"
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formObject={{
                  id: null,
                  name: null,
                  parentId: null,
                  departmentIds: [],
                  jobTypeIds: [],
                  opportunityTypeIds: [],
                  organizationGroups: [],
                  revenueThreshold: {},
                  accountNumber: null,
                  disabled: 0,
                  type: 'Contractor',
                }}
                formErrors={errors}
                jobTypeSelectOptions={selectableJobTypes}
                opportunityTypeSelectOptions={selectableOpportunityTypes}
                organizationDepartmentSelectOptions={selectableDepartments}
                parentOrganizationSelectOptions={selectableOrganizations.filter(
                  ({ type }) => type === getParentOrganizationType('Contractor'),
                )}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

OrganizationCreateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectableDepartments: PropTypes.arrayOf(PropTypes.object),
  selectableJobTypes: PropTypes.arrayOf(PropTypes.object),
  selectableOrganizations: PropTypes.arrayOf(PropTypes.object),
  dispatchFetchDepartments: PropTypes.func,
  dispatchFetchJobTypes: PropTypes.func,
  dispatchFetchOpportunityTypes: PropTypes.func,
  dispatchFetchOrganizations: PropTypes.func,
  dispatchCreateOrganization: PropTypes.func,
};

const loadingInputData = createRequestLoadingSelector([
  'fetchDepartments',
  'fetchJobTypes',
  'fetchOpportunityTypes',
  'fetchOrganizations',
]);
const submittingOrganization = createRequestLoadingSelector([
  'createOrganization',
]);

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  loading: loadingInputData(state),
  submitting: submittingOrganization(state),
  selectableDepartments: getSelectableDepartments(state),
  selectableJobTypes: getSelectableJobTypes(state),
  selectableOpportunityTypes: getSelectableOpportunityTypes(state),
  selectableOrganizations: getSelectableOrganizations(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchDepartments: fetchDepartments,
    dispatchFetchJobTypes: fetchJobTypes,
    dispatchFetchOpportunityTypes: fetchOpportunityTypes,
    dispatchFetchOrganizations: fetchOrganizations,
    dispatchCreateOrganization: createOrganization,
  })(OrganizationCreateContainer),
);
