export const API_BASE_URL = process.env.REACT_APP_API_HOST;
export const SERVICE_DEPARTMENT_ID = process.env.SERVICE_DEPARTMENT_ID || '1';
export const SALE_DEPARTMENT_ID = process.env.SALE_DEPARTMENT_ID || '2';
export const PLUMBING_DEPARTMENT_ID = process.env.PLUMBING_DEPARTMENT_ID || '3';
export const ELECTRICAL_DEPARTMENT_ID = process.env.ELECTRICAL_DEPARTMENT_ID || '4';

export const Departments = {
  [SERVICE_DEPARTMENT_ID]: 'Service',
  [SALE_DEPARTMENT_ID]: 'Sales',
  [PLUMBING_DEPARTMENT_ID]: 'Plumbing',
  [ELECTRICAL_DEPARTMENT_ID]: 'Electrical',
};

export const Roles = {
  Admin: 'Admin',
  Trainer: 'Trainer',
  Owner: 'Owner',
  Manager: 'Manager',
  Employee: 'Employee',
  Sales: 'Sales',
  Distributor: 'Distributor',
};

export const MasterRoles = [
  Roles.Admin,
  Roles.Trainer,
];

export const ManagerRoles = [
  Roles.Owner,
  Roles.Manager,
  Roles.Distributor,
];

export const WorkerRoles = [
  Roles.Employee,
  Roles.Sales,
];

export const OrganizationType = {
  Manufacturer: 'Manufacturer',
  Distributor: 'Distributor',
  Contractor: 'Contractor',
};
