import api from '@/api';

export const fetchOwnedOrganizationRevenueWithCloseRate = async ({ organizationId, fromDate, toDate }) => {
  try {
    const { data } = await api.get('/opportunities/organization-revenue-with-close-rate', {
      params: {
        organizationId,
        fromDate,
        toDate,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
