import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { compact, sortBy } from 'lodash';

import { Row, Col, Typography, Spin, Button, notification } from 'antd';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import JobForm from '@/forms/JobForm';

import { fetchDepartments } from '@/actions/departmentActions';
import { fetchJobTargetOrganization, fetchTargetJob, updateJob, deleteJob } from '@/actions/jobActions';
import { getSelectableDepartments } from '@/selectors/departmentSelectors';
import { getTargetOrganization, getTargetJob } from '@/selectors/jobSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import { canDeleteJob } from '@/policies/jobPolicies';
import { MasterRoles, ManagerRoles, WorkerRoles } from '@/constants';
import './index.scss';

const { Title } = Typography;

class JobUpdateContainer extends Component {
  componentDidMount() {
    const {
      dispatchFetchDepartments,
      dispatchFetchJobTargetOrganization,
      dispatchFetchTargetJob,
      currentUser,
      match: { params },
      location: { search },
    } = this.props;
    const searchPrams = new URLSearchParams(search);

    dispatchFetchDepartments();
    dispatchFetchJobTargetOrganization(searchPrams.get('organizationId') || currentUser.organizationId);
    dispatchFetchTargetJob(params.id);
  }

  handleSubmit = (values) => {
    const { dispatchUpdateJob, targetJob, location } = this.props;

    dispatchUpdateJob(targetJob.id, {
      ...values,
      date: values.date ? values.date.format('YYYY-MM-DD') : null,
      opportunities: values.opportunities.map(({ id, opportunityTypeId, revenue }) => ({
        id,
        opportunityTypeId,
        revenue,
      })),
    }, {
      backUrl: location.state.backUrl,
    });
  };

  handleDelete = () => {
    const { dispatchDeleteJob, targetJob, location } = this.props;
    dispatchDeleteJob(targetJob.id, {
      backUrl: location.state.backUrl,
    });
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleChangeOrganization = (organizationId) => {
    const { dispatchFetchJobTargetOrganization } = this.props;
    dispatchFetchJobTargetOrganization(organizationId);
  }

  render() {
    const {
      errors,
      loading,
      submitting,
      currentUser,
      targetOrganization,
      targetJob,
      selectableJobTypes,
      selectableOpportunityTypes,
      selectableOrganizations,
      selectableDepartments,
      selectableUsers,
    } = this.props;

    if (
      targetJob &&
      targetJob.userId !== currentUser.id &&
      ![...MasterRoles, ...ManagerRoles].includes(currentUser?.type)
    ) {
      notification.error({
        message: 'Permission Denied',
        description: 'You are not authorized to perform this action',
      });
      return <Redirect to={{ pathname: '/jobs' }} />;
    }

    return (
      <LayoutContainer contentClassName={['content-container', 'job-create-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Row gutter={16} align='middle'>
              <Col flex="1">
                <Title level={2} className="content-container__title">
                  Edit Job
                </Title>
                <Breadcrumb
                  items={[
                    { key: 'home', link: '/', title: 'Home' },
                    { key: 'jobs', link: '/jobs', title: 'Jobs' },
                    { key: 'update', title: 'Edit Job' },
                  ]}
                  className="content-container__breadcrumb"
                />
              </Col>
              <Col flex="0">
                {canDeleteJob(currentUser) && (
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={this.handleDelete}
                    loading={submitting}
                  >
                    Delete
                  </Button>
                )}
              </Col>
            </Row>
            {!targetJob || loading ? (
              <Spin />
            ) : (
              <JobForm
                submitting={submitting}
                submitTitle='Update Job'
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formErrors={errors}
                formObject={{
                  id: targetJob.id,
                  date: targetJob.date ? moment(targetJob.date) : null,
                  name: targetJob.name,
                  jobTypeId: targetJob.jobTypeId,
                  replacementOpp: !!targetJob.replacementOpp,
                  techLeadTurnOver: !!targetJob.techLeadTurnOver,
                  opportunities: targetJob.opportunities || [],
                  userId: targetJob.user?.id,
                  departmentId: targetJob.departmentId,
                  organizationId: targetOrganization?.id,
                }}
                canSelectUser={[
                  ...MasterRoles,
                  ...ManagerRoles
                ].includes(currentUser?.type)}
                canSelectDepartment
                canSelectOrganization={false}
                userSelectOptions={selectableUsers}
                jobTypeSelectOptions={selectableJobTypes}
                opportunityTypeSelectOptions={selectableOpportunityTypes}
                organizationSelectOptions={selectableOrganizations}
                departmentSelectOptions={selectableDepartments}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

JobUpdateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  currentUser: PropTypes.object,
  targetJob: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectableJobTypes: PropTypes.arrayOf(PropTypes.object),
  selectableOpportunityTypes: PropTypes.arrayOf(PropTypes.object),
  selectableOrganizations: PropTypes.arrayOf(PropTypes.object),
  selectableUsers: PropTypes.arrayOf(PropTypes.object),
  dispatchFetchTargetJob: PropTypes.func,
  dispatchFetchJobTargetOrganization: PropTypes.func,
  dispatchFetchDepartments: PropTypes.func,
  dispatchUpdateJob: PropTypes.func,
  dispatchDeleteJob: PropTypes.func,
  targetOrganization: PropTypes.object,
};

const loadingInputData = createRequestLoadingSelector(['fetchJobTargetOrganization', 'fetchTargetJob']);
const submittingOrganization = createRequestLoadingSelector(['updateJob', 'deleteJob']);

const getOrganizationUsers = (organization, currentUser) => {
  if (!organization || !currentUser) {
    return [];
  }

  let { users } = organization;
  if (WorkerRoles.includes(currentUser.type)) {
    users = users.filter(({ id }) => id.toString() === currentUser.id?.toString());
  }
  users = users.map((user) => {
    let fullname = `${user.firstName} ${user.lastName}`;

    if (ManagerRoles.includes(currentUser.type) && currentUser?.id === user.id) {
      fullname = `${fullname} (You)`;
    }

    return {
      ...user,
      fullname,
    };
  });
  users = sortBy(users, (user) => user.fullname?.toLowerCase());
  return users;
};

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const targetOrganization = getTargetOrganization(state);

  return {
    errors: getErrors(state),
    loading: loadingInputData(state),
    submitting: submittingOrganization(state),
    targetJob: getTargetJob(state),
    selectableDepartments: getSelectableDepartments(state),
    selectableOrganizations: compact([targetOrganization]),
    selectableUsers: getOrganizationUsers(targetOrganization, currentUser),
    selectableJobTypes: sortBy(
      targetOrganization?.jobTypes || [],
      (jobType) => jobType.name?.toLowerCase(),
    ),
    selectableOpportunityTypes: sortBy(
      targetOrganization?.opportunityTypes || [],
      (opportunityType) => opportunityType.name?.toLowerCase(),
    ),
    targetOrganization,
    currentUser,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchTargetJob: fetchTargetJob,
    dispatchFetchJobTargetOrganization: fetchJobTargetOrganization,
    dispatchFetchDepartments: fetchDepartments,
    dispatchUpdateJob: updateJob,
    dispatchDeleteJob: deleteJob
  })(JobUpdateContainer),
);
