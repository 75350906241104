import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col, Typography, Spin } from 'antd';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import KpiForm from '@/forms/KpiForm';

import { fetchKpis, createOrUpdateKpis } from '@/actions/kpiActions';
import { getKpis } from '@/selectors/kpiSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import './index.scss';

const { Title } = Typography;

class KpisSetContainer extends Component {
  componentDidMount() {
    const { dispatchFetchKpis } = this.props;
    dispatchFetchKpis();
  }

  handleSubmit = (values) => {
    const { dispatchCreateOrUpdateKpis } = this.props;
    const kpis = Object.entries(values)
      .reduce((memo, [key, value]) => [...memo, { key, value }], []);
    dispatchCreateOrUpdateKpis({ kpis });
  }

  render() {
    const {
      kpis,
      errors,
      loading,
      submitting,
    } = this.props;

    return (
      <LayoutContainer contentClassName={['content-container', 'kpis-set-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Row gutter={16} align="middle">
              <Col flex="1">
                <Title level={2} className="content-container__title">
                  KPIs
                </Title>
                <Breadcrumb
                  items={[
                    { key: 'home', link: '/', title: 'Home' },
                    { key: 'kpis', link: '/kpis', title: 'KPIs' },
                  ]}
                  className="content-container__breadcrumb"
                />
              </Col>
            </Row>
            {loading ? (
              <Spin />
            ) : (
              <KpiForm
                loading={submitting}
                onSubmit={this.handleSubmit}
                formObject={kpis}
                formErrors={errors}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

KpisSetContainer.propTypes = {
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  kpis: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
  dispatchFetchKpis: PropTypes.func,
  dispatchCreateOrUpdateKpis: PropTypes.func,
};

const loadingData = createRequestLoadingSelector(['fetchKpis']);
const submittingKpis = createRequestLoadingSelector(['createOrUpdateKpis']);

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  loading: loadingData(state),
  submitting: submittingKpis(state),
  kpis: getKpis(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchKpis: fetchKpis,
    dispatchCreateOrUpdateKpis: createOrUpdateKpis,
  })(KpisSetContainer),
);
