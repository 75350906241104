import { round } from 'lodash';

export const displayCurrency = (amount, noCents = true) => {
  const value = noCents ? round(amount) : amount;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(value);
};

export const displayPercent = (amount) => {
  return `${round(amount * 100, 2)}%`;
};
