import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

function NotFoundContainer() {
  return (
    <div className="notfound__container">
      <div>
        404 not found!
      </div>
      <Link to="/">Back to home</Link>
    </div>
  );
}

export default NotFoundContainer;
