import api from '@/api';

export const fetchOrganizations = async () => {
  try {
    const { data } = await api.get('/organizations', {
      params: {
        sort: 'name',
        order: 'ASC',
        includes: 'jobTypes,opportunityTypes,organizationGroups',
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchOrganizationUsers = async (organizationId) => {
  try {
    const { data } = await api.get(`/organizations/${organizationId}/users`);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchOpportunityRevenues = async ({
  organizationId,
  organizationGroupId,
  departmentId,
  userId,
  jobTypeId,
  fromDate,
  toDate,
}) => {
  try {
    const { data } = await api.get('/opportunities/revenue-summary', {
      params: {
        organizationId,
        organizationGroupId,
        departmentId,
        userId,
        jobTypeId,
        fromDate,
        toDate,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchOpportunityRevenuesByUnit = async ({
  organizationId,
  organizationGroupId,
  departmentId,
  jobTypeId,
  fromDate,
  toDate,
}) => {
  try {
    const { data } = await api.get('/opportunities/revenue-by-unit', {
      params: {
        organizationId,
        organizationGroupId,
        departmentId,
        jobTypeId,
        fromDate,
        toDate,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchJobStats = async ({
  organizationId,
  organizationGroupId,
  departmentId,
  userId,
  jobTypeId,
  fromDate,
  toDate,
}) => {
  try {
    const { data } = await api.get('/jobs/count-summary', {
      params: {
        organizationId,
        organizationGroupId,
        departmentId,
        userId,
        jobTypeId,
        fromDate,
        toDate,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchGoalAmount = async ({
  organizationId,
  organizationGroupId,
  departmentId,
  userId,
  fromDate,
  toDate,
}) => {
  try {
    const { data } = await api.get('/goals/calculate', {
      params: {
        organizationId,
        organizationGroupId,
        departmentId,
        userId,
        fromDate,
        toDate,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
