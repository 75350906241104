import qs from 'qs';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Form, Button, Typography, Space, Table, Select, Input, Tag } from 'antd';
import { pickBy, identity } from 'lodash';

import Breadcrumb from '@/components/Breadcumb';
import LayoutContainer from '@/containers/LayoutContainer';

import { fetchUsers } from '@/actions/userActions';
import { fetchOrganizations } from '@/actions/organizationActions';
import { getUserList } from '@/selectors/userSelectors';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getSelectableOrganizations } from '@/selectors/organizationSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { canCreateUser } from '@/policies/userPolicies';
import { selectSearchByChildrenText } from '@/utils/selectUtils';
import { displayDateTime } from '@/utils/dateUtils';
import './index.scss';

const { Title } = Typography;
const { Option } = Select;

const getBackUrl = () => `${window.location.pathname}${window.location.search}`;

class UserListContainer extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { dispatchFetchUsers, dispatchFetchOrganizations, limit, sort, order } = this.props;
    const { organizationId, email } = this.getInitialFilterParams();

    dispatchFetchOrganizations();
    this.formRef.current.setFieldsValue({
      organizationId,
      email,
    });
    dispatchFetchUsers({
      organizationId,
      email,
      page: 1,
      limit,
      sort,
      order,
    });
  }

  getInitialFilterParams = () => {
    const { currentUser, location: { search } } = this.props;
    const searchPrams = new URLSearchParams(search);
    const organizationId = parseInt(searchPrams.get('organizationId'), 10) || currentUser.organizationId || null;
    const email = searchPrams.get('email') || null;

    return {
      organizationId,
      email,
    };
  }

  handleSearch = ({ organizationId, email }) => {
    const { dispatchFetchUsers, limit, sort, order } = this.props;
    this.handleUpdateURLSearchParams({ organizationId, email });
    dispatchFetchUsers({ organizationId, email, page: 1, limit, sort, order });
  };

  handleResetForm = () => {
    const { dispatchFetchUsers, limit, sort, order } = this.props;

    this.formRef.current.resetFields();
    this.handleUpdateURLSearchParams({});
    dispatchFetchUsers({ page: 1, limit, sort, order });
  };

  handleChangePagination = (page, limit) => {
    const { dispatchFetchUsers, filteredOrganizationId, filteredEmail, sort, order } = this.props;
    dispatchFetchUsers({ organizationId: filteredOrganizationId, email: filteredEmail, page, limit, sort, order });
  };

  handleUpdateURLSearchParams = (object) => {
    const { history } = this.props;
    const queryObject = pickBy(object, identity);
    history.replace({ search: qs.stringify(queryObject) });
  }

  handleTableChange = (_pagination, _filters, sorter, extra) => {
    if (extra.action === 'sort') {
      const {
        dispatchFetchUsers,
        filteredOrganizationId,
        filteredEmail,
        page,
        limit,
      } = this.props;
      const nextSort = sorter.field;
      const nextOrder = sorter.order;

      dispatchFetchUsers({
        organizationId: filteredOrganizationId,
        email: filteredEmail,
        sort: nextSort,
        order: nextOrder,
        page,
        limit,
      });
    }
  }

  getTableColumns = () => {
    const { sort, order } = this.props;

    return [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        sortOrder: sort === 'id' ? order : null,
        render: (id) => {
          return (
            <Link
              to={{
                pathname: `/users/${id}/edit`,
                state: {
                  backUrl: getBackUrl(),
                },
              }}
            >
              #{id}
            </Link>
          );
        },
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        sorter: true,
        sortOrder: sort === 'firstName' ? order : null,
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        sorter: true,
        sortOrder: sort === 'lastName' ? order : null,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        sortOrder: sort === 'email' ? order : null,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render: (type) => {
          return <Tag color="cyan">{type}</Tag>;
        },
      },
      {
        title: 'Status',
        dataIndex: 'disabled',
        key: 'disabled',
        align: 'center',
        render: (disabled) => {
          return <Tag color={disabled ? 'red' : 'blue'}>{disabled ? 'Disabled' : 'Active'}</Tag>;
        },
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        sortOrder: sort === 'createdAt' ? order : null,
        render: (createdAt) => {
          return createdAt ? displayDateTime(createdAt) : null;
        },
      },
    ];
  }

  render() {
    const {
      page,
      limit,
      items,
      loading,
      totalCount,
      selectableOrganizations,
      currentUser,
    } = this.props;
    const tableColumns = this.getTableColumns();

    return (
      <LayoutContainer contentClassName={['content-container', 'user-list-container']}>
        <Row gutter={16} align='middle'>
          <Col flex="1">
            <Title level={2} className="content-container__title">
              Users
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'users', link: '/users', title: 'Users' },
                { key: 'list', title: 'List' },
              ]}
              className="content-container__breadcrumb"
            />
          </Col>
          <Col flex="0">
            {canCreateUser(currentUser) && (
              <Link
                to={{
                  pathname: '/users/new',
                  state: {
                    backUrl: getBackUrl(),
                  },
                }}
              >
                <Button type="primary" icon={<PlusOutlined />}>
                  Create New User
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <Form
          ref={this.formRef}
          name="dashboard-search"
          layout="vertical"
          className="content-container__search-form"
          initialValues={{
            organizationId: null,
            email: null,
          }}
          onFinish={this.handleSearch}
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item name="organizationId" label="Organization">
                <Select
                  showSearch
                  placeholder="Select Organization"
                  optionFilterProp="children"
                  filterOption={selectSearchByChildrenText}
                  loading={loading}
                  disabled={!!currentUser.organizationId}
                >
                  {selectableOrganizations.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="email" label="Email" rules={[{ type: 'email', message: 'Email is not valid' }]}>
                <Input placeholder="Type Email" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Space
                style={{
                  marginTop: 33.14,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
                <Button htmlType="button" onClick={this.handleResetForm}>
                  Reset
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        <div className="content-container__section">
          <Table
            rowKey="id"
            dataSource={items}
            columns={tableColumns}
            loading={loading}
            scroll={{
              x: 'max-content',
            }}
            pagination={{
              onChange: this.handleChangePagination,
              pageSize: limit,
              current: page,
              total: totalCount,
            }}
            onChange={this.handleTableChange}
          />
        </div>
      </LayoutContainer>
    );
  }
}

UserListContainer.propTypes = {
  page: PropTypes.number,
  limit: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object),
  sort: PropTypes.string,
  order: PropTypes.string,
  totalCount: PropTypes.number,
  filteredEmail: PropTypes.string,
  filteredOrganizationId: PropTypes.number,
  selectableOrganizations: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.object,
  loading: PropTypes.bool,
};

const getLoading = createRequestLoadingSelector([
  'fetchUsers',
  'fetchOrganizations',
]);

const mapStateToProps = (state) => {
  const userList = getUserList(state);

  return {
    ...userList,
    loading: getLoading(state),
    currentUser: getCurrentUser(state),
    selectableOrganizations: getSelectableOrganizations(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchUsers: fetchUsers,
    dispatchFetchOrganizations: fetchOrganizations,
  })(UserListContainer),
);
