import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col, Typography, Spin } from 'antd';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import OpportunityTypeForm from '@/forms/OpportunityTypeForm';

import { createOpportunityType } from '@/actions/opportunityTypeActions';
import { fetchDepartments } from '@/actions/departmentActions';
import { getDepartments } from '@/selectors/departmentSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import './index.scss';

const { Title } = Typography;

class OpportunityTypeCreateContainer extends Component {
  componentDidMount() {
    const { dispatchFetchDepartments } = this.props;
    dispatchFetchDepartments();
  }

  handleSubmit = (values) => {
    const { dispatchCreateOpportunityType } = this.props;
    dispatchCreateOpportunityType(values);
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      errors,
      loading,
      submitting,
      selectableDepartments,
    } = this.props;

    return (
      <LayoutContainer contentClassName={['content-container', 'opportunity-type-create-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Title level={2} className="content-container__title">
              Create New Opportunity Type
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'opportunity-types', link: '/opportunity-types', title: 'Opportunity Types' },
                { key: 'create', title: 'Create New Opportunity Type' },
              ]}
              className="content-container__breadcrumb"
            />
            {loading ? (
              <Spin />
            ) : (
              <OpportunityTypeForm
                submitting={submitting}
                submitTitle='Create Opportunity Type'
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formObject={{
                  id: null,
                  departmentIds: [],
                  name: '',
                }}
                formErrors={errors}
                departmentSelectOptions={selectableDepartments}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

OpportunityTypeCreateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectableDepartments: PropTypes.arrayOf(PropTypes.object),
  dispatchFetchDepartments: PropTypes.func,
  dispatchCreateOpportunityType: PropTypes.func,
};

const loadingInputData = createRequestLoadingSelector(['fetchDepartments']);
const submittingOpportunityType = createRequestLoadingSelector(['createOpportunityType']);

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  loading: loadingInputData(state),
  submitting: submittingOpportunityType(state),
  selectableDepartments: getDepartments(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchDepartments: fetchDepartments,
    dispatchCreateOpportunityType: createOpportunityType,
  })(OpportunityTypeCreateContainer),
);
