import { Roles } from '@/constants';

export const canManageGoalStrategy = (user) => {
  return [Roles.Admin].includes(user?.type);
};

export const canViewGoalStrategy = (user) => {
  return canManageGoalStrategy(user);
};

export const canCreateGoalStrategy = (user) => {
  return canManageGoalStrategy(user);
};

export const canUpdateGoalStrategy = (user) => {
  return canManageGoalStrategy(user);
};

export const canDeleteGoalStrategy = (user) => {
  return canManageGoalStrategy(user);
};
