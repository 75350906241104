export const getGoals = (state) => {
  return state.goal.items;
};

export const getTargetGoal = (state) => {
  return state.goal.target;
};

export const getTargetOrganization = (state) => {
  return state.goal.targetOrganization;
};
