import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'department',
  initialState: {
    items: [],
  },
  reducers: {
    setDepartments(state, { payload: { items } }) {
      state.items = items;
    },
  },
});

export const {
  setDepartments,
} = slice.actions;

export default slice.reducer;
