import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'opportunityType',
  initialState: {
    items: [],
    target: null,
  },
  reducers: {
    setOpportunityTypes(state, { payload: { items } }) {
      state.items = items;
    },
    setTargetOpportunityType(state, { payload: { target } }) {
      state.target = target;
    },
  },
});

export const {
  setOpportunityTypes,
  setTargetOpportunityType,
} = slice.actions;

export default slice.reducer;
