import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'jobType',
  initialState: {
    items: [],
    target: null,
  },
  reducers: {
    setJobTypes(state, { payload: { items } }) {
      state.items = items;
    },
    setTargetJobType(state, { payload: { target } }) {
      state.target = target;
    },
  },
});

export const {
  setJobTypes,
  setTargetJobType,
} = slice.actions;

export default slice.reducer;
