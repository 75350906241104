import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import { Row, Col, Typography, Button, Space, Form, Input, InputNumber, Select, DatePicker, Checkbox } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';

import ErrorMessage from '@/components/ErrorMessage';
import { selectSearchByChildrenText } from '@/utils/selectUtils';
import { displayCurrency } from '@/utils/numberUtils';
import { MasterRoles } from '@/constants';

const { Option } = Select;
const { Title, Paragraph } = Typography;

const filterUser = (userId, users) => {
  return users.find((user) => `${user.id}` === `${userId}`);
};

const filterJobTypesByDepartmentId = (departmentId, jobTypes) => {
  if (!departmentId || !jobTypes) {
    return [];
  }

  return jobTypes.filter((jt) => jt.departmentIds?.includes(departmentId?.toString()));
};

const filterOpportunitiesByDepartmentId = (departmentId, opportunityTypes) => {
  if (!departmentId || !opportunityTypes) {
    return [];
  }

  return opportunityTypes.filter((ot) => ot.departmentIds?.includes(departmentId?.toString()));
};

const filterDepartmentsByUser = (user, departments) => {
  if (!user || !departments) {
    return [];
  }

  if (MasterRoles.includes(user.type) && !user.departmentIds) {
    return departments;
  }

  return departments.filter((dp) => user.departmentIds?.includes(dp.id.toString()));
};

const JobForm = (props) => {
  const {
    submitting,
    submitTitle,
    formObject,
    formErrors,
    userSelectOptions,
    jobTypeSelectOptions,
    opportunityTypeSelectOptions,
    organizationSelectOptions,
    departmentSelectOptions,
    canSelectOrganization,
    canSelectDepartment,
    canSelectUser,
    onCancel,
    onSubmit,
    onChangeOrganization,
  } = props;

  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(formObject.userId);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(formObject.departmentId);

  const selectedUser = filterUser(
    selectedUserId,
    userSelectOptions,
  );
  const usedJobTypeSelectOptions = filterJobTypesByDepartmentId(
    selectedDepartmentId,
    jobTypeSelectOptions,
  );
  const usedOpportunityTypeSelectOptions = filterOpportunitiesByDepartmentId(
    selectedDepartmentId,
    opportunityTypeSelectOptions,
  );
  const usedDepartmentSelectOptions = filterDepartmentsByUser(
    selectedUser,
    departmentSelectOptions,
  );

  useEffect(() => {
    if (!updated) {
      form.resetFields();
    }
  }, [form, formObject, updated]);

  useEffect(() => {
    const ttrvn = sumBy(formObject.opportunities, 'revenue');
    setTotalRevenue(ttrvn);
  }, [formObject.opportunities]);

  useEffect(() => {
    if (formObject.departmentId) {
      return;
    }
    if (selectedUser && selectedUser.departmentIds && selectedUser.departmentIds.length === 1) {
      const departmentId = parseInt(selectedUser.departmentIds[0], 10);
      form.setFieldsValue({
        departmentId,
      });
      setSelectedDepartmentId(departmentId);
    } else {
      form.setFieldsValue({
        departmentId: null,
      });
      setSelectedDepartmentId(null);
    }
  }, [form, formObject, selectedUser]);

  const onValuesChange = (changedValues, values) => {
    const ttrvn = sumBy(values.opportunities, 'revenue') || 0;
    setTotalRevenue(ttrvn);
    setUpdated(true);

    if (changedValues.userId) {
      setSelectedUserId(changedValues.userId);
    }

    if (changedValues.departmentId) {
      setSelectedDepartmentId(changedValues.departmentId);
    }

    if (changedValues.organizationId) {
      onChangeOrganization && onChangeOrganization(changedValues.organizationId);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      initialValues={formObject}
      onValuesChange={onValuesChange}
      colon={false}
      layout="vertical"
      className="content-container__form"
      hideRequiredMark
    >
      <ErrorMessage
        errors={formErrors}
      />
      <Paragraph>
        Please input the information below:
      </Paragraph>
      <Form.Item
        name="organizationId"
        label="Contractor"
        rules={[{
          required: true,
          message: 'Contractor is required'
        }]}
        hasFeedback
      >
        <Select
          showSearch
          disabled={!canSelectOrganization}
          placeholder="Select contractor"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
        >
          {organizationSelectOptions.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="userId"
        label="Employee"
        rules={[{
          required: true,
          message: 'Employee is required'
        }]}
        hasFeedback
      >
        <Select
          showSearch
          disabled={!canSelectUser}
          placeholder="Select employee"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
        >
          {userSelectOptions.map(({ id, fullname }) => (
            <Option key={id} value={id}>
              {fullname}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="departmentId"
        label="Department"
        rules={[{
          required: true,
          message: 'Department is required'
        }]}
        hasFeedback
      >
        <Select
          showSearch
          disabled={!canSelectDepartment}
          placeholder="Select department"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
        >
          {usedDepartmentSelectOptions.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="name"
        label="Customer Name"
        rules={[{
          required: true,
          message: 'Customer name is required'
        }]}
        hasFeedback
      >
        <Input placeholder="Customer Name" />
      </Form.Item>
      <Form.Item name="date" label="Date">
        <DatePicker placeholder="Select Date" style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        name="jobTypeId"
        label="Job Type"
        rules={[{
          required: true,
          message: 'Job type is required'
        }]}
        hasFeedback
      >
        <Select
          showSearch
          placeholder="Select job type"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
        >
          {usedJobTypeSelectOptions.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="replacementOpp"
        valuePropName="checked"
        style={{ marginBottom: 0 }}
      >
        <Checkbox>Replacement Opportunity?</Checkbox>
      </Form.Item>
      <Form.Item
        name="techLeadTurnOver"
        valuePropName="checked"
      >
        <Checkbox>Tech Lead Turnover</Checkbox>
      </Form.Item>
      <Title level={4}>Sold Items</Title>
      <Form.List
        name="opportunities"
      >
        {(fields, { add, remove }, { errors }) => (
          <React.Fragment>
            {fields.map((field) => (
              <React.Fragment key={field.key}>
                <Row gutter={16} key={field.key} align="start">
                  <Form.Item name={[field.name, 'id']}>
                    <Input type="hidden" />
                  </Form.Item>
                  <Col span={10}>
                    <Form.Item
                      label="Opportunity Type"
                      name={[field.name, 'opportunityTypeId']}
                      rules={[{ required: true, message: 'Opportunity type is required' }]}
                      hasFeedback
                    >
                      <Select
                        showSearch
                        placeholder="Select opportunity type"
                        optionFilterProp="children"
                        filterOption={selectSearchByChildrenText}
                      >
                        {usedOpportunityTypeSelectOptions.map(({ id, name }) => (
                          <Option key={id} value={id}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      label="Revenue ($)"
                      name={[field.name, 'revenue']}
                      rules={[{ required: true, message: 'Revenue is required' }]}
                    >
                      <InputNumber placeholder="$" min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <MinusCircleOutlined
                      style={{
                        color: '#ff4d4f',
                        marginTop: 42.5,
                      }}
                      onClick={() => remove(field.name)}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ))}
            <Title level={5}>
              Total Revenue: {displayCurrency(totalRevenue)}
            </Title>
            <Form.Item style={{marginTop: 24}}>
              <Button
                type="dashed"
                onClick={() => add({ revenue: 0 })}
                icon={<PlusOutlined />}
              >
                Add Sold Item
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </React.Fragment>
        )}
      </Form.List>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {submitTitle || 'Submit'}
          </Button>
          {onCancel && (
            <Button htmlType="button" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

JobForm.propTypes = {
  submitting: PropTypes.bool,
  submitTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onChangeOrganization: PropTypes.func,
  formObject: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.object,
    name: PropTypes.string,
    userId: PropTypes.number,
    departmentId: PropTypes.number,
    jobTypeId: PropTypes.number,
    replacementOpp: PropTypes.bool,
    opportunities: PropTypes.arrayOf(PropTypes.object),
  }),
  formErrors: PropTypes.arrayOf(PropTypes.string),
  userSelectOptions: PropTypes.arrayOf(PropTypes.object),
  jobTypeSelectOptions: PropTypes.arrayOf(PropTypes.object),
  opportunityTypeSelectOptions: PropTypes.arrayOf(PropTypes.object),
  organizationSelectOptions: PropTypes.arrayOf(PropTypes.object),
  departmentSelectOptions: PropTypes.arrayOf(PropTypes.object),
  canSelectOrganization: PropTypes.bool,
  canSelectDepartment: PropTypes.bool,
  canSelectUser: PropTypes.bool,
};

export default JobForm;
