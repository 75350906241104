import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col, Button, Typography, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import JobTypeForm from '@/forms/JobTypeForm';

import { fetchDepartments } from '@/actions/departmentActions';
import { fetchTargetJobType, updateJobType, deleteJobType } from '@/actions/jobTypeActions';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getDepartments } from '@/selectors/departmentSelectors';
import { getTargetJobType } from '@/selectors/jobTypeSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import { canDeleteJobType } from '@/policies/jobTypePolicies';
import './index.scss';

const { Title } = Typography;

class JobTypeUpdateContainer extends Component {
  componentDidMount() {
    const {
      dispatchFetchDepartments,
      dispatchFetchTargetJobType,
      match: { params },
    } = this.props;
    dispatchFetchDepartments();
    dispatchFetchTargetJobType(params.id);
  }

  handleSubmit = (values) => {
    const { dispatchUpdateJobType, targetJobType } = this.props;
    dispatchUpdateJobType(targetJobType.id, values);
  }

  handleDelete = () => {
    const { dispatchDeleteJobType, targetJobType } = this.props;
    dispatchDeleteJobType(targetJobType.id);
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      currentUser,
      selectableDepartments,
      targetJobType,
      errors,
      loading,
      submitting,
    } = this.props;

    return (
      <LayoutContainer contentClassName={['content-container', 'jobtype-update-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Row gutter={16} align='middle'>
              <Col flex="1">
                <Title level={2} className="content-container__title">
                  Edit Job Type
                </Title>
                <Breadcrumb
                  items={[
                    { key: 'home', link: '/', title: 'Home' },
                    { key: 'job-types', link: '/job-types', title: 'Job Types' },
                    { key: 'update', title: 'Edit Job Type' },
                  ]}
                  className="content-container__breadcrumb"
                />
              </Col>
              <Col flex="0">
                {canDeleteJobType(currentUser) && (
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={this.handleDelete}
                    loading={submitting}
                  >
                    Delete
                  </Button>
                )}
              </Col>
            </Row>
            {!targetJobType || loading ? (
              <Spin />
            ) : (
              <JobTypeForm
                submitting={submitting}
                submitTitle='Update Job Type'
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formObject={{
                  id: targetJobType.id,
                  name: targetJobType.name,
                  departmentIds: targetJobType.departmentIds,
                }}
                formErrors={errors}
                departmentSelectOptions={selectableDepartments}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

JobTypeUpdateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  targetJobType: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectableDepartments: PropTypes.arrayOf(PropTypes.object),
  dispatchFetchDepartments: PropTypes.func,
  dispatchFetchTargetJobType: PropTypes.func,
  dispatchUpdateJobType: PropTypes.func,
  dispatchDeleteJobType: PropTypes.func,
};

const loadingData = createRequestLoadingSelector(['fetchDepartments', 'fetchTargetJobType']);
const submittingJobType = createRequestLoadingSelector(['updateJobType', 'deleteJobType']);

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  loading: loadingData(state),
  submitting: submittingJobType(state),
  targetJobType: getTargetJobType(state),
  currentUser: getCurrentUser(state),
  selectableDepartments: getDepartments(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchDepartments: fetchDepartments,
    dispatchFetchTargetJobType: fetchTargetJobType,
    dispatchUpdateJobType: updateJobType,
    dispatchDeleteJobType: deleteJobType,
  })(JobTypeUpdateContainer),
);
