import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'kpi',
  initialState: {
    items: {},
  },
  reducers: {
    setKpis(state, { payload: { items } }) {
      state.items = {
        ...state.items,
        ...items,
      };
    },
  },
});

export const { setKpis } = slice.actions;

export default slice.reducer;
