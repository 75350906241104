import { notification } from 'antd';

import api from '@/api';
import history from '@/history';
import { doRequest, finishRequest } from '@/store/request';
import { setJobTypes, setTargetJobType } from '@/store/jobType';
import { createErrors } from '@/actions/errorActions';
import { transformErrors } from '@/utils/errorUtils';

export const fetchJobTypes = (sort = null, order = null) => async (dispatch) => {
  dispatch(doRequest('fetchJobTypes'));

  try {
    const { data: items } = await api.get('/job-types', {
      params: {
        sort,
        order,
      },
    });
    dispatch(setJobTypes({ items }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchJobTypes'));
  }
};

export const fetchTargetJobType = (id) => async (dispatch) => {
  dispatch(doRequest('fetchTargetJobType'));

  try {
    const { data: jobType } = await api.get(`/job-types/${id}`);
    dispatch(setTargetJobType({ target: jobType }));
  } catch(error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchTargetJobType'));
  }
};

export const createJobType = (data) => async (dispatch) => {
  dispatch(doRequest('createJobType'));

  try {
    await api.post('/job-types', data);
    notification.success({
      message: 'Create job type',
      description: 'Created job type successfully',
    });
    history.push('/job-types');
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('createJobType'));
  }
};

export const updateJobType = (id, data) => async (dispatch) => {
  dispatch(doRequest('updateJobType'));

  try {
    await api.put(`/job-types/${id}`, data);
    dispatch(setTargetJobType({ target: null }));

    notification.success({
      message: 'Update job type',
      description: 'Updated job type successfully',
    });
    history.push('/job-types');
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('updateJobType'));
  }
};

export const deleteJobType = (id) => async (dispatch) => {
  dispatch(doRequest('deleteJobType'));

  try {
    await api.delete(`/job-types/${id}`);
    dispatch(setTargetJobType({ target: null }));

    notification.success({
      message: 'Delete job type',
      description: 'Deleted job type successfully',
    });
    history.push('/job-types');
  } catch (error) {
    notification.error({
      message: 'Delete job type',
      description: 'Unable to delete job type',
    });
    console.error(error);
  } finally {
    dispatch(finishRequest('deleteJobType'));
  }
};
