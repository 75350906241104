import { Roles } from '@/constants';

export const canViewJob = (user) => {
  return [
    Roles.Admin,
    Roles.Trainer,
    Roles.Owner,
    Roles.Manager,
    Roles.Employee,
    Roles.Sales,
  ].includes(user?.type);
};

export const canCreateJob = (user) => {
  return [
    Roles.Admin,
    Roles.Trainer,
    Roles.Owner,
    Roles.Manager,
    Roles.Employee,
    Roles.Sales,
  ].includes(user?.type);
};

export const canUpdateJob = (user) => {
  return [
    Roles.Admin,
    Roles.Trainer,
    Roles.Manager,
    Roles.Owner,
    Roles.Employee,
    Roles.Sales,
  ].includes(user?.type);
};

export const canDeleteJob = (user) => {
  return [
    Roles.Admin,
    Roles.Trainer,
    Roles.Owner,
    Roles.Manager,
    Roles.Employee,
    Roles.Sales,
  ].includes(user?.type);
};
