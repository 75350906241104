import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import qs from 'qs';
import { connect } from 'react-redux';
import { sum, pickBy, identity } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Form, Button, Typography, Table, Select, DatePicker, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Breadcrumb from '@/components/Breadcumb';
import LayoutContainer from '@/containers/LayoutContainer';
import { fetchOrganizations } from '@/actions/organizationActions';
import { fetchJobs, fetchSelectableOrganizationUsers, resetJobListAndFilter } from '@/actions/jobActions';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getSelectableContractorOrganizations } from '@/selectors/organizationSelectors';
import { getJobList, getFilteredOrganizationUsers } from '@/selectors/jobSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { canCreateJob } from '@/policies/jobPolicies';
import { selectSearchByChildrenText } from '@/utils/selectUtils';
import { displayCurrency } from '@/utils/numberUtils';
import { MasterRoles, ManagerRoles, Departments } from '@/constants';
import './index.scss';

const { Title } = Typography;
const { Option } = Select;

const getBackUrl = () => `${window.location.pathname}${window.location.search}`;

class JobListContainer extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const {
      dispatchFetchJobs,
      dispatchFetchOrganizations,
      dispatchFetchSelectableOrganizationUsers,
      limit,
      sort,
      order,
    } = this.props;
    const filterParams = this.getInitialFilterParams();

    dispatchFetchOrganizations();

    if (filterParams.organizationId) {
      dispatchFetchSelectableOrganizationUsers(filterParams.organizationId);
    }

    if (filterParams.organizationId && filterParams.userId) {
      dispatchFetchJobs({
        ...filterParams,
        page: 1,
        limit,
        sort,
        order,
      });
    }

    this.formRef.current.setFieldsValue({
      ...filterParams,
      fromDate: filterParams.fromDate ? moment(filterParams.fromDate) : null,
      toDate: filterParams.toDate ? moment(filterParams.toDate) : null,
    });
  }

  componentWillUnmount() {
    const { dispatchResetJobListAndFilter } = this.props;
    dispatchResetJobListAndFilter();
  }

  getInitialFilterParams = () => {
    const {
      currentUser,
      location: { search },
    } = this.props;
    const searchPrams = new URLSearchParams(search);

    const organizationId = parseInt(searchPrams.get('organizationId'), 10) || currentUser?.organizationId || null;
    const userId = parseInt(searchPrams.get('userId'), 10) || (organizationId && currentUser?.id) || null;
    const fromDate = searchPrams.get('fromDate');
    const toDate = searchPrams.get('toDate');

    return {
      userId,
      organizationId,
      fromDate,
      toDate,
    };
  };

  handleUpdateURLSearchParams = (object) => {
    const { history } = this.props;
    const queryObject = pickBy(object, identity);
    history.replace({ search: qs.stringify(queryObject) });
  };

  handleSearch = ({ organizationId, userId, fromDate, toDate }) => {
    const { dispatchFetchJobs, limit, sort, order } = this.props;
    const filterParams = {
      userId,
      organizationId,
      fromDate: fromDate ? fromDate.format('YYYY-MM-DD') : null,
      toDate: toDate ? toDate.format('YYYY-MM-DD') : null,
    };
    this.handleUpdateURLSearchParams(filterParams);
    dispatchFetchJobs({ ...filterParams, page: 1, limit, sort, order });
  };

  handleResetForm = () => {
    const {
      limit,
      sort,
      order,
      currentUser,
      filteredUserId,
      filteredOrganizationId,
      dispatchFetchSelectableOrganizationUsers,
      dispatchFetchJobs,
    } = this.props;
    const organizationId = currentUser?.organizationId || filteredOrganizationId || null;
    const userId = (currentUser?.organizationId && currentUser?.id) || filteredUserId || null;

    if (organizationId) {
      dispatchFetchSelectableOrganizationUsers(organizationId);
    }

    if (organizationId && userId) {
      dispatchFetchJobs({
        userId,
        organizationId,
        page: 1,
        limit,
        sort,
        order,
      });
    }

    this.handleUpdateURLSearchParams({
      userId,
      organizationId,
    });
    this.formRef.current.setFieldsValue({
      userId,
      organizationId,
      fromDate: null,
      toDate: null,
    });
  };

  handleChangeOrganization = (organizationId) => {
    const { dispatchFetchSelectableOrganizationUsers } = this.props;

    this.formRef.current.setFieldsValue({
      userId: null,
    });
    dispatchFetchSelectableOrganizationUsers(organizationId);
  };

  handleChangePagination = (page, limit) => {
    const {
      dispatchFetchJobs,
      filteredOrganizationId,
      filteredUserId,
      filteredFromDate,
      filteredToDate,
      sort,
      order,
    } = this.props;
    dispatchFetchJobs({
      organizationId: filteredOrganizationId,
      userId: filteredUserId,
      fromDate: filteredFromDate,
      toDate: filteredToDate,
      page,
      limit,
      sort,
      order,
    });
  };

  handleTableChange = (_pagination, _filters, sorter, extra) => {
    if (extra.action === 'sort') {
      const {
        dispatchFetchJobs,
        filteredOrganizationId,
        filteredUserId,
        filteredFromDate,
        filteredToDate,
        page,
        limit,
      } = this.props;
      const nextSort = sorter.field;
      const nextOrder = sorter.order;

      dispatchFetchJobs({
        organizationId: filteredOrganizationId,
        userId: filteredUserId,
        fromDate: filteredFromDate,
        toDate: filteredToDate,
        sort: nextSort,
        order: nextOrder,
        page,
        limit,
      });
    }
  }

  getTableComlumns = () => {
    const {
      currentUser,
      filteredOrganizationId,
      sort,
      order,
    } = this.props;

    return [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        sortOrder: sort === 'id' ? order : null,
        render: (id, record) => {
          const canEdit = record.userId === currentUser.id ||
            [...MasterRoles, ...ManagerRoles].includes(currentUser?.type);
          if (canEdit) {
            return (
              <Link
                to={{
                  pathname: `/jobs/${id}/edit`,
                  search: currentUser.organizationId ? null : `?organizationId=${filteredOrganizationId}`,
                  state: {
                    backUrl: getBackUrl(),
                  },
                }}
              >
                #{id}
              </Link>
            );
          }
          return `#${id}`;
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortOrder: sort === 'name' ? order : null,
      },
      {
        title: 'Department',
        dataIndex: 'departmentName',
        key: 'departmentName',
        sorter: true,
        sortOrder: sort === 'departmentName' ? order : null,
        render: (_, record) => {
          return Departments[record.departmentId];
        },
      },
      {
        title: 'Type',
        dataIndex: 'jobTypeName',
        key: 'jobTypeName',
        render: (_, record) => {
          return record.jobType?.name;
        },
      },
      {
        title: 'Revenue',
        dataIndex: 'opportunityRevenue',
        key: 'opportunityRevenue',
        sorter: true,
        sortOrder: sort === 'opportunityRevenue' ? order : null,
        render: (_, record) => {
          const revenue = sum(record.opportunities.map(({ revenue }) => revenue));
          return displayCurrency(revenue);
        },
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: true,
        sortOrder: sort === 'date' ? order : null,
        align: 'center',
      },
    ];
  }

  render() {
    const {
      page,
      limit,
      items,
      loadingJobs,
      loadingFormData,
      totalCount,
      selectableOrganizations,
      selectableOrganizationUsers,
      currentUser,
    } = this.props;
    const tableColumns = this.getTableComlumns();

    return (
      <LayoutContainer contentClassName={['content-container', 'job-list-container']}>
        <Row gutter={16} align="middle">
          <Col flex="1">
            <Title level={2} className="content-container__title">
              Jobs
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'jobs', link: '/jobs', title: 'Jobs' },
                { key: 'list', title: 'List' },
              ]}
              className="content-container__breadcrumb"
            />
          </Col>
          <Col flex="0">
            {canCreateJob(currentUser) && (
              <Link
                to={{
                  pathname: '/jobs/new',
                  state: {
                    backUrl: getBackUrl(),
                  },
                }}
              >
                <Button type="primary" icon={<PlusOutlined />}>
                  Create New Job
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <Form
          ref={this.formRef}
          name="dashboard-search"
          layout="vertical"
          className="content-container__search-form"
          hideRequiredMark
          initialValues={{
            organizationId: null,
            userId: null,
            fromDate: null,
            toDate: null,
          }}
          onFinish={this.handleSearch}
        >
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                name="organizationId"
                label="Organization"
                rules={[{ required: true, message: 'Organization is required' }]}
              >
                <Select
                  placeholder="Select Organization"
                  optionFilterProp="children"
                  filterOption={selectSearchByChildrenText}
                  disabled={!!currentUser.organizationId}
                  onChange={this.handleChangeOrganization}
                  loading={loadingFormData}
                  showSearch
                  allowClear
                >
                  {selectableOrganizations.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="userId" label="User" rules={[{ required: true, message: 'User is required' }]}>
                <Select
                  showSearch
                  placeholder="Select User"
                  optionFilterProp="children"
                  filterOption={selectSearchByChildrenText}
                  disabled={
                    ![...MasterRoles, ...ManagerRoles].includes(currentUser.type) ||
                    !this.formRef?.current?.getFieldValue('organizationId')
                  }
                >
                  {selectableOrganizationUsers.map(({ id, firstName, lastName }) => (
                    <Option key={id} value={id}>
                      {`${firstName} ${lastName}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="fromDate" label="From date">
                <DatePicker placeholder="Select From Date" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="toDate"
                label="To date"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, toDate) {
                      const fromDate = getFieldValue('fromDate');
                      if (fromDate && toDate && toDate < fromDate) {
                        return Promise.reject(new Error('To date must be greater than or equal to from date'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker placeholder="Select To Date" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  marginTop: 33.14,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
                <Button htmlType="button" onClick={this.handleResetForm}>
                  Reset
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        <div className="content-container__section">
          <Table
            rowKey="id"
            dataSource={items}
            columns={tableColumns}
            loading={loadingJobs}
            scroll={{
              x: 'max-content',
            }}
            pagination={{
              onChange: this.handleChangePagination,
              pageSize: limit,
              current: page,
              total: totalCount,
            }}
            onChange={this.handleTableChange}
          />
        </div>
      </LayoutContainer>
    );
  }
}

JobListContainer.propTypes = {
  page: PropTypes.number,
  limit: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object),
  sort: PropTypes.string,
  order: PropTypes.string,
  totalCount: PropTypes.number,
  filteredOrganizationId: PropTypes.number,
  filteredUserId: PropTypes.number,
  filteredFromDate: PropTypes.string,
  filteredToDate: PropTypes.string,
  selectableOrganizations: PropTypes.arrayOf(PropTypes.object),
  selectableOrganizationUsers: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.object,
  loadingFormData: PropTypes.bool,
  loadingJobs: PropTypes.bool,
  dispatchFetchOrganizations: PropTypes.func,
  dispatchFetchSelectableOrganizationUsers: PropTypes.func,
  dispatchResetJobListAndFilter: PropTypes.func,
  dispatchFetchJobs: PropTypes.func,
};

const getLoadingFormData = createRequestLoadingSelector(['fetchOrganizations', 'fetchSelectableOrganizationUsers']);
const getLoadingJobs = createRequestLoadingSelector(['fetchJobs']);

const mapStateToProps = (state) => {
  const jobList = getJobList(state);

  return {
    ...jobList,
    loadingFormData: getLoadingFormData(state),
    loadingJobs: getLoadingJobs(state),
    currentUser: getCurrentUser(state),
    selectableOrganizations: getSelectableContractorOrganizations(state),
    selectableOrganizationUsers: getFilteredOrganizationUsers(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchOrganizations: fetchOrganizations,
    dispatchFetchSelectableOrganizationUsers: fetchSelectableOrganizationUsers,
    dispatchResetJobListAndFilter: resetJobListAndFilter,
    dispatchFetchJobs: fetchJobs,
  })(JobListContainer),
);
