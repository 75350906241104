import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'request',
  initialState: {},
  reducers: {
    doRequest(state, { payload }) {
      state[payload] = true;
    },
    finishRequest(state, { payload }) {
      state[payload] = false;
    },
  },
});

export const {
  doRequest,
  finishRequest,
} = slice.actions;

export default slice.reducer;
