import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Form, Button, Typography, Space, Table, Input, Tag } from 'antd';
import moment from 'moment';

import Breadcrumb from '@/components/Breadcumb';
import LayoutContainer from '@/containers/LayoutContainer';

import { fetchOrganizations } from '@/actions/organizationActions';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getOrganizations } from '@/selectors/organizationSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { canCreateOrganization } from '@/policies/organizationPolicies';
import { displayDateTime } from '@/utils/dateUtils';
import './index.scss';

const { Title } = Typography;

const sortAttributeId = () => {
  return (x, y) => x.id - y.id;
};

const sortAttributeString = (attribute) => {
  return (x, y) => {
    const xValue = (x[attribute] || '').toLowerCase();
    const yValue = (y[attribute] || '').toLowerCase();

    if (xValue < yValue) {
      return -1;
    }
    if (xValue > yValue) {
      return 1;
    }
    return 0;
  };
};

const sortAttributeDate = (attribute) => {
  return (x, y) => moment(x[attribute]) - moment(y[attribute]);
};

class OrganizationListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterName: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { dispatchFetchOrganizations } = this.props;

    dispatchFetchOrganizations();
  }

  handleSearch = ({ name }) => {
    this.setState({ filterName: name });
  };

  handleResetForm = () => {
    this.formRef.current.resetFields();
    this.setState({ filterName: null });
  };

  filterOrganizations = (organizations, filterName) => {
    if (!filterName) {
      return organizations;
    }

    return organizations.filter(({ name = '' }) => {
      const loweredName = name.toLowerCase();
      const loweredFilterName = filterName.trim().toLowerCase();

      return loweredName.includes(loweredFilterName);
    });
  };

  getTableColumns = () => {
    return [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        sorter: sortAttributeId(),
        render: (id) => {
          return <Link to={`/organizations/${id}/edit`}>#{id}</Link>;
        },
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: sortAttributeString('name'),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: sortAttributeString('type'),
        align: 'center',
        render: (type) => {
          return <Tag color="cyan">{type}</Tag>;
        },
      },
      {
        title: 'Status',
        dataIndex: 'disabled',
        key: 'disabled',
        align: 'center',
        render: (disabled) => {
          return <Tag color={disabled ? 'red' : 'blue'}>{disabled ? 'Disabled' : 'Active'}</Tag>;
        },
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: sortAttributeDate('createdAt'),
        render: (createdAt) => {
          return createdAt ? displayDateTime(createdAt) : null;
        },
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: sortAttributeDate('updatedAt'),
        render: (updatedAt) => {
          return updatedAt ? displayDateTime(updatedAt) : null;
        },
      },
    ];
  }

  render() {
    const { filterName } = this.state;
    const { loading, organizations: allOrganizations, currentUser } = this.props;
    const organizations = this.filterOrganizations(allOrganizations, filterName);
    const tableColumns = this.getTableColumns();

    return (
      <LayoutContainer contentClassName={['content-container', 'organization-list-container']}>
        <Row gutter={16} align="middle">
          <Col flex="1">
            <Title level={2} className="content-container__title">
              Organizations
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'organizations', link: '/organizations', title: 'Organizations' },
                { key: 'list', title: 'List' },
              ]}
              className="content-container__breadcrumb"
            />
          </Col>
          <Col flex="0">
            {canCreateOrganization(currentUser) && (
              <Link to="/organizations/new">
                <Button type="primary" icon={<PlusOutlined />}>
                  Create New Organization
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <Form
          ref={this.formRef}
          layout="vertical"
          className="content-container__search-form"
          onFinish={this.handleSearch}
          colon={false}
          hideRequiredMark
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Name is required',
                  },
                ]}
              >
                <Input placeholder="Type Organization Name" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Space
                style={{
                  marginTop: 33.14,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
                <Button htmlType="button" onClick={this.handleResetForm}>
                  Reset
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        <div className="content-container__section">
          <Table
            rowKey="id"
            dataSource={organizations}
            columns={tableColumns}
            loading={loading}
            scroll={{
              x: 'max-content',
            }}
            pagination={{
              total: organizations.length,
              defaultPageSize: 50,
              defaultCurrent: 1,
            }}
          />
        </div>
      </LayoutContainer>
    );
  }
}

OrganizationListContainer.propTypes = {
  loading: PropTypes.bool,
  organizations: PropTypes.arrayOf(PropTypes.object),
  currentUser: PropTypes.object,
  dispatchFetchOrganizations: PropTypes.func,
};

const loadingOrganizations = createRequestLoadingSelector(['fetchOrganizations']);

const mapStateToProps = (state) => ({
  loading: loadingOrganizations(state),
  organizations: getOrganizations(state),
  currentUser: getCurrentUser(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchOrganizations: fetchOrganizations,
  })(OrganizationListContainer),
);
