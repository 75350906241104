import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Space, Form, Input, Select, Radio } from 'antd';

import { selectSearchByChildrenText } from '@/utils/selectUtils';
import ErrorMessage from '@/components/ErrorMessage';
import { MasterRoles } from '@/constants';

const { Option } = Select;
const { Paragraph } = Typography;

const UserForm = (props) => {
  const {
    submitting,
    submitTitle,
    formObject,
    formErrors,
    typeSelectOptions,
    organizationSelectOptions,
    departmentSelectOptions,
    canSelectOrganization,
    onCancel,
    onSubmit,
  } = props;

  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);
  const [noNeedOrganization, setNoNeedOrganization] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    if (!updated) {
      form.resetFields();
    }
  }, [form, formObject, updated]);

  useEffect(() => {
    if (formObject.organizationId) {
      const organization = organizationSelectOptions.find(({ id }) => `${id}` === `${formObject.organizationId}`);
      setSelectedOrganization(organization);
    } else {
      setSelectedOrganization(null);
    }
    setNoNeedOrganization(MasterRoles.includes(formObject.type));
  }, [formObject, organizationSelectOptions]);

  const onValuesChange = (changedValues) => {
    setUpdated(true);

    if (changedValues.organizationId) {
      const organization = organizationSelectOptions.find(({ id }) => `${id}` === `${changedValues.organizationId}`);
      setSelectedOrganization(organization);
      form.setFieldsValue({
        organizationGroupId: null,
      });
    }

    if (changedValues.type) {
      if (MasterRoles.includes(changedValues.type)) {
        form.setFieldsValue({
          organizationId: null,
          organizationGroupId: null,
          departmentIds: [],
        });
        setNoNeedOrganization(true);
        setSelectedOrganization(null);
      } else {
        setNoNeedOrganization(false);
      }
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      initialValues={formObject}
      onValuesChange={onValuesChange}
      colon={false}
      layout="vertical"
      className="content-container__form"
      hideRequiredMark
    >
      <ErrorMessage errors={formErrors} />
      <Paragraph>Please input the information below:</Paragraph>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { type: 'email', message: 'Email is not valid' },
          { required: true, message: 'Email is required' },
        ]}
        hasFeedback
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[{ required: true, message: 'First name is required' }]}
        hasFeedback
      >
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[{ required: true, message: 'Last name is required' }]}
        hasFeedback
      >
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Type is required' }]} hasFeedback>
        <Select
          showSearch
          placeholder="Select type"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
        >
          {typeSelectOptions.map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="organizationId"
        label="Organization"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const selectedType = getFieldValue('type');
              if (MasterRoles.includes(selectedType) || value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Organization is required'));
            },
          }),
        ]}
        hasFeedback
      >
        <Select
          showSearch
          placeholder="Select organization"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
          disabled={!canSelectOrganization || noNeedOrganization}
        >
          {organizationSelectOptions.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="organizationGroupId" label="Subgroup" hasFeedback>
        <Select
          showSearch
          placeholder="Select subgroup"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
          disabled={noNeedOrganization}
        >
          {(selectedOrganization?.organizationGroups || []).map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="departmentIds"
        label="Departments"
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              const selectedType = getFieldValue('type');
              if (MasterRoles.includes(selectedType) || value && value.length > 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Departments is required'));
            },
          }),
        ]}
        hasFeedback
      >
        <Select
          showSearch
          mode="multiple"
          placeholder="Select departments"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
          disabled={noNeedOrganization}
        >
          {departmentSelectOptions.map(({ id, name }) => (
            <Option key={id} value={`${id}`}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="disabled" label="Status">
        <Radio.Group buttonStyle="solid" size="medium">
          <Radio.Button value={0} className="ant-radio-button-wrapper-green">
            Active
          </Radio.Button>
          <Radio.Button value={1} className="ant-radio-button-wrapper-red">
            Disabled
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {submitTitle || 'Submit'}
          </Button>
          {onCancel && (
            <Button htmlType="button" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

UserForm.propTypes = {
  submitting: PropTypes.bool,
  submitTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  formObject: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organizationId: PropTypes.number,
    departmentIds: PropTypes.array,
    disabled: PropTypes.number,
    type: PropTypes.string,
  }),
  formErrors: PropTypes.arrayOf(PropTypes.string),
  typeSelectOptions: PropTypes.arrayOf(PropTypes.string),
  organizationSelectOptions: PropTypes.arrayOf(PropTypes.object),
  departmentSelectOptions: PropTypes.arrayOf(PropTypes.object),
  canSelectOrganization: PropTypes.bool,
};

export default UserForm;
