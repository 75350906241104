import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col, Button, Typography, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import OpportunityTypeForm from '@/forms/OpportunityTypeForm';

import {
  fetchTargetOpportunityType,
  updateOpportunityType,
  deleteOpportunityType,
} from '@/actions/opportunityTypeActions';
import { fetchDepartments } from '@/actions/departmentActions';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getTargetOpportunityType } from '@/selectors/opportunityTypeSelectors';
import { getDepartments } from '@/selectors/departmentSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import { canDeleteOpportunityType } from '@/policies/opportunityTypePolicies';
import './index.scss';

const { Title } = Typography;

class OpportunityTypeUpdateContainer extends Component {
  componentDidMount() {
    const {
      dispatchFetchDepartments,
      dispatchFetchTargetOpportunityType,
      match: { params },
    } = this.props;
    dispatchFetchDepartments();
    dispatchFetchTargetOpportunityType(params.id);
  }

  handleSubmit = (values) => {
    const { dispatchUpdateOpportunityType, targetOpportunityType } = this.props;
    dispatchUpdateOpportunityType(targetOpportunityType.id, values);
  }

  handleDelete = () => {
    const { dispatchDeleteOpportunityType, targetOpportunityType } = this.props;
    dispatchDeleteOpportunityType(targetOpportunityType.id);
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      currentUser,
      selectableDepartments,
      targetOpportunityType,
      errors,
      loading,
      submitting,
    } = this.props;

    return (
      <LayoutContainer contentClassName={['content-container', 'opportunity-type-update-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Row gutter={16} align='middle'>
              <Col flex="1">
                <Title level={2} className="content-container__title">
                  Edit Opportunity Type
                </Title>
                <Breadcrumb
                  items={[
                    { key: 'home', link: '/', title: 'Home' },
                    { key: 'opportunity-types', link: '/opportunity-types', title: 'Opportunity Types' },
                    { key: 'update', title: 'Edit Opportunity Type' },
                  ]}
                  className="content-container__breadcrumb"
                />
              </Col>
              <Col flex="0">
                {canDeleteOpportunityType(currentUser) && (
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    onClick={this.handleDelete}
                    loading={submitting}
                  >
                    Delete
                  </Button>
                )}
              </Col>
            </Row>
            {!targetOpportunityType || loading ? (
              <Spin />
            ) : (
              <OpportunityTypeForm
                submitting={submitting}
                submitTitle='Update Opportunity Type'
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formObject={{
                  id: targetOpportunityType.id,
                  name: targetOpportunityType.name,
                  departmentIds: targetOpportunityType.departmentIds,
                }}
                formErrors={errors}
                departmentSelectOptions={selectableDepartments}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

OpportunityTypeUpdateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  targetOpportunityType: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectableDepartments: PropTypes.arrayOf(PropTypes.object),
  dispatchFetchDepartments: PropTypes.func,
  dispatchFetchTargetOpportunityType: PropTypes.func,
  dispatchUpdateOpportunityType: PropTypes.func,
  dispatchDeleteOpportunityType: PropTypes.func,
};

const loadingData = createRequestLoadingSelector(['fetchDepartments', 'fetchTargetOpportunityType']);
const submittingOpportunityType = createRequestLoadingSelector(['updateOpportunityType', 'deleteOpportunityType']);

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  loading: loadingData(state),
  submitting: submittingOpportunityType(state),
  targetOpportunityType: getTargetOpportunityType(state),
  currentUser: getCurrentUser(state),
  selectableDepartments: getDepartments(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchDepartments: fetchDepartments,
    dispatchFetchTargetOpportunityType: fetchTargetOpportunityType,
    dispatchUpdateOpportunityType: updateOpportunityType,
    dispatchDeleteOpportunityType: deleteOpportunityType,
  })(OpportunityTypeUpdateContainer),
);
