import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';

import { OrganizationType } from '@/constants';

export const getOrganizations = (state) => {
  return state.organization.items;
};

export const getTargetOrganization = (state) => {
  return state.organization.target;
};

export const getSelectableOrganizations = createSelector(getOrganizations, (organizations) => {
  const usedOrganizations = organizations
    .filter(({ disabled }) => !disabled)
    .map(({ id, name, type, organizationGroups, parentId }) => ({
      id,
      name,
      type,
      organizationGroups,
      parentId,
    }));
  return sortBy(usedOrganizations, (organization) => organization.name?.toLowerCase());
});

export const getSelectableContractorOrganizations = createSelector(getOrganizations, (organizations) => {
  const usedOrganizations = organizations
    .filter(({ type, disabled }) => type === OrganizationType.Contractor && !disabled)
    .map(({ id, name, type, organizationGroups, parentId }) => ({
      id,
      name,
      type,
      organizationGroups,
      parentId,
    }));
  return sortBy(usedOrganizations, (organization) => organization.name?.toLowerCase());
});
