import api from '@/api';
import { displayCurrency } from '@/utils/numberUtils';

export const fetchTechnicianRankings = async (targetYear) => {
  const { data } = await api.get(`/ranks/technician-rank/${targetYear}`);

  return data.map((dt) => ({
    rankNumber: parseInt(dt.ranking, 10),
    contractorName: dt.contractor,
    technicanName: dt.technician,
    revenueNumber: parseFloat(dt.revenue),
    revenueText: displayCurrency(parseFloat(dt.revenue)),
    awardLevel: dt.awardLevel,
  }));
};

export const fetchComfortConsultantRankings = async (targetYear) => {
  const { data } = await api.get(`/ranks/comfort-consultant-rank/${targetYear}`);

  return data.map((dt) => ({
    rankNumber: parseInt(dt.ranking, 10),
    contractorName: dt.contractor,
    comfortConsultantName: dt.technician,
    revenueNumber: parseFloat(dt.revenue),
    revenueText: displayCurrency(parseFloat(dt.revenue)),
    awardLevel: dt.awardLevel,
  }));
};

export const fetchPlumberRankings = async (targetYear) => {
  const { data } = await api.get(`/ranks/plumber-rank/${targetYear}`);

  return data.map((dt) => ({
    rankNumber: parseInt(dt.ranking, 10),
    contractorName: dt.contractor,
    plumberName: dt.technician,
    revenueNumber: parseFloat(dt.revenue),
    revenueText: displayCurrency(parseFloat(dt.revenue)),
    awardLevel: dt.awardLevel,
  }));
};

export const fetchElectricianRankings = async (targetYear) => {
  const { data } = await api.get(`/ranks/electrician-rank/${targetYear}`);

  return data.map((dt) => ({
    rankNumber: parseInt(dt.ranking, 10),
    contractorName: dt.contractor,
    electricianName: dt.technician,
    revenueNumber: parseFloat(dt.revenue),
    revenueText: displayCurrency(parseFloat(dt.revenue)),
    awardLevel: dt.awardLevel,
  }));
};
