/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Typography, Spin } from 'antd';
import { compact, sortBy } from 'lodash';

import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import GoalForm from '@/forms/GoalForm';

import { fetchTargetOrganization, fetchTargetGoal, updateGoal } from '@/actions/goalActions';
import { fetchGoalStrategies } from '@/actions/goalStrategyActions';
import { getGoalStrategies } from '@/selectors/goalStrategySelectors';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getTargetOrganization , getTargetGoal } from '@/selectors/goalSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import { ManagerRoles, WorkerRoles } from '@/constants';
import './index.scss';

const { Title } = Typography;

const customGoalStrategy = {
  id: 'customGoalStrategy',
  name: 'Custom',
  year: null,
  jan: 0,
  feb: 0,
  mar: 0,
  apr: 0,
  may: 0,
  jun: 0,
  jul: 0,
  aug: 0,
  sep: 0,
  oct: 0,
  nov: 0,
  dec: 0,
  isGlobal: false,
};

class GoalUpdateContainer extends Component {
  componentDidMount() {
    const {
      currentUser,
      dispatchFetchTargetOrganization,
      dispatchFetchGoalStrategies,
      dispatchFetchTargetGoal,
      match: { params },
      location: { search },
    } = this.props;
    const searchPrams = new URLSearchParams(search);

    dispatchFetchGoalStrategies();
    dispatchFetchTargetOrganization(searchPrams.get('organizationId') || currentUser?.organizationId);
    dispatchFetchTargetGoal(params.userId, params.year);
  }

  handleSubmit = (values) => {
    const { dispatchUpdateGoal } = this.props;
    const { userId } = values;

    delete values.userId;
    delete values.organizationId;
    if (values.goalStrategyId === 'customGoalStrategy') {
      delete values.goalStrategyId;
    }

    dispatchUpdateGoal(userId, values.year, values);
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      errors,
      loading,
      submitting,
      targetGoal,
      targetOrganization,
      selectableOrganizations,
      selectableGoalStrategies,
      selectableUsers,
    } = this.props;

    return (
      <LayoutContainer contentClassName={['content-container', 'goal-update-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Title level={2} className="content-container__title">
              Edit Goal
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'goals', link: '/goals', title: 'Goals' },
                { key: 'update', title: 'Edit Goal' },
              ]}
              className="content-container__breadcrumb"
            />
            {!targetGoal || loading ? (
              <Spin />
            ) : (
              <GoalForm
                mode='edit'
                submitting={submitting}
                submitTitle='Update Goal'
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formObject={{
                  ...targetGoal,
                  organizationId: targetOrganization.id,
                }}
                formErrors={errors}
                canSelectUser={false}
                canSelectOrganization={false}
                userSelectOptions={selectableUsers}
                organizationSelectOptions={selectableOrganizations}
                goalStrategySelectOptions={selectableGoalStrategies}
                onChangeOrganization={() => {}}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

GoalUpdateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  targetGoal: PropTypes.object,
  currentUser: PropTypes.object,
  targetOrganization: PropTypes.object,
  selectableGoalStrategies: PropTypes.arrayOf(PropTypes.object),
  selectableOrganizations: PropTypes.arrayOf(PropTypes.object),
  selectableUsers: PropTypes.arrayOf(PropTypes.object),
  dispatchFetchTargetOrganization: PropTypes.func,
  dispatchFetchGoalStrategies: PropTypes.func,
  dispatchFetchTargetGoal: PropTypes.func,
  dispatchUpdateGoal: PropTypes.func,
};

const loadingData = createRequestLoadingSelector(['fetchTargetOrganization', 'fetchGoalStrategies', 'fetchTargetGoal']);
const submittingGoal = createRequestLoadingSelector(['updateGoal']);

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const targetOrganization = getTargetOrganization(state);
  const goalStrategies = getGoalStrategies(state);
  const targetGoal = getTargetGoal(state);

  const selectableUsers = sortBy(
    targetOrganization?.users?.filter(({ type, disabled }) =>
      [...ManagerRoles, ...WorkerRoles].includes(type) && !disabled,
    ) || [],
    (user) => `${user.firstName} ${user.lastName}`.toLowerCase(),
  );
  const goalStrategySelectOptions =
      targetGoal && targetGoal.goalStrategy
        ? !!targetGoal.goalStrategy.isGlobal === true
          ? [...goalStrategies, customGoalStrategy]
          : [...goalStrategies, targetGoal.goalStrategy]
        : [...goalStrategies, customGoalStrategy];
  const selectableGoalStrategies = sortBy(
    goalStrategySelectOptions,
    (goalStrategy) => goalStrategy.name?.toLowerCase(),
  );

  return {
    errors: getErrors(state),
    loading: loadingData(state),
    submitting: submittingGoal(state),
    selectableOrganizations: compact([targetOrganization]),
    selectableGoalStrategies,
    selectableUsers,
    currentUser,
    targetOrganization,
    targetGoal,
  };
};

export default withRouter(connect(mapStateToProps, {
  dispatchFetchTargetOrganization: fetchTargetOrganization,
  dispatchFetchGoalStrategies: fetchGoalStrategies,
  dispatchFetchTargetGoal: fetchTargetGoal,
  dispatchUpdateGoal: updateGoal,
})(GoalUpdateContainer));
