import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'user',
  initialState: {
    page: 1,
    limit: 50,
    sort: null,
    order: null,
    items: [],
    target: null,
    totalCount: 0,
    filteredEmail: null,
    filteredOrganizationId: null,
  },
  reducers: {
    setUsers(state, { payload }) {
      state.page = payload.page;
      state.limit = payload.limit;
      state.items = payload.items;
      state.sort = payload.sort;
      state.order = payload.order;
      state.totalCount = payload.totalCount;
      state.filteredEmail = payload.filteredEmail;
      state.filteredOrganizationId = payload.filteredOrganizationId;
      state.loading = false;
    },
    setTargetUser(state, { payload: { target } }) {
      state.target = target;
    },
  },
});

export const {
  setUsers,
  setTargetUser,
} = slice.actions;

export default slice.reducer;
