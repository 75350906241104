import { notification } from 'antd';

import api from '@/api';
import { setKpis } from '@/store/kpi';
import { doRequest, finishRequest } from '@/store/request';
import { createErrors } from '@/actions/errorActions';

export const fetchKpis = () => async (dispatch) => {
  dispatch(doRequest('fetchKpis'));

  try {
    const { data: items } = await api.get('/kpis');
    dispatch(setKpis({ items }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchKpis'));
  }
};

export const createOrUpdateKpis = (data) => async (dispatch) => {
  dispatch(doRequest('createOrUpdateKpis'));

  try {
    const { data: items } = await api.post('/kpis', data);
    dispatch(setKpis({ items }));

    notification.success({
      message: 'Set KPIs',
      description: 'Updated kpis successfully',
    });
  } catch(error) {
    dispatch(createErrors(['Failed to update kpis']));
    console.error(error);
  } finally {
    dispatch(finishRequest('createOrUpdateKpis'));
  }
};
