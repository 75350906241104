import { Roles } from '@/constants';

export const canManageUser = (user) => {
  return [Roles.Admin, Roles.Trainer, Roles.Owner, Roles.Manager].includes(user?.type);
};

export const canViewUser = (user) => {
  return canManageUser(user);
};

export const canCreateUser = (user) => {
  return canManageUser(user);
};

export const canUpdateUser = (user) => {
  return canManageUser(user);
};

export const canDeleteUser = (user) => {
  return canManageUser(user);
};
