import { notification } from 'antd';

import api from '@/api';
import history from '@/history';
import { setOrganizations, setTargetOrganization } from '@/store/organization';
import { doRequest, finishRequest } from '@/store/request';
import { createErrors } from '@/actions/errorActions';
import { transformErrors } from '@/utils/errorUtils';

export const fetchOrganizations = (params = {}) => async (dispatch) => {
  dispatch(doRequest('fetchOrganizations'));

  try {
    const { data: items } = await api.get('/organizations', { params });
    dispatch(setOrganizations({ items }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchOrganizations'));
  }
};

export const fetchTargetOrganization = (id) => async (dispatch) => {
  dispatch(doRequest('fetchTargetOrganization'));

  try {
    const { data: organization } = await api.get(`/organizations/${id}`);
    dispatch(setTargetOrganization({ target: organization }));
  } catch(error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchTargetOrganization'));
  }
};

export const createOrganization = (data) => async (dispatch) => {
  dispatch(doRequest('createOrganization'));

  try {
    await api.post('/organizations', data);
    notification.success({
      message: 'Create organization',
      description: 'Created organization successfully',
    });
    history.push('/organizations');
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('createOrganization'));
  }
};

export const updateOrganization = (id, data) => async (dispatch) => {
  dispatch(doRequest('updateOrganization'));

  try {
    await api.put(`/organizations/${id}`, data);
    dispatch(setTargetOrganization({ target: null }));

    notification.success({
      message: 'Update organization',
      description: 'Updated organization successfully',
    });
    history.push('/organizations');
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('updateOrganization'));
  }
};

export const deleteOrganization = (id) => async (dispatch) => {
  dispatch(doRequest('deleteOrganization'));

  try {
    await api.delete(`/organizations/${id}`);
    dispatch(setTargetOrganization({ target: null }));

    notification.success({
      message: 'Delete organization',
      description: 'Deleted organization successfully',
    });
    history.push('/organizations');
  } catch (error) {
    notification.error({
      message: 'Delete organization',
      description: 'Unable to delete organization',
    });
    console.error(error);
  } finally {
    dispatch(finishRequest('deleteOrganization'));
  }
};
