import { configureStore, combineReducers } from '@reduxjs/toolkit';

import error from './error';
import request from './request';
import auth from './auth';
import user from './user';
import job from './job';
import jobType from './jobType';
import opportunityType from './opportunityType';
import organization from './organization';
import department from './department';
import goalStrategy from './goalStrategy';
import goal from './goal';
import kpi from './kpi';

const reducer = combineReducers({
  error,
  request,
  auth,
  user,
  job,
  jobType,
  opportunityType,
  organization,
  department,
  goalStrategy,
  goal,
  kpi,
});

const store = configureStore({
  reducer,
});

export default store;
