import React, { Component, Fragment } from 'react';
import { Alert } from 'antd';
import './index.scss';

class ErrorMessage extends Component {
  render() {
    const { errors } = this.props;

    if (!errors.length) {
      return null;
    }

    return (
      <div className="error-message__container">
        <Alert
          description={
            <Fragment>
              {errors.map((error) => (
                <Fragment key={error}>
                  {error}
                  <br />
                </Fragment>
              ))}
            </Fragment>
          }
          message="Error"
          type="error"
          showIcon
        />
      </div>
    );
  }
}

export default ErrorMessage;
