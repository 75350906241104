import axios from 'axios';
import { notification } from 'antd';

import { setCurrentUser } from '@/store/auth';
import { getAuthTokenFromLocalStorage , removeUserFromLocalStorage } from '@/utils/authUtils';
import { API_BASE_URL } from '@/constants';
import store from '@/store';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getAuthTokenFromLocalStorage()}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { config, response } = error;
    if (config?.url !== '/auth/login' && response?.status === 401) {
      const { auth: { currentUser } } = store.getState();
      if (currentUser) {
        notification.error({
          message: 'Session Expired',
          description: 'Please login again to continue',
        });
        removeUserFromLocalStorage();
        store.dispatch(setCurrentUser({ user: null }));
      }
    }
    return Promise.reject(error);
  }
);

export default api;
