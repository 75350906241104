import { notification } from 'antd';

import api from '@/api';
import history from '@/history';
import { setUsers, setTargetUser } from '@/store/user';
import { doRequest, finishRequest } from '@/store/request';
import { createErrors } from '@/actions/errorActions';
import { transformErrors } from '@/utils/errorUtils';

const sortOrderTranfomer = {
  'ascend': 'ASC',
  'descend': 'DESC',
};

export const fetchUsers = ({ organizationId, email, page, limit, sort, order }) => async (dispatch) => {
  dispatch(doRequest('fetchUsers'));

  try {
    let params = {
      organizationId,
      email,
      limit,
      page,
    };
    if (sort && order) {
      params = { ...params, sort, order: sortOrderTranfomer[order] };
    }
    const { data } = await api.get('/users', { params });
    dispatch(
      setUsers({
        items: data.items,
        totalCount: data.meta?.totalItems,
        filteredOrganizationId: organizationId,
        filteredEmail: email,
        page: data.meta?.currentPage,
        limit: data.meta?.itemsPerPage,
        sort,
        order,
      }),
    );
  } catch(error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchUsers'));
  }
};

export const fetchTargetUser = (id) => async (dispatch) => {
  dispatch(doRequest('fetchTargetOrganization'));

  try {
    const { data: user } = await api.get(`/users/${id}`);
    dispatch(setTargetUser({ target: user }));
  } catch(error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchTargetOrganization'));
  }
};

export const createUser = (data, options = {}) => async (dispatch) => {
  dispatch(doRequest('createUser'));

  try {
    await api.post('/users', data);
    notification.success({
      message: 'Create user',
      description: 'Created user successfully',
    });
    const backUrl = options.backUrl || '/users';
    history.push(backUrl);
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('createUser'));
  }
};

export const updateUser = (id, data, options = {}) => async (dispatch) => {
  dispatch(doRequest('updateUser'));

  try {
    await api.put(`/users/${id}`, data);
    dispatch(setTargetUser({ target: null }));

    notification.success({
      message: 'Update user',
      description: 'Updated user successfully',
    });
    const backUrl = options.backUrl || '/users';
    history.push(backUrl);
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('updateUser'));
  }
};

export const deleteUser = (id, options = {}) => async (dispatch) => {
  dispatch(doRequest('deleteUser'));

  try {
    await api.delete(`/users/${id}`);
    dispatch(setTargetUser({ target: null }));

    notification.success({
      message: 'Delete user',
      description: 'Deleted user successfully',
    });
    const backUrl = options.backUrl || '/users';
    history.push(backUrl);
  } catch(error) {
    notification.error({
      message: 'Delete user',
      description: 'Unable to delete user',
    });
    console.error(error);
  } finally {
    dispatch(finishRequest('deleteUser'));
  }
};

export const resendUserActivation = (id) => async (dispatch) => {
  dispatch(doRequest('resendUserActivation'));

  try {
    await api.post(`/users/${id}/resend-activation`);
    notification.success({
      message: 'Resend activation email',
      description: 'Resent activation email successfully',
    });
  } catch(error) {
    notification.error({
      message: 'Resend activation email',
      description: error.response?.data?.message,
    });
    console.error(error);
  } finally {
    dispatch(finishRequest('resendUserActivation'));
  }
};
