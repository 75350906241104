import { notification } from 'antd';

import api from '@/api';
import history from '@/history';
import { setOpportunityTypes, setTargetOpportunityType } from '@/store/opportunityType';
import { doRequest, finishRequest } from '@/store/request';
import { createErrors } from '@/actions/errorActions';
import { transformErrors } from '@/utils/errorUtils';

export const fetchOpportunityTypes = (sort = null, order = null) => async (dispatch) => {
  dispatch(doRequest('fetchOpportunityTypes'));

  try {
    const { data: items } = await api.get('/opportunity-types', {
      params: {
        sort,
        order,
      },
    });
    dispatch(setOpportunityTypes({ items }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchOpportunityTypes'));
  }
};

export const fetchTargetOpportunityType = (id) => async (dispatch) => {
  dispatch(doRequest('fetchTargetOpportunityType'));

  try {
    const { data: opportunityType } = await api.get(`/opportunity-types/${id}`);
    dispatch(setTargetOpportunityType({ target: opportunityType }));
  } catch(error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchTargetOpportunityType'));
  }
};

export const createOpportunityType = (data) => async (dispatch) => {
  dispatch(doRequest('createOpportunityType'));

  try {
    await api.post('/opportunity-types', data);
    notification.success({
      message: 'Create opportunity type',
      description: 'Created opportunity type successfully',
    });
    history.push('/opportunity-types');
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('createOpportunityType'));
  }
};

export const updateOpportunityType = (id, data) => async (dispatch) => {
  dispatch(doRequest('updateOpportunityType'));

  try {
    await api.put(`/opportunity-types/${id}`, data);
    dispatch(setTargetOpportunityType({ target: null }));

    notification.success({
      message: 'Update opportunity type',
      description: 'Updated opportunity type successfully',
    });
    history.push('/opportunity-types');
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('updateOpportunityType'));
  }
};

export const deleteOpportunityType = (id) => async (dispatch) => {
  dispatch(doRequest('deleteOpportunityType'));

  try {
    await api.delete(`/opportunity-types/${id}`);
    dispatch(setTargetOpportunityType({ target: null }));

    notification.success({
      message: 'Delete opportunity type',
      description: 'Deleted opportunity type successfully',
    });
    history.push('/opportunity-types');
  } catch (error) {
    notification.error({
      message: 'Delete opportunity type',
      description: 'Unable to delete opportunity type',
    });
    console.error(error);
  } finally {
    dispatch(finishRequest('deleteOpportunityType'));
  }
};
