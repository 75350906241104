import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col, Typography } from 'antd';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import GoalStrategyForm from '@/forms/GoalStrategyForm';

import { createGoalStrategy } from '@/actions/goalStrategyActions';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import './index.scss';

const { Title } = Typography;

class GoalStrategyCreateContainer extends Component {
  handleSubmit = (values) => {
    const { dispatchCreateGoalStrategy } = this.props;
    dispatchCreateGoalStrategy(values);
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      errors,
      submitting,
    } = this.props;

    return (
      <LayoutContainer contentClassName={['content-container', 'goal-strategy-create-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Title level={2} className="content-container__title">
              Create New Goal Strategy
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'goal-strategies', link: '/goal-strategies', title: 'Goal Strategies' },
                { key: 'create', title: 'Create New Goal Strategy' },
              ]}
              className="content-container__breadcrumb"
            />
            <GoalStrategyForm
              submitting={submitting}
              submitTitle='Create Goal Strategy'
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              formObject={{
                id: null,
                name: 'Default',
              }}
              formErrors={errors}
            />
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

GoalStrategyCreateContainer.propTypes = {
  history: PropTypes.object,
  submitting: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  dispatchCreateGoalStrategy: PropTypes.func,
};

const submitting = createRequestLoadingSelector(['createGoalStrategy']);

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  submitting: submitting(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchCreateGoalStrategy: createGoalStrategy,
  })(GoalStrategyCreateContainer),
);
