import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import classNames from 'classnames';

import { logout } from '@/actions/authActions';
import { canViewUser } from '@/policies/userPolicies';
import { canViewOrganization } from '@/policies/organizationPolicies';
import { canViewGoal } from '@/policies/goalPolicies';
import { canViewGoalStrategy } from '@/policies/goalStrategyPolicies';
import { canViewJob } from '@/policies/jobPolicies';
import { canViewJobType } from '@/policies/jobTypePolicies';
import { canViewOpportunityType } from '@/policies/opportunityTypePolicies';
import { canSetKpi } from '@/policies/kpiPolicies';
import { canViewAdminMenu, accessableDashboardDepartmentLinks } from '@/policies/appPolicies';
import { getCurrentUser } from '@/selectors/authSelectors';
import logoImage from '@/assets/images/logo.png';
import './index.scss';

const { Header, Content } = Layout;

class LayoutContainer extends Component {
  handleClickOnRightMenu = ({ key }) => {
    const { dispatchLogout } = this.props;

    switch (key) {
      case 'userLogout':
        dispatchLogout();
        break;
      default:
    }
  };

  leftMenuSelectedKeys = () => {
    const { location: { pathname, search } } = this.props;
    const fullPath = `${pathname}${search}`;

    const menuKeys = {
      'home': [/^\/$/],
      'dashboardAll': [/dashboard\?allDepartment=true/],
      'dashboardSerice': [/dashboard\?departmentId=1/],
      'dashboardSales': [/dashboard\?departmentId=2/],
      'dashboardPlumbing': [/dashboard\?departmentId=3/],
      'dashboardElectrical': [/dashboard\?departmentId=4/],
      'jobs': [/jobs/],
      'users': [/users/],
      'goals': [/goals/],
      'organizations': [/organizations/],
      'technicianRankings': [/technician-rankings/],
      'comfortConsultantRankings': [/comfort-consultant-rankings/],
      'plumberRankings': [/plumber-rankings/],
      'electricianRankings': [/electrician-rankings/],
      'adminGoals': [/goal-strategies/],
      'adminKpis': [/kpis/],
      'adminJobTypes': [/job-types/],
      'adminOpportunityTypes': [/opportunity-types/],
    };

    return Object.entries(menuKeys).reduce(
      (memo, [key, regs]) => (regs.some((reg) => fullPath.match(reg))
        ? [...memo, key]
        : [...memo]),
      [],
    );
  }

  render() {
    const { currentUser, children, contentClassName } = this.props;
    const leftMenuSelectedKeys = this.leftMenuSelectedKeys();

    return (
      <Layout className="layout__container">
        <Header className="layout__header">
          <Link to="/">
            <img src={logoImage} alt="logo" className="layout__header__logo" />
          </Link>
          <Menu
            theme="light"
            mode="horizontal"
            className="layout__header__left-menu"
            defaultSelectedKeys={leftMenuSelectedKeys}
          >
            <Menu.Item key="home">
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.SubMenu
              key="dashboard"
              title="Dashboard"
            >
              {accessableDashboardDepartmentLinks(currentUser).map(departmentLink => (
                <Menu.Item key={departmentLink.key} disabled={departmentLink.disabled}>
                  <Link
                    to={departmentLink.path}
                    disabled={departmentLink.disabled}
                  >
                    {departmentLink.title}
                  </Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
            <Menu.SubMenu key="rankings" title="Rankings">
              <Menu.Item key="technicianRankings">
                <Link to="/rankings/technician-rankings">Technician Rankings</Link>
              </Menu.Item>
              <Menu.Item key="comfortConsultantRankings">
                <Link to="/rankings/comfort-consultant-rankings">Comfort Consultant Rankings</Link>
              </Menu.Item>
              <Menu.Item key="plumberRankings">
                <Link to="/rankings/plumber-rankings">Plumber Rankings</Link>
              </Menu.Item>
              <Menu.Item key="electricianRankings">
                <Link to="/rankings/electrician-rankings">Electrician Rankings</Link>
              </Menu.Item>
            </Menu.SubMenu>
            {canViewUser(currentUser) && (
              <Menu.Item key="users">
                <Link to="/users">Users</Link>
              </Menu.Item>
            )}
            {canViewOrganization(currentUser) && (
              <Menu.Item key="organizations">
                <Link to="/organizations">Organizations</Link>
              </Menu.Item>
            )}
            {canViewGoal(currentUser) && (
              <Menu.Item key="goals">
                <Link to="/goals">Goals</Link>
              </Menu.Item>
            )}
            {canViewJob(currentUser) && (
              <Menu.Item key="jobs">
                <Link to="/jobs">Jobs</Link>
              </Menu.Item>
            )}
            {canViewAdminMenu(currentUser) && (
              <Menu.SubMenu key="admin" title="Admin">
                {canViewGoalStrategy(currentUser) && (
                  <Menu.Item key="adminGoals">
                    <Link to="/goal-strategies">Goals</Link>
                  </Menu.Item>
                )}
                {canSetKpi(currentUser) && (
                  <Menu.Item key="adminKpis">
                    <Link to="/kpis">KPIs</Link>
                  </Menu.Item>
                )}
                {canViewJobType(currentUser) && (
                  <Menu.Item key="adminJobTypes">
                    <Link to="/job-types">Job Types</Link>
                  </Menu.Item>
                )}
                {canViewOpportunityType(currentUser) && (
                  <Menu.Item key="adminOpportunityTypes">
                    <Link to="/opportunity-types">Opportunity Types</Link>
                  </Menu.Item>
                )}
              </Menu.SubMenu>
            )}
          </Menu>
          <Menu
            theme="light"
            mode="horizontal"
            className="layout__header__right-menu"
            onClick={this.handleClickOnRightMenu}
          >
            <Menu.SubMenu key="support" title="Support">
              <Menu.Item key="supportContact">
                <a href="mailto:support@sbeodyssey.com">Contact SBE</a>
              </Menu.Item>
              <Menu.Item key="supportSuperConference">
                <a href="https://sbeodyssey.com/super-conference" target="_blank" rel="noreferrer">
                  Super Conference
                </a>
              </Menu.Item>
              <Menu.Item key="supportMastermindTraining">
                <Link to="https://sbeodyssey.com/training" target="_blank" rel="noreferrer">
                  Training
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu key="user" title={currentUser.email}>
              <Menu.Item key="userLogout">Logout</Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Header>
        <Content className="layout__content">
          <div className={classNames('layout__content', contentClassName)}>
            {children}
          </div>
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchLogout: logout,
  })(LayoutContainer),
);
