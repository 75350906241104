import api from '@/api';
import { setUserToLocalStorage, removeUserFromLocalStorage } from '@/utils/authUtils';
import { doRequest, finishRequest } from '@/store/request';
import { setCurrentUser } from '@/store/auth';
import { createErrors } from '@/actions/errorActions';

export const login = ({ email, password }) => async (dispatch) => {
  dispatch(doRequest('login'));

  try {
    const { data: user } = await api.post('/auth/login', { email, password });
    setUserToLocalStorage(user);
    dispatch(setCurrentUser({ user }));
  } catch (error) {
    dispatch(createErrors(['Email or password is invalid']));
    console.error(error);
  } finally {
    dispatch(finishRequest('login'));
  }
};

export const logout = () => async (dispatch) => {
  removeUserFromLocalStorage();
  dispatch(setCurrentUser({ user: null }));
};

export const checkin = () => async (dispatch) => {
  dispatch(doRequest('checkin'));

  try {
    const { data: user } = await api.get('/auth/me');
    dispatch(setCurrentUser({ user }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('checkin'));
  }
};
