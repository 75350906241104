import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb as AntBreadcrumb } from 'antd';

class Breadcrumb extends PureComponent {
  render() {
    const { items, className } = this.props;

    return (
      <AntBreadcrumb className={className}>
        {items.map(({ key, link, title }) => (
          <AntBreadcrumb.Item key={key}>
            {link ? <Link to={link}>{title}</Link> : title}
          </AntBreadcrumb.Item>
        ))}
      </AntBreadcrumb>
    );
  }
}

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
};

export default Breadcrumb;
