import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { Row, Col, Typography, Spin } from 'antd';
import LayoutContainer from '@/containers/LayoutContainer';
import Breadcrumb from '@/components/Breadcumb';
import UserForm from '@/forms/UserForm';

import { createUser } from '@/actions/userActions';
import { fetchOrganizations } from '@/actions/organizationActions';
import { fetchDepartments } from '@/actions/departmentActions';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getSelectableContractorOrganizations } from '@/selectors/organizationSelectors';
import { getSelectableDepartments } from '@/selectors/departmentSelectors';
import { getSelectableUserTypesByCurrentUser } from '@/selectors/userSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { getErrors } from '@/selectors/errorSelectors';
import { MasterRoles } from '@/constants';
import './index.scss';

const { Title } = Typography;

class UserCreateContainer extends Component {
  componentDidMount() {
    const { dispatchFetchOrganizations, dispatchFetchDepartments } = this.props;

    dispatchFetchOrganizations({ includes: 'organizationGroups' });
    dispatchFetchDepartments();
  }

  handleSubmit = (values) => {
    const { dispatchCreateUser, location } = this.props;
    const submittedValues = cloneDeep(values);

    if (MasterRoles.includes(submittedValues.type)) {
      submittedValues.departmentIds = null;
    }

    dispatchCreateUser(submittedValues, {
      backUrl: location.state.backUrl,
    });
  }

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      errors,
      loading,
      submitting,
      selectableUserTypes,
      selectableOrganizations,
      selectableDepartments,
      currentUser,
    } = this.props;

    return (
      <LayoutContainer contentClassName={['content-container', 'user-create-container']}>
        <Row>
          <Col xs={24} xl={16}>
            <Title level={2} className="content-container__title">
              Create New User
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'users', link: '/users', title: 'Users' },
                { key: 'create', title: 'Create New User' },
              ]}
              className="content-container__breadcrumb"
            />
            {loading ? (
              <Spin />
            ) : (
              <UserForm
                submitting={submitting}
                submitTitle='Create User'
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                formObject={{
                  id: null,
                  email: '',
                  firstName: '',
                  lastName: '',
                  disabled: 0,
                  organizationId: currentUser.organizationId || null,
                  organizationGroupId: null,
                  departmentIds: [],
                  type: null,
                }}
                formErrors={errors}
                typeSelectOptions={selectableUserTypes}
                organizationSelectOptions={selectableOrganizations}
                departmentSelectOptions={selectableDepartments}
                canSelectOrganization={!currentUser.organizationId}
              />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    );
  }
}

UserCreateContainer.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  selectableOrganizations: PropTypes.arrayOf(PropTypes.object),
  selectableUserTypes: PropTypes.arrayOf(PropTypes.string),
  dispatchFetchOrganizations: PropTypes.func,
  dispatchFetchDepartments: PropTypes.func,
  dispatchCreateUser: PropTypes.func,
};

const loadingData = createRequestLoadingSelector(['fetchOrganizations', 'fetchDepartments']);
const submittingUser = createRequestLoadingSelector(['createUser']);

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  loading: loadingData(state),
  submitting: submittingUser(state),
  selectableUserTypes: getSelectableUserTypesByCurrentUser(state),
  selectableOrganizations: getSelectableContractorOrganizations(state),
  selectableDepartments: getSelectableDepartments(state),
  currentUser: getCurrentUser(state),
});

export default withRouter(
  connect(mapStateToProps, {
    dispatchFetchOrganizations: fetchOrganizations,
    dispatchFetchDepartments: fetchDepartments,
    dispatchCreateUser: createUser,
  })(UserCreateContainer),
);
