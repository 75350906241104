import { Roles } from '@/constants';

export const canViewGoal = (user) => {
  return [
    Roles.Admin,
    Roles.Trainer,
    Roles.Owner,
    Roles.Manager,
    Roles.Employee,
    Roles.Sales,
  ].includes(user?.type);
};

export const canCreateGoal = (user) => {
  return [
    Roles.Admin,
    Roles.Trainer,
    Roles.Owner,
    Roles.Manager,
    Roles.Employee,
    Roles.Sales,
  ].includes(user?.type);
};

export const canUpdateGoal = (user) => {
  return [
    Roles.Admin,
    Roles.Trainer,
    Roles.Owner,
    Roles.Manager,
    Roles.Employee,
    Roles.Sales,
  ].includes(user?.type);
};
