import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Table, Button, Form, Input, Select, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Breadcrumb from '@/components/Breadcumb';
import LayoutContainer from '@/containers/LayoutContainer';
import { fetchJobTypes } from '@/actions/jobTypeActions';
import { fetchDepartments } from '@/actions/departmentActions';
import { getJobTypes } from '@/selectors/jobTypeSelectors';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getSelectableDepartments } from '@/selectors/departmentSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { canCreateJobType } from '@/policies/jobTypePolicies';
import { selectSearchByChildrenText } from '@/utils/selectUtils';
import { displayDateTime } from '@/utils/dateUtils';
import './index.scss';

const { Option } = Select;
const { Title } = Typography;

const jobTypeColumns = (departments) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
    render: (id) => {
      return <Link to={`/job-types/${id}/edit`}>#{id}</Link>;
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Departments',
    dataIndex: 'departmentIds',
    key: 'departmentIds',
    render: (departmentIds = []) => {
      const departmentNameMaper = departments.reduce((memo, item) => ({ ...memo, [item.id]: item.name }), {});
      return departmentIds?.map((departmentId) => departmentNameMaper[departmentId])?.join(', ');
    },
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => {
      return createdAt ? displayDateTime(createdAt) : null;
    },
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (updatedAt) => {
      return updatedAt ? displayDateTime(updatedAt) : null;
    },
  },
];

class JobTypeListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterName: null,
      filterDeparmentId: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { dispatchFetchDepartments, dispatchFetchJobTypes } = this.props;

    dispatchFetchDepartments();
    dispatchFetchJobTypes('createdAt', 'DESC');
  }

  handleSearch = ({ name, departmentId }) => {
    this.setState({
      filterName: name,
      filterDeparmentId: departmentId,
    });
  };

  handleResetForm = () => {
    this.formRef.current.resetFields();
    this.setState({
      filterName: null,
      filterDeparmentId: null,
    });
  };

  filterItems = (items, filterName, filterDeparmentId) => {
    let result = items;

    // filter by name
    if (filterName) {
      result = result.filter(({ name = '' }) => {
        const loweredName = name.toLowerCase();
        const loweredFilterName = filterName.trim().toLowerCase();

        return loweredName.includes(loweredFilterName);
      });
    }

    // filter by department
    if (filterDeparmentId) {
      result = result.filter(({ departmentIds }) => {
        return departmentIds?.map((departmentId) => `${departmentId}`)?.includes(`${filterDeparmentId}`);
      });
    }

    return result;
  };

  render() {
    const { filterName, filterDeparmentId} = this.state;
    const { loading, items, currentUser, selectableDepartments } = this.props;
    const filteredItems = this.filterItems(items, filterName, filterDeparmentId);

    return (
      <LayoutContainer contentClassName={['content-container', 'jobtype-list-container']}>
        <Row gutter={16} align="middle">
          <Col flex="1">
            <Title level={2} className="content-container__title">
              Job Types
            </Title>
            <Breadcrumb
              items={[
                { key: 'home', link: '/', title: 'Home' },
                { key: 'job-types', link: '/job-types', title: 'Job Types' },
                { key: 'list', title: 'List' },
              ]}
              className="content-container__breadcrumb"
            />
          </Col>
          <Col flex="0">
            {canCreateJobType(currentUser) && (
              <Link to="/job-types/new">
                <Button type="primary" icon={<PlusOutlined />}>
                  Create New Job Type
                </Button>
              </Link>
            )}
          </Col>
        </Row>
        <Form
          ref={this.formRef}
          layout="vertical"
          className="content-container__search-form"
          onFinish={this.handleSearch}
          colon={false}
          hideRequiredMark
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                name="name"
                label="Name"
              >
                <Input placeholder="Type Job Type Name" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="departmentId"
                label="Department"
                hasFeedback
              >
                <Select
                  showSearch
                  placeholder="Select department"
                  optionFilterProp="children"
                  filterOption={selectSearchByChildrenText}
                >
                  {selectableDepartments.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Space
                style={{
                  marginTop: 33.14,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
                <Button htmlType="button" onClick={this.handleResetForm}>
                  Reset
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        <div className="content-container__section">
          <Table
            rowKey="id"
            dataSource={filteredItems}
            columns={jobTypeColumns(selectableDepartments)}
            loading={loading}
            pagination={{
              total: filteredItems.length,
              defaultPageSize: 50,
              defaultCurrent: 1,
            }}
            scroll={{
              x: 'max-content',
            }}
          />
        </div>
      </LayoutContainer>
    );
  }
}

JobTypeListContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  currentUser: PropTypes.object,
  selectableDepartments: PropTypes.arrayOf(PropTypes.object),
  dispatchFetchDepartments: PropTypes.func,
};

const loadingData = createRequestLoadingSelector(['fetchDepartments', 'fetchJobTypes']);

const mapStateToProps = (state) => ({
  items: getJobTypes(state),
  loading: loadingData(state),
  currentUser: getCurrentUser(state),
  selectableDepartments: getSelectableDepartments(state),
});

export default connect(mapStateToProps, {
  dispatchFetchJobTypes: fetchJobTypes,
  dispatchFetchDepartments: fetchDepartments,
})(JobTypeListContainer);
