import moment from 'moment';

export const parseDate = (dateJSON) => {
  return moment(dateJSON);
};

export const displayDateTime = (dateJSON) => {
  return parseDate(dateJSON).format('YYYY-MM-DD, h:mm:ss A');
};

export const displayDate = (dateJSON) => {
  return parseDate(dateJSON).format('YYYY-MM-DD');
};

export const sortDescByDate = (items, dateKey) => {
  return items.sort(
    (a, b) => parseDate(b[dateKey]).unix() - parseDate(a[dateKey]).unix(),
  );
};

export const formatDateAsString = (date) => {
  return date.format('YYYY-MM-DD');
};
