import { Roles } from '@/constants';

export const canManageOpportunityType = (user) => {
  return [Roles.Admin].includes(user?.type);
};

export const canViewOpportunityType = (user) => {
  return canManageOpportunityType(user);
};

export const canCreateOpportunityType = (user) => {
  return canManageOpportunityType(user);
};

export const canUpdateOpportunityType = (user) => {
  return canManageOpportunityType(user);
};

export const canDeleteOpportunityType = (user) => {
  return canManageOpportunityType(user);
};
