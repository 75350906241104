import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Button, Space, Form, Input, InputNumber, Select, Checkbox, Radio } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { sortBy } from 'lodash';

import { selectSearchByChildrenText } from '@/utils/selectUtils';
import ErrorMessage from '@/components/ErrorMessage';

const { Option } = Select;
const { Title, Paragraph } = Typography;

const getDepartmentNamesByIds = (departments, ids) => {
  const departmentNameMapper = departments.reduce((memo, item) => {
    return { ...memo, [item.id]: item.name };
  }, {});
  return ids.map((id) => departmentNameMapper[id]).join(', ');
};

const tranformJobTypesWithDepartmentName = (jobTypes, departments) => {
  return sortBy(
    jobTypes.map((jt) => ({
      ...jt,
      name: `${jt.name} [${getDepartmentNamesByIds(departments, jt.departmentIds)}]`,
    })),
    'name',
  );
};

const tranformOpportunityTypesWithDepartmentName = (opportunityTypes, departments) => {
  return sortBy(
    opportunityTypes.map((ot) => ({
      ...ot,
      name: `${ot.name} [${getDepartmentNamesByIds(departments, ot.departmentIds)}]`,
    })),
    'name',
  );
};

const OrganizationForm = (props) => {
  const {
    submitting,
    submitTitle,
    formObject,
    formErrors,
    jobTypeSelectOptions,
    opportunityTypeSelectOptions,
    organizationDepartmentSelectOptions,
    parentOrganizationSelectOptions,
    onCancel,
    onSubmit,
  } = props;

  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);
  const [usedJobTypeSelectOptions, setUsedJobTypeSelectOptions] = useState([]);
  const [usedOpportunityTypeSelectOptions, setUsedOpportunityTypeSelectOptions] = useState([]);

  const selectedDepartmentIds = form.getFieldValue('departmentIds');
  const selectedHasRevenueThresholdDepartments = organizationDepartmentSelectOptions.filter(
    ({ id, hasRevenueThreshold }) => (selectedDepartmentIds || []).includes(`${id}`) && !!hasRevenueThreshold,
  );

  useEffect(() => {
    const jobTypes = tranformJobTypesWithDepartmentName(jobTypeSelectOptions, organizationDepartmentSelectOptions);
    setUsedJobTypeSelectOptions(jobTypes);
  }, [jobTypeSelectOptions, organizationDepartmentSelectOptions]);

  useEffect(() => {
    const opportunityTypes = tranformOpportunityTypesWithDepartmentName(
      opportunityTypeSelectOptions,
      organizationDepartmentSelectOptions,
    );
    setUsedOpportunityTypeSelectOptions(opportunityTypes);
  }, [opportunityTypeSelectOptions, organizationDepartmentSelectOptions]);

  useEffect(() => {
    if (!updated) {
      form.resetFields();
    }
  }, [form, formObject, updated]);

  const onValuesChange = (changedValues) => {
    setUpdated(true);

    if (changedValues.departmentIds) {
      const filteredJobTypeSelectOptions = changedValues.departmentIds.length
        ? jobTypeSelectOptions.filter(
            ({ departmentIds }) =>
              !departmentIds.length ||
              departmentIds.some((departmentId) =>
                changedValues.departmentIds.map((id) => `${id}`).includes(`${departmentId}`),
              ),
          )
        : jobTypeSelectOptions;
      const filteredOpportunityTypeSelectOptions = changedValues.departmentIds.length
        ? opportunityTypeSelectOptions.filter(
            ({ departmentIds }) =>
              !departmentIds.length ||
              departmentIds.some((departmentId) =>
                changedValues.departmentIds.map((id) => `${id}`).includes(`${departmentId}`),
              ),
          )
        : opportunityTypeSelectOptions;
      const transformedJobTypeSelectOptions = tranformJobTypesWithDepartmentName(
        filteredJobTypeSelectOptions,
        organizationDepartmentSelectOptions,
      );
      const transformedOpportunityTypeSelectOptions = tranformOpportunityTypesWithDepartmentName(
        filteredOpportunityTypeSelectOptions,
        organizationDepartmentSelectOptions,
      );
      setUsedJobTypeSelectOptions(transformedJobTypeSelectOptions);
      setUsedOpportunityTypeSelectOptions(transformedOpportunityTypeSelectOptions);
      form.setFieldsValue({
        jobTypeIds: formObject.jobTypeIds,
        opportunityTypeIds: formObject.opportunityTypeIds,
      });
    }
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      initialValues={formObject}
      onValuesChange={onValuesChange}
      colon={false}
      layout="vertical"
      className="content-container__form"
      hideRequiredMark
    >
      <ErrorMessage errors={formErrors} />
      <Paragraph>Please input the information below:</Paragraph>
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: 'Name is required',
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item name="departmentIds" label="Departments" hasFeedback>
        <Checkbox.Group>
          {organizationDepartmentSelectOptions.map(({ id, name }) => (
            <Checkbox value={`${id}`} key={id}>
              {name}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name="parentId"
        label="Distributor"
        rules={[{ required: true, message: 'Distributor is required' }]}
        hasFeedback
      >
        <Select
          showSearch
          placeholder="Select distributor"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
        >
          {parentOrganizationSelectOptions.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="jobTypeIds"
        label="Job Types"
        rules={[{ required: true, message: 'Job type is required' }]}
        hasFeedback
      >
        <Select
          showSearch
          mode="multiple"
          placeholder="Select job types"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
        >
          {usedJobTypeSelectOptions.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="opportunityTypeIds"
        label="Opportunity Types"
        rules={[{ required: true, message: 'Job type is required' }]}
        hasFeedback
      >
        <Select
          showSearch
          mode="multiple"
          placeholder="Select opportunity types"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
        >
          {usedOpportunityTypeSelectOptions.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {selectedHasRevenueThresholdDepartments.map((department) => (
        <Form.Item
          key={department.id}
          name={['revenueThreshold', `${department.id}`]}
          label={`Revenue Threshold For ${department.name} Project`}
          className="form-item-currency"
          hasFeedback
        >
          <InputNumber min={0} style={{ width: '100%' }} />
        </Form.Item>
      ))}
      <Form.Item name="accountNumber" label="SBE Account Number" hasFeedback>
        <Input placeholder="SBE Account Number" />
      </Form.Item>
      <Form.Item name="disabled" label="Status">
        <Radio.Group buttonStyle="solid" size="medium">
          <Radio.Button value={0} className="ant-radio-button-wrapper-green">
            Active
          </Radio.Button>
          <Radio.Button value={1} className="ant-radio-button-wrapper-red">
            Disabled
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Title level={4}>Subgroups</Title>
      <Form.List name="organizationGroups">
        {(fields, { add, remove }, { errors }) => (
          <React.Fragment>
            {fields.map((field) => (
              <React.Fragment key={field.key}>
                <Row gutter={16} key={field.key} align="start">
                  <Form.Item name={[field.name, 'id']}>
                    <Input type="hidden" />
                  </Form.Item>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name={[field.name, 'name']}
                      rules={[{ required: true, message: 'Name is required' }]}
                    >
                      <Input placeholder="Name" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <MinusCircleOutlined
                      style={{
                        color: '#ff4d4f',
                        marginTop: 42.5,
                      }}
                      onClick={() => remove(field.name)}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add({ name: '' })} icon={<PlusOutlined />}>
                Add Subgroup
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </React.Fragment>
        )}
      </Form.List>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {submitTitle || 'Submit'}
          </Button>
          {onCancel && (
            <Button htmlType="button" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

OrganizationForm.propTypes = {
  submitting: PropTypes.bool,
  submitTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  formObject: PropTypes.shape({
    name: PropTypes.string,
    parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    departments: PropTypes.arrayOf(PropTypes.number),
    jobTypeIds: PropTypes.arrayOf(PropTypes.number),
    opportunityTypeIds: PropTypes.arrayOf(PropTypes.number),
    organizationGroups: PropTypes.arrayOf(PropTypes.object),
    revenueThreshold: PropTypes.object,
    accountNumber: PropTypes.string,
  }),
  formErrors: PropTypes.arrayOf(PropTypes.string),
  jobTypeSelectOptions: PropTypes.arrayOf(PropTypes.object),
  opportunityTypeSelectOptions: PropTypes.arrayOf(PropTypes.object),
  organizationDepartmentSelectOptions: PropTypes.arrayOf(PropTypes.object),
  parentOrganizationSelectOptions: PropTypes.arrayOf(PropTypes.object),
};

export default OrganizationForm;
