import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';

export const getOpportunityTypes = (state) => {
  return state.opportunityType.items;
};

export const getTargetOpportunityType = (state) => {
  return state.opportunityType.target;
};

export const getSelectableOpportunityTypes = createSelector(getOpportunityTypes, (opportunityTypes) => {
  const usedOpportunityTypes = opportunityTypes.map(({ id, name, departmentIds }) => ({ id, name, departmentIds }));
  return sortBy(usedOpportunityTypes, (opportunityType) => opportunityType.name?.toLowerCase());
});
