import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'organization',
  initialState: {
    items: [],
    target: null,
    loading: false,
  },
  reducers: {
    setOrganizations(state, { payload: { items } }) {
      state.items = items;
    },
    setTargetOrganization(state, { payload: { target } }) {
      state.target = target;
    },
  },
});

export const {
  setOrganizations,
  setTargetOrganization,
} = slice.actions;

export default slice.reducer;
