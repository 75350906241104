/* eslint-disable max-len */
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, DatePicker, Button, Typography, Space, Table, Spin, Select } from 'antd';
import { round, compact, get, sortBy } from 'lodash';

import LayoutContainer from '@/containers/LayoutContainer';
import {
  MasterRoles,
  ManagerRoles,
  WorkerRoles,
  SERVICE_DEPARTMENT_ID,
  SALE_DEPARTMENT_ID,
  PLUMBING_DEPARTMENT_ID,
  ELECTRICAL_DEPARTMENT_ID,
} from '@/constants';
import { fetchKpis } from '@/actions/kpiActions';
import { fetchOwnedOrganizationRevenueWithCloseRate } from '@/actions/homeActions';
import { fetchOrganizations } from '@/actions/organizationActions';
import { getKpis } from '@/selectors/kpiSelectors';
import { getCurrentUser } from '@/selectors/authSelectors';
import { getSelectableContractorOrganizations } from '@/selectors/organizationSelectors';
import { createRequestLoadingSelector } from '@/selectors/requestSelectors';
import { displayCurrency, displayPercent } from '@/utils/numberUtils';
import { selectSearchByChildrenText } from '@/utils/selectUtils';
import { formatDateAsString } from '@/utils/dateUtils';
import './index.scss';

const { Title } = Typography;
const { Option } = Select;

const serviceColumns = ({ includeYourCompany = true }) =>
  compact([
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
    },
    includeYourCompany
      ? {
          title: 'Your Company',
          dataIndex: 'avgYou',
          key: 'avgYou',
          width: '25%',
        }
      : null,
    {
      title: 'KPI',
      dataIndex: 'avgKpi',
      key: 'avgKpi',
      width: '25%',
    },
  ]);

const serviceCloseRateColumns = ({ includeYourCompany = true }) =>
  compact([
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
    },
    includeYourCompany
      ? {
          title: 'Your Company',
          dataIndex: 'avgYou',
          key: 'avgYou',
          width: '25%',
        }
      : null,
    {
      title: 'KPI',
      dataIndex: 'avgKpi',
      key: 'avgKpi',
      width: '25%',
    },
  ]);

const saleColumns = ({ includeYourCompany = true }) =>
  compact([
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
    },
    includeYourCompany
      ? {
          title: 'Your Company',
          dataIndex: 'avgYou',
          key: 'avgYou',
          width: '25%',
        }
      : null,
    {
      title: 'KPI',
      dataIndex: 'avgKpi',
      key: 'avgKpi',
      width: '25%',
    },
  ]);

const plumbingColumns = ({ includeYourCompany = true }) =>
  compact([
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
    },
    includeYourCompany
      ? {
          title: 'Your Company',
          dataIndex: 'avgYou',
          key: 'avgYou',
          width: '25%',
        }
      : null,
    {
      title: 'KPI',
      dataIndex: 'avgKpi',
      key: 'avgKpi',
      width: '25%',
    },
  ]);

const electricalColumns = ({ includeYourCompany = true }) =>
  compact([
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      width: '25%',
    },
    includeYourCompany
      ? {
          title: 'Your Company',
          dataIndex: 'avgYou',
          key: 'avgYou',
          width: '25%',
        }
      : null,
    {
      title: 'KPI',
      dataIndex: 'avgKpi',
      key: 'avgKpi',
      width: '25%',
    },
  ]);

const startDateOfYear = moment().startOf('year');
const currentDate = moment();

class HomeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      revenueAndCloseRate: [],
      loadingRevenueAndCloseRate: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const {
      currentUser,
      dispatchFetchOrganizations,
      dispatchFetchKpis,
    } = this.props;

    dispatchFetchOrganizations();
    dispatchFetchKpis();

    this.fetchRevenueAndCloseRate({
      organizationId: currentUser.organizationId,
      fromDate: formatDateAsString(startDateOfYear),
      toDate: formatDateAsString(currentDate),
    });
  }

  fetchRevenueAndCloseRate = async ({ organizationId, fromDate, toDate }) => {
    this.setState({
      loadingRevenueAndCloseRate: true,
    });

    try {
      const revenueAndCloseRate = organizationId
        ? await fetchOwnedOrganizationRevenueWithCloseRate({
            organizationId,
            fromDate,
            toDate,
          })
        : [];
      this.setState({
        revenueAndCloseRate,
        loadingRevenueAndCloseRate: false,
      });
    } catch (error) {
      this.setState({
        loadingRevenueAndCloseRate: false,
      });
      console.error(error);
      throw error;
    }
  }

  getOrganizations = () => {
    const { currentUser, organizations } = this.props;

    if (!organizations) {
      return [];
    }
    
    if (!currentUser.organizationId) {
      return sortBy(organizations, (organization) => organization.name?.toLowerCase());
    }

    if (currentUser.type === 'Distributor') {
      return organizations.filter(({ parentId }) => `${parentId}` === `${currentUser.organizationId}`);
    } 

    return organizations.filter(({ id }) => `${id}` === `${currentUser.organizationId}`);
  };

  handleSearch = (values) => {
    const { organizationId, fromDate, toDate } = values;

    this.fetchRevenueAndCloseRate({
      organizationId,
      fromDate: fromDate ? formatDateAsString(fromDate) : null,
      toDate: toDate ? formatDateAsString(toDate) : null,
    });
  }

  handleResetForm = () => {
    const { currentUser } = this.props;
    this.formRef.current.setFieldsValue({
      organizationId: currentUser?.organizationId,
      fromDate: startDateOfYear,
      toDate: currentDate,
    });
    this.fetchRevenueAndCloseRate({
      organizationId: currentUser.organizationId,
      fromDate: formatDateAsString(startDateOfYear),
      toDate: formatDateAsString(currentDate),
    });
  }

  getServiceData = () => {
    const { kpis } = this.props;
    const { revenueAndCloseRate } = this.state;
    const data = revenueAndCloseRate.find(
      ({ departmentId, departmentName }) => departmentName === 'Service' || `${departmentId}` === SERVICE_DEPARTMENT_ID,
    )?.revenue;
    const getAvgRevenueOfJobType = (listData = [], jobTypeName = null) => {
      return listData.find((item) => item.jobTypeName === jobTypeName)?.avgRevenue || 0;
    };


    return [
      {
        title: 'Avg Service Ticket',
        avgYou: displayCurrency(data?.avgServiceRevenue || 0),
        avgKpi: kpis.service_avg_service_ticket && displayCurrency(round(kpis.service_avg_service_ticket, 2)),
      },
      {
        title: 'Avg Demand Ticket',
        avgYou: displayCurrency(getAvgRevenueOfJobType(data?.byJobType, 'Demand Service')),
        avgKpi: kpis.service_avg_demand_ticket && displayCurrency(round(kpis.service_avg_demand_ticket, 2)),
      },
      {
        title: 'Avg Maintenance Ticket',
        avgYou: displayCurrency(getAvgRevenueOfJobType(data?.byJobType, 'Maintenance Agreement')),
        avgKpi: kpis.service_avg_maintenance_ticket && displayCurrency(round(kpis.service_avg_maintenance_ticket, 2)),
      },
      {
        title: 'Avg Tune Up Ticket',
        avgYou: displayCurrency(getAvgRevenueOfJobType(data?.byJobType, 'Tune-up')),
        avgKpi: kpis.service_avg_tune_up_ticket && displayCurrency(round(kpis.service_avg_tune_up_ticket, 2)),
      },
      {
        title: 'Avg Replacement Ticket',
        avgYou: displayCurrency(data?.avgReplaceRevenue || 0),
        avgKpi: kpis.service_avg_replacement_ticket && displayCurrency(round(kpis.service_avg_replacement_ticket, 2)),
      },
      {
        title: 'Avg Truck Revenue',
        avgYou: displayCurrency(data?.avgTruckRevenue || 0),
        avgKpi: kpis.service_avg_truck_revenue && displayCurrency(round(kpis.service_avg_truck_revenue, 2)),
      },
    ];
  };

  getServiceCloseRateData = () => {
    const { kpis } = this.props;
    const { revenueAndCloseRate } = this.state;
    const data = revenueAndCloseRate.find(
      ({ departmentId, departmentName }) => departmentName === 'Service' || `${departmentId}` === SERVICE_DEPARTMENT_ID,
    )?.closeRate;
    const getAvgRateOfOpportunityType = (listData = [], opportunityTypeName = null, attribute = 'rate') => {
      const dt = listData.find((item) => item.opportunityTypeName === opportunityTypeName);
      return get(dt, attribute) || 0;
    };

    return [
      {
        title: 'Avg Opportunity Close Rate',
        avgYou: displayPercent(getAvgRateOfOpportunityType(data?.byOpportunityType, 'New System', 'opportunityRate')),
        avgKpi: kpis.service_opportunity_close_rate && displayPercent(round(kpis.service_opportunity_close_rate / 100, 2)),
      },
      {
        title: 'Avg Maintenance Close Rate',
        avgYou: displayPercent(getAvgRateOfOpportunityType(data?.byOpportunityType, 'Agreement')),
        avgKpi: kpis.service_avg_maintenance_close_rate && displayPercent(round(kpis.service_avg_maintenance_close_rate / 100, 2)),
      },
      {
        title: 'Avg IAQ Close Rate',
        avgYou: displayPercent(getAvgRateOfOpportunityType(data?.byOpportunityType, 'IAQ')),
        avgKpi: kpis.service_iaq_close_rate && displayPercent(round(kpis.service_iaq_close_rate / 100, 2)),
      },
      {
        title: 'Avg Duct Cleaning Close Rate',
        avgYou: displayPercent(getAvgRateOfOpportunityType(data?.byOpportunityType, 'Duct Cleaning')),
        avgKpi: kpis.service_duct_cleaning_close_rate && displayPercent(round(kpis.service_duct_cleaning_close_rate / 100, 2)),
      },
      {
        title: 'Avg Water Heater Close Rate',
        avgYou: displayPercent(getAvgRateOfOpportunityType(data?.byOpportunityType, 'Water Heaters')),
        avgKpi: kpis.service_water_heater_close_rate && displayPercent(round(kpis.service_water_heater_close_rate / 100, 2)),
      },
      {
        title: 'Avg Generator Close Rate',
        avgYou: displayPercent(getAvgRateOfOpportunityType(data?.byOpportunityType, 'Generators')),
        avgKpi: kpis.service_generator_close_rate && displayPercent(round(kpis.service_generator_close_rate / 100, 2)),
      },
    ];
  };

  getSaleData = () => {
    const { kpis } = this.props;
    const { revenueAndCloseRate } = this.state;
    const revenueData = revenueAndCloseRate.find(
      ({ departmentId, departmentName }) => departmentName === 'Sales' || `${departmentId}` === SALE_DEPARTMENT_ID,
    )?.revenue;
    const closeRateData = revenueAndCloseRate.find(
      ({ departmentId, departmentName }) => departmentName === 'Sales' || `${departmentId}` === SALE_DEPARTMENT_ID,
    )?.closeRate;
    const getAvgRateOfOpportunityType = (listData = [], opportunityTypeName = null, attribute = 'rate') => {
      const dt = listData?.find((item) => item.opportunityTypeName === opportunityTypeName);
      return get(dt, attribute) || 0;
    };

    return [
      {
        title: 'Avg Replacement Ticket',
        avgYou: displayCurrency(revenueData?.avgReplaceRevenue || 0),
        avgKpi: kpis.sales_avg_replacement_ticket && displayCurrency(round(kpis.sales_avg_replacement_ticket, 2)),
      },
      {
        title: 'Avg Close Rate New Systems (Cold Lead)',
        avgYou: displayPercent(getAvgRateOfOpportunityType(closeRateData?.byOpportunityType, 'New System', 'coldLeadRate')),
        avgKpi: kpis.sales_avg_close_rate_new_systems_cold_lead &&
          displayPercent(round(kpis.sales_avg_close_rate_new_systems_cold_lead / 100, 2)),
      },
      {
        title: 'Avg Close Rate New Systems (Turn Over)',
        avgYou: displayPercent(getAvgRateOfOpportunityType(closeRateData?.byOpportunityType, 'New System', 'turnOverRate')),
        avgKpi: kpis.sales_avg_close_rate_new_systems_turn_over &&
          displayPercent(round(kpis.sales_avg_close_rate_new_systems_turn_over / 100, 2)),
      },
      {
        title: 'Avg Close Rate Water Heaters',
        avgYou: displayPercent(getAvgRateOfOpportunityType(closeRateData?.byOpportunityType, 'Water Heaters')),
        avgKpi: kpis.sales_avg_close_rate_water_heaters && displayPercent(round(kpis.sales_avg_close_rate_water_heaters / 100, 2)),
      },
      {
        title: 'Avg Close Rate Generators',
        avgYou: displayPercent(getAvgRateOfOpportunityType(closeRateData?.byOpportunityType, 'Generators')),
        avgKpi: kpis.sales_avg_close_rate_generators && displayPercent(round(kpis.sales_avg_close_rate_generators / 100, 2)),
      },
      {
        title: 'Avg Close Rate Duct Modifications',
        avgYou: displayPercent(getAvgRateOfOpportunityType(closeRateData?.byOpportunityType, 'Ductwork Replacement/Modification')),
        avgKpi:
          kpis.sales_avg_close_rate_duct_modifications &&
          displayPercent(round(kpis.sales_avg_close_rate_duct_modifications / 100, 2)),
      },
      {
        title: 'Avg Close Rate Duct Cleaning',
        avgYou: displayPercent(getAvgRateOfOpportunityType(closeRateData?.byOpportunityType, 'Duct Cleaning')),
        avgKpi: kpis.sales_avg_close_rate_duct_cleaning && displayPercent(round(kpis.sales_avg_close_rate_duct_cleaning / 100, 2)),
      },
    ];
  };

  getPlumbingData = () => {
    const { kpis } = this.props;
    const { revenueAndCloseRate } = this.state;
    const data = revenueAndCloseRate.find(
      ({ departmentId, departmentName }) => departmentName === 'Plumbing' || `${departmentId}` === PLUMBING_DEPARTMENT_ID,
    )?.revenue;
    const getAvgRevenueOfJobType = (listData = [], jobTypeName = null) => {
      return listData.find((item) => item.jobTypeName === jobTypeName)?.avgRevenue || 0;
    };

    return [
      {
        title: 'Avg Service Ticket',
        avgYou: displayCurrency(data?.avgServiceRevenue || 0),
        avgKpi: kpis.plumbing_avg_service_ticket && displayCurrency(round(kpis.plumbing_avg_service_ticket, 2)),
      },
      {
        title: 'Avg Demand Ticket',
        avgYou: displayCurrency(getAvgRevenueOfJobType(data?.byJobType, 'Demand Service')),
        avgKpi: kpis.plumbing_avg_demand_ticket && displayCurrency(round(kpis.plumbing_avg_demand_ticket, 2)),
      },
      {
        title: 'Avg Maintenance Ticket',
        avgYou: displayCurrency(getAvgRevenueOfJobType(data?.byJobType, 'Maintenance Agreement')),
        avgKpi: kpis.plumbing_avg_maintenance_ticket && displayCurrency(round(kpis.plumbing_avg_maintenance_ticket, 2)),
      },
      {
        title: 'Avg Tune Up Ticket',
        avgYou: displayCurrency(getAvgRevenueOfJobType(data?.byJobType, 'Tune-up')),
        avgKpi: kpis.plumbing_avg_tune_up_ticket && displayCurrency(round(kpis.plumbing_avg_tune_up_ticket, 2)),
      },
      {
        title: 'Avg Truck Revenue',
        avgYou: displayCurrency(data?.avgTruckRevenue || 0),
        avgKpi: kpis.plumbing_avg_truck_revenue && displayCurrency(round(kpis.plumbing_avg_truck_revenue, 2)),
      },
      {
        title: 'Avg Project Ticket',
        avgYou: displayCurrency(getAvgRevenueOfJobType(data?.byJobType, 'New Project')),
        avgKpi: kpis.plumbing_avg_project_ticket && displayCurrency(round(kpis.plumbing_avg_project_ticket, 2)),
      },
    ];
  };

  getElectricalData = () => {
    const { kpis } = this.props;
    const { revenueAndCloseRate } = this.state;
    const data = revenueAndCloseRate.find(
      ({ departmentId, departmentName }) => departmentName === 'Electrical' || `${departmentId}` === ELECTRICAL_DEPARTMENT_ID,
    )?.revenue;
    const getAvgRevenueOfJobType = (listData = [], jobTypeName = null) => {
      return listData.find((item) => item.jobTypeName === jobTypeName)?.avgRevenue || 0;
    };

    return [
      {
        title: 'Avg Service Ticket',
        avgYou: displayCurrency(data?.avgServiceRevenue || 0),
        avgKpi: kpis.electrical_avg_service_ticket && displayCurrency(round(kpis.electrical_avg_service_ticket, 2)),
      },
      {
        title: 'Avg Demand Ticket',
        avgYou: displayCurrency(getAvgRevenueOfJobType(data?.byJobType, 'Demand Service')),
        avgKpi: kpis.electrical_avg_demand_ticket && displayCurrency(round(kpis.electrical_avg_demand_ticket, 2)),
      },
      {
        title: 'Avg Maintenance Ticket',
        avgYou: displayCurrency(getAvgRevenueOfJobType(data?.byJobType, 'Maintenance Agreement')),
        avgKpi: kpis.electrical_avg_maintenance_ticket && displayCurrency(round(kpis.electrical_avg_maintenance_ticket, 2)),
      },
      {
        title: 'Avg Tune Up Ticket',
        avgYou: displayCurrency(getAvgRevenueOfJobType(data?.byJobType, 'Tune-up')),
        avgKpi: kpis.electrical_avg_tune_up_ticket && displayCurrency(round(kpis.electrical_avg_tune_up_ticket, 2)),
      },
      {
        title: 'Avg Truck Revenue',
        avgYou: displayCurrency(data?.avgTruckRevenue || 0),
        avgKpi: kpis.electrical_avg_truck_revenue && displayCurrency(round(kpis.electrical_avg_truck_revenue, 2)),
      },
      {
        title: 'Avg Project Ticket',
        avgYou: displayCurrency(getAvgRevenueOfJobType(data?.byJobType, 'New Project')),
        avgKpi: kpis.electrical_avg_project_ticket && displayCurrency(round(kpis.electrical_avg_project_ticket, 2)),
      },
    ];
  };

  render() {
    const { currentUser, loading } = this.props;
    const { loadingRevenueAndCloseRate } = this.state;
    const targetOrganizationId = this.formRef.current?.getFieldValue('organizationId');
    const includeYourCompany = !!targetOrganizationId;

    const isWorker = WorkerRoles.includes(currentUser.type);
    const isMasterOrManager = MasterRoles.includes(currentUser.type) || [...MasterRoles, ...ManagerRoles].includes(currentUser.type);
    const shouldShowServiceData = isMasterOrManager || (isWorker && currentUser.departmentIds?.includes(SERVICE_DEPARTMENT_ID));
    const shouldShowSaleData = isMasterOrManager || (isWorker && currentUser.departmentIds?.includes(SALE_DEPARTMENT_ID));
    const shouldShowPlumbingData = isMasterOrManager || (isWorker && currentUser.departmentIds?.includes(PLUMBING_DEPARTMENT_ID));
    const shouldShowElectricalData = isMasterOrManager || (isWorker && currentUser.departmentIds?.includes(ELECTRICAL_DEPARTMENT_ID));

    return (
      <LayoutContainer contentClassName={['content-container', 'home-container']}>
        <Title level={2} className="content-container__title">
          Home
        </Title>
        <Form
          name="dashboard-search"
          layout="vertical"
          className="content-container__search-form"
          onFinish={this.handleSearch}
          initialValues={{
            organizationId: currentUser?.organizationId,
            fromDate: startDateOfYear,
            toDate: currentDate,
          }}
          ref={this.formRef}
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item name="organizationId" label="Contractor">
                <Select
                  showSearch
                  placeholder="Select Contractor"
                  optionFilterProp="children"
                  filterOption={selectSearchByChildrenText}
                  loading={loading}
                >
                  {!currentUser.organizationId && <Option value={null}>All</Option>}
                  {this.getOrganizations().map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="fromDate"
                label="From Date"
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'From date is required',
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="toDate"
                label="To Date"
                style={{ marginBottom: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'From date is required',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, toDate) {
                      const fromDate = getFieldValue('fromDate');
                      if (fromDate && toDate && toDate < fromDate) {
                        return Promise.reject(new Error('To date must be greater than or equal to from date'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Space
                style={{
                  marginTop: 33.14,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
                <Button htmlType="button" onClick={this.handleResetForm}>
                  Reset
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        {loading || loadingRevenueAndCloseRate ? (
          <Spin />
        ) : (
          <>
            {shouldShowServiceData && (
              <div className="content-container__section">
                <Title level={4} className="content-container__subtitle">
                  Service
                </Title>
                <Table
                  rowKey="title"
                  className="content-container__table"
                  dataSource={this.getServiceData()}
                  columns={serviceColumns({ includeYourCompany })}
                  pagination={false}
                  scroll={{
                    x: 'max-content',
                  }}
                />
              </div>
            )}
            {shouldShowServiceData && (
              <div className="content-container__section">
                <Title level={4} className="content-container__subtitle">
                  Service Close Rates
                </Title>
                <Table
                  rowKey="title"
                  className="content-container__table"
                  dataSource={this.getServiceCloseRateData()}
                  columns={serviceCloseRateColumns({ includeYourCompany })}
                  pagination={false}
                  scroll={{
                    x: 'max-content',
                  }}
                />
              </div>
            )}
            {shouldShowSaleData && (
              <div className="content-container__section">
                <Title level={4} className="content-container__subtitle">
                  Sales
                </Title>
                <Table
                  rowKey="title"
                  className="content-container__table"
                  dataSource={this.getSaleData()}
                  columns={saleColumns({ includeYourCompany })}
                  pagination={false}
                />
              </div>
            )}
            {shouldShowPlumbingData && (
              <div className="content-container__section">
                <Title level={4} className="content-container__subtitle">
                  Plumbing
                </Title>
                <Table
                  rowKey="title"
                  className="content-container__table"
                  dataSource={this.getPlumbingData()}
                  columns={plumbingColumns({ includeYourCompany })}
                  pagination={false}
                  scroll={{
                    x: 'max-content',
                  }}
                />
              </div>
            )}
            {shouldShowElectricalData && (
              <div className="content-container__section">
                <Title level={4} className="content-container__subtitle">
                  Electrical
                </Title>
                <Table
                  rowKey="title"
                  className="content-container__table"
                  dataSource={this.getElectricalData()}
                  columns={electricalColumns({ includeYourCompany })}
                  pagination={false}
                  scroll={{
                    x: 'max-content',
                  }}
                />
              </div>
            )}
          </>
        )}
      </LayoutContainer>
    );
  }
}

HomeContainer.propTypes = {
  kpis: PropTypes.object,
  loading: PropTypes.bool,
  currentUser: PropTypes.object,
  dispatchFetchOrganizations: PropTypes.func,
  dispatchFetchKpis: PropTypes.func,
};

const loadingData = createRequestLoadingSelector(['fetchKpis', 'fetchOrganizations']);

const mapStateToProps = (state) => ({
  loading: loadingData(state),
  currentUser: getCurrentUser(state),
  organizations: getSelectableContractorOrganizations(state),
  kpis: getKpis(state),
});

export default connect(mapStateToProps, {
  dispatchFetchOrganizations: fetchOrganizations,
  dispatchFetchKpis: fetchKpis,
})(HomeContainer);
