import { notification } from 'antd';
import api from '@/api';
import history from '@/history';
import { setJobs, setFilters, setFilteredOrganizationUsers, setTargetJob, setTargetOrganization } from '@/store/job';
import { doRequest, finishRequest } from '@/store/request';
import { createErrors } from '@/actions/errorActions';
import { transformErrors } from '@/utils/errorUtils';

const sortOrderTranfomer = {
  'ascend': 'ASC',
  'descend': 'DESC',
};

export const fetchSelectableOrganizationUsers = (organizationId) => async (dispatch, getState) => {
  dispatch(doRequest('fetchSelectableOrganizationUsers'));

  try {
    const { data: users } = await api.get(`/organizations/${organizationId}/users`);
    dispatch(setFilteredOrganizationUsers({ users }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchSelectableOrganizationUsers'));
  }
};

export const fetchJobs = ({
  organizationId,
  userId,
  fromDate,
  toDate,
  page,
  limit,
  sort,
  order,
}) => async (dispatch) => {
  dispatch(doRequest('fetchJobs'));

  try {
    let params = {
      userId,
      fromDate,
      toDate,
      limit,
      page,
    };
    if (sort && order) {
      params = { ...params, sort, order: sortOrderTranfomer[order] };
    }
    const { data } = await api.get('/jobs', { params });
    dispatch(
      setJobs({
        items: data.items,
        totalCount: data.meta?.totalItems,
        filteredOrganizationId: organizationId,
        filteredUserId: userId,
        filteredFromDate: fromDate,
        filteredToDate: toDate,
        page: data.meta?.currentPage,
        limit: data.meta?.itemsPerPage,
        sort,
        order,
      }),
    );
  } catch(error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchJobs'));
  }
};

export const fetchTargetJob = (id) => async (dispatch) => {
  dispatch(doRequest('fetchTargetJob'));

  try {
    const { data: job } = await api.get(`/jobs/${id}`);
    dispatch(setTargetJob({ target: job }));
  } catch(error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchTargetJob'));
  }
};

export const fetchJobTargetOrganization = (organizationId) => async (dispatch) => {
  dispatch(doRequest('fetchJobTargetOrganization'));

  try {
    const [
      dataOrganization,
      dataOrganizationUsers,
    ] = await Promise.all([
      api.get(`/organizations/${organizationId}`),
      await api.get(`/organizations/${organizationId}/users`),
    ]);
    const organization = {
      ...dataOrganization.data,
      users: dataOrganizationUsers.data,
    };
    dispatch(setTargetOrganization({ targetOrganization: organization }));
  } catch(error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchJobTargetOrganization'));
  }
};

export const createJob = (data, options = {}) => async (dispatch) => {
  dispatch(doRequest('createJob'));

  try {
    await api.post('/jobs', data);
    notification.success({
      message: 'Create job',
      description: 'Created job successfully',
    });
    const nextUrl = options.backUrl || '/jobs';
    history.push(nextUrl);
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('createJob'));
  }
};

export const updateJob = (id, data, options = {}) => async (dispatch) => {
  dispatch(doRequest('updateJob'));

  try {
    await api.put(`/jobs/${id}`, data);
    dispatch(setTargetJob({ target: null }));

    notification.success({
      message: 'Update job',
      description: 'Updated job successfully',
    });
    const nextUrl = options.backUrl || '/jobs';
    history.push(nextUrl);
  } catch(error) {
    const errors = transformErrors(error.response?.data?.errors || {});
    dispatch(createErrors(errors));
    console.error(error);
  } finally {
    dispatch(finishRequest('updateJob'));
  }
};

export const deleteJob = (id, options = {}) => async (dispatch) => {
  dispatch(doRequest('deleteJob'));

  try {
    await api.delete(`/jobs/${id}`);
    dispatch(setTargetJob({ target: null }));

    notification.success({
      message: 'Delete job',
      description: 'Deleted job successfully',
    });
    const nextUrl = options.backUrl || '/jobs';
    history.push(nextUrl);
  } catch (error) {
    notification.error({
      message: 'Delete job',
      description: 'Unable to delete job',
    });
    console.error(error);
  } finally {
    dispatch(finishRequest('deleteJob'));
  }
};

export const resetJobListAndFilter = () => async (dispatch) => {
  dispatch(
    setJobs({
      items: [],
      page: 1,
      limit: 50,
      sort: null,
      order: null,
      totalCount: 0,
      filteredOrganizationId: null,
      filteredUserId: null,
      filteredFromDate: null,
      filteredToDate: null,
    }),
  );
  dispatch(setFilteredOrganizationUsers({ users: [] }));
  dispatch(
    setFilters({
      filteredOrganizationId: null,
      filteredUserId: null,
      filteredFromDate: null,
      filteredToDate: null,
    }),
  );
};
