import api from '@/api';
import { doRequest, finishRequest } from '@/store/request';
import { setDepartments } from '@/store/department';

export const fetchDepartments = (sort = null, order = null) => async (dispatch) => {
  dispatch(doRequest('fetchDepartments'));

  try {
    const { data: items } = await api.get('/departments');
    dispatch(setDepartments({ items }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(finishRequest('fetchDepartments'));
  }
};
