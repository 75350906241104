import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Row, Col, Typography, Table, Input, Tag, Space, Select } from 'antd';
import { range, kebabCase, isNil } from 'lodash';
import moment from 'moment';

import Breadcrumb from '@/components/Breadcumb';
import LayoutContainer from '@/containers/LayoutContainer';
import {
  fetchTechnicianRankings,
  fetchComfortConsultantRankings,
  fetchPlumberRankings,
  fetchElectricianRankings,
} from '@/actions/rankingActions';
import './index.scss';

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;

const sortRankNumber = (attribute) => (a, b) => a[attribute] - b[attribute];

const sortString = (attribute) => {
  return (a, b) => {
    const aValue = (a[attribute] || '').toLowerCase();
    const bValue = (b[attribute] || '').toLowerCase();

    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  };
};

const rankingTypes = {
  'technician-rankings': 0,
  'comfort-consultant-rankings': 1,
  'plumber-rankings': 2,
  'electrician-rankings': 3,
};

const rankingTypeTitles = {
  'technician-rankings': 'Technician Rankings',
  'comfort-consultant-rankings': 'Comfort Consultant Rankings',
  'plumber-rankings': 'Plumber Rankings',
  'electrician-rankings': 'Electrician Rankings',
};

const rankingColumns = (rankingType) => {
  const rankerTitle = {
    'technician-rankings': 'Technican',
    'comfort-consultant-rankings': 'Comfort Consultant',
    'plumber-rankings': 'Plumber',
    'electrician-rankings': 'Electrician',
  }[rankingType];

  const rankerDataIndex = {
    'technician-rankings': 'technicanName',
    'comfort-consultant-rankings': 'comfortConsultantName',
    'plumber-rankings': 'plumberName',
    'electrician-rankings': 'electricianName',
  }[rankingType];

  return [
    {
      title: 'Ranking',
      dataIndex: 'rankNumber',
      key: 'rankNumber',
      width: '10%',
      align: 'center',
      sorter: sortRankNumber('rankNumber'),
    },
    {
      title: 'Contractor Name',
      dataIndex: 'contractorName',
      key: 'contractorName',
      width: '30%',
      sorter: sortString('contractorName'),
    },
    {
      title: rankerTitle,
      dataIndex: rankerDataIndex,
      key: rankerDataIndex,
      width: '20%',
      align: 'center',
      sorter: sortString(rankerDataIndex),
    },
    {
      title: 'Revenue',
      dataIndex: 'revenueText',
      key: 'revenueText',
      width: '20%',
      align: 'right',
      sorter: sortRankNumber('revenueNumber'),
    },
    {
      title: 'Award Level',
      dataIndex: 'awardLevel',
      key: 'awardLevel',
      width: '20%',
      align: 'center',
      sorter: sortString('awardLevel'),
      render: (awardLevel) => {
        if (!awardLevel) {
          return null;
        }
        const colors = { 'above-beyond': 'blue', gold: 'gold', silver: 'silver', bronze: 'volcano' };
        return <Tag color={colors[kebabCase(awardLevel)]}>{awardLevel}</Tag>;
      },
    },
  ];
};

const currentYear = moment().year();
const yearSelections = range(2020, currentYear + 1);
class RankingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      rankingType: null,
      technicianRankingDataSource: [],
      technicianRankingSearchText: '',
      technicianRankingTargetYear: currentYear,
      comfortConsultantRankingDataSource: [],
      comfortConsultantRankingSearchText: '',
      comfortConsultantRankingTargetYear: currentYear,
      plumberRankingDataSource: [],
      plumberRankingSearchText: '',
      plumberRankingTargetYear: currentYear,
      electricianRankingDataSource: [],
      electricianRankingSearchText: '',
      electricianRankingTargetYear: currentYear,
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { rankingType },
      },
    } = this.props;
    this.fetchRankingByTypes(rankingType);
  }

  componentDidUpdate(prevProps, prevState) {
    const { match: { params: { rankingType: prevRankingType } } } = prevProps;
    const { match: { params: { rankingType } } } = this.props;

    const {
      technicianRankingTargetYear: prevTechnicianRankingTargetYear,
      comfortConsultantRankingTargetYear: prevComfortConsultantRankingTargetYear,
      plumberRankingTargetYear: prevPlumberRankingTargetYear,
      electricianRankingTargetYear: prevElectricianRankingTargetYear,
    } = prevState;
    const {
      technicianRankingTargetYear,
      comfortConsultantRankingTargetYear,
      plumberRankingTargetYear,
      electricianRankingTargetYear,
    } = this.state;

    if (
      rankingType !== prevRankingType ||
      technicianRankingTargetYear !== prevTechnicianRankingTargetYear ||
      comfortConsultantRankingTargetYear !== prevComfortConsultantRankingTargetYear ||
      plumberRankingTargetYear !== prevPlumberRankingTargetYear ||
      electricianRankingTargetYear !== prevElectricianRankingTargetYear
    ) {
      this.fetchRankingByTypes(rankingType);
    }
  }

  onChangeTechnicianRankingSearch = (event) => {
    this.setState({
      technicianRankingSearchText: event.target.value,
    });
  };

  onChangeTechnicianRankingTargetYear = (selectedYear) => {
    this.setState({
      technicianRankingTargetYear: selectedYear,
    });
  };

  onChangeComfortConsultantRankingSearch = (event) => {
    this.setState({
      comfortConsultantRankingSearchText: event.target.value,
    });
  };

  onChangeComfortConsultantRankingTargetYear = (selectedYear) => {
    this.setState({
      comfortConsultantRankingTargetYear: selectedYear,
    });
  };

  onChangePlumberRankingSearch = (event) => {
    this.setState({
      plumberRankingSearchText: event.target.value,
    });
  };

  onChangePlumberRankingTargetYear = (selectedYear) => {
    this.setState({
      plumberRankingTargetYear: selectedYear,
    });
  };

  onChangeElectricianRankingSearch = (event) => {
    this.setState({
      electricianRankingSearchText: event.target.value,
    });
  };

  onChangeElectricianRankingTargetYear = (selectedYear) => {
    this.setState({
      electricianRankingTargetYear: selectedYear,
    });
  };

  filterTechnicianRankingDataSource = (dataSource, searchText) => {
    const searchTextLowerCase = searchText.toLowerCase();
    const attributes = ['rankNumber', 'contractorName', 'technicanName', 'revenueText', 'awardLevel'];

    return dataSource.filter((data) => {
      return attributes.some((attr) => (data[attr] || '').toString().toLowerCase().includes(searchTextLowerCase));
    });
  };

  filterComfortConsultantRankingDataSource = (dataSource, searchText) => {
    const searchTextLowerCase = searchText.toLowerCase();
    const attributes = ['rankNumber', 'contractorName', 'comfortConsultantName', 'revenueText'];

    return dataSource.filter((data) => {
      return attributes.some((attr) => (data[attr] || '').toString().toLowerCase().includes(searchTextLowerCase));
    });
  };

  filterPlumberRankingDataSource = (dataSource, searchText) => {
    const searchTextLowerCase = searchText.toLowerCase();
    const attributes = ['rankNumber', 'contractorName', 'plumberName', 'revenueText'];

    return dataSource.filter((data) => {
      return attributes.some((attr) => (data[attr] || '').toString().toLowerCase().includes(searchTextLowerCase));
    });
  };

  filterElectricianRankingDataSource = (dataSource, searchText) => {
    const searchTextLowerCase = searchText.toLowerCase();
    const attributes = ['rankNumber', 'contractorName', 'plumberName', 'revenueText'];

    return dataSource.filter((data) => {
      return attributes.some((attr) => (data[attr] || '').toString().toLowerCase().includes(searchTextLowerCase));
    });
  };

  fetchRankingByTypes(rankingType) {
    this.setState({
      loading: true,
      rankingType,
    });

    if (rankingTypes[rankingType] === 0) {
      const { technicianRankingTargetYear } = this.state;
      fetchTechnicianRankings(technicianRankingTargetYear)
        .then((technicianRankingDataSource) => {
          this.setState({
            technicianRankingDataSource,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.error(error);
        });
    }

    if (rankingTypes[rankingType] === 1) {
      const { comfortConsultantRankingTargetYear } = this.state;
      fetchComfortConsultantRankings(comfortConsultantRankingTargetYear)
        .then((comfortConsultantRankingDataSource) => {
          this.setState({
            comfortConsultantRankingDataSource,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.error(error);
        });
    }

    if (rankingTypes[rankingType] === 2) {
      const { plumberRankingTargetYear } = this.state;
      fetchPlumberRankings(plumberRankingTargetYear)
        .then((plumberRankingDataSource) => {
          this.setState({
            plumberRankingDataSource,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.error(error);
        });
    }

    if (rankingTypes[rankingType] === 3) {
      const { electricianRankingTargetYear } = this.state;
      fetchElectricianRankings(electricianRankingTargetYear)
        .then((electricianRankingDataSource) => {
          this.setState({
            electricianRankingDataSource,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.error(error);
        });
    }
  }

  render() {
    const {
      loading,
      rankingType,
      technicianRankingDataSource,
      technicianRankingSearchText,
      technicianRankingTargetYear,
      comfortConsultantRankingDataSource,
      comfortConsultantRankingSearchText,
      comfortConsultantRankingTargetYear,
      plumberRankingDataSource,
      plumberRankingSearchText,
      plumberRankingTargetYear,
      electricianRankingDataSource,
      electricianRankingSearchText,
      electricianRankingTargetYear,
    } = this.state;

    const filteredTechnicianRankingDataSource = this.filterTechnicianRankingDataSource(
      technicianRankingDataSource,
      technicianRankingSearchText,
    );
    const filteredComfortConsultantRankingDataSource = this.filterComfortConsultantRankingDataSource(
      comfortConsultantRankingDataSource,
      comfortConsultantRankingSearchText,
    );
    const filteredPlumberRankingDataSource = this.filterPlumberRankingDataSource(
      plumberRankingDataSource,
      plumberRankingSearchText,
    );
    const filteredElectricianRankingDataSource = this.filterElectricianRankingDataSource(
      electricianRankingDataSource,
      electricianRankingSearchText,
    );

    if (rankingType !== null && isNil(rankingTypes[rankingType])) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    return (
      <LayoutContainer contentClassName={['content-container', 'ranking-container']}>
        <Title level={2} className="content-container__title">
          {rankingTypeTitles[rankingType]}
        </Title>
        <Breadcrumb
          items={[
            { key: 'home', link: '/', title: 'Home' },
            { key: `rankings/${rankingType}`, title: rankingTypeTitles[rankingType] },
          ]}
          className="content-container__breadcrumb"
        />
        {rankingTypes[rankingType] === 0 && (
          <div className="content-container__section">
            <Row>
              <Col flex="1" />
              <Col flex="0">
                <Space style={{ marginBottom: 10 }}>
                  <Select
                    placeholder="Select year"
                    optionFilterProp="children"
                    disabled={loading}
                    value={technicianRankingTargetYear}
                    onChange={this.onChangeTechnicianRankingTargetYear}
                  >
                    {yearSelections.map((year) => (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    ))}
                  </Select>
                  <Search
                    enterButton
                    placeholder="Search"
                    style={{ width: 300 }}
                    value={technicianRankingSearchText}
                    onChange={this.onChangeTechnicianRankingSearch}
                    disabled={loading}
                  />
                </Space>
              </Col>
            </Row>
            <Table
              rowKey="rankNumber"
              dataSource={filteredTechnicianRankingDataSource}
              columns={rankingColumns(rankingType)}
              pagination={false}
              loading={loading}
              scroll={{
                x: 'max-content',
              }}
            />
          </div>
        )}
        {rankingTypes[rankingType] === 1 && (
          <div className="content-container__section">
            <Row>
              <Col flex="1" />
              <Col flex="0">
                <Space style={{ marginBottom: 10 }}>
                  <Select
                    placeholder="Select year"
                    optionFilterProp="children"
                    disabled={loading}
                    value={comfortConsultantRankingTargetYear}
                    onChange={this.onChangeComfortConsultantRankingTargetYear}
                  >
                    {yearSelections.map((year) => (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    ))}
                  </Select>
                  <Search
                    enterButton
                    placeholder="Search"
                    style={{ width: 300 }}
                    value={comfortConsultantRankingSearchText}
                    onChange={this.onChangeComfortConsultantRankingSearch}
                    disabled={loading}
                  />
                </Space>
              </Col>
            </Row>
            <Table
              rowKey="rankNumber"
              dataSource={filteredComfortConsultantRankingDataSource}
              columns={rankingColumns(rankingType)}
              pagination={false}
              loading={loading}
              scroll={{
                x: 'max-content',
              }}
            />
          </div>
        )}
        {rankingTypes[rankingType] === 2 && (
          <div className="content-container__section">
            <Row>
              <Col flex="1" />
              <Col flex="0">
                <Space style={{ marginBottom: 10 }}>
                  <Select
                    placeholder="Select year"
                    optionFilterProp="children"
                    disabled={loading}
                    value={plumberRankingTargetYear}
                    onChange={this.onChangePlumberRankingTargetYear}
                  >
                    {yearSelections.map((year) => (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    ))}
                  </Select>
                  <Search
                    enterButton
                    placeholder="Search"
                    style={{ width: 300 }}
                    value={plumberRankingSearchText}
                    onChange={this.onChangePlumberRankingSearch}
                    disabled={loading}
                  />
                </Space>
              </Col>
            </Row>
            <Table
              rowKey="rankNumber"
              dataSource={filteredPlumberRankingDataSource}
              columns={rankingColumns(rankingType)}
              pagination={false}
              scroll={{
                x: 'max-content',
              }}
              loading={loading}
            />
          </div>
        )}
        {rankingTypes[rankingType] === 3 && (
          <div className="content-container__section">
            <Row>
              <Col flex="1" />
              <Col flex="0">
                <Space style={{ marginBottom: 10 }}>
                  <Select
                    placeholder="Select year"
                    optionFilterProp="children"
                    disabled={loading}
                    value={electricianRankingTargetYear}
                    onChange={this.onChangeElectricianRankingTargetYear}
                  >
                    {yearSelections.map((year) => (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    ))}
                  </Select>
                  <Search
                    enterButton
                    placeholder="Search"
                    style={{ width: 300 }}
                    value={electricianRankingSearchText}
                    onChange={this.onChangeElectricianRankingSearch}
                    disabled={loading}
                  />
                </Space>
              </Col>
            </Row>
            <Table
              rowKey="rankNumber"
              dataSource={filteredElectricianRankingDataSource}
              columns={rankingColumns(rankingType)}
              pagination={false}
              scroll={{
                x: 'max-content',
              }}
              loading={loading}
            />
          </div>
        )}
      </LayoutContainer>
    );
  }
}

const mapStateToProps = () => ({});

export default withRouter(connect(mapStateToProps, {})(RankingContainer));
