import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Button, Space, Form, InputNumber, Select, Divider, Modal } from 'antd';
import { round, isNil } from 'lodash';

import ErrorMessage from '@/components/ErrorMessage';
import { capitalize } from '@/utils/stringUtils';
import { displayCurrency } from '@/utils/numberUtils';
import { selectSearchByChildrenText } from '@/utils/selectUtils';

const { Option } = Select;
const { Paragraph, Title, Text } = Typography;

const currentYear = new Date().getFullYear();
const yearSelectOptions = [...new Array(4)].map((_, i) => currentYear + i);
const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

const GoalForm = (props) => {
  const {
    submitting,
    submitTitle,
    formObject,
    formErrors,
    canSelectUser,
    canSelectOrganization,
    userSelectOptions,
    organizationSelectOptions,
    goalStrategySelectOptions,
    onChangeOrganization,
    onCancel,
    onSubmit,
    mode,
  } = props;

  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);
  const [selectedYear, setSelectedYear] = useState(false);
  const [selectedGoalStrategy, setSelectedGoalStrategy] = useState(false);
  const [assignedTotalAmount, setAssignedTotalAmount] = useState(false);
  const [usedGoalStrategieSelectOptions, setUsedGoalStrategieSelectOptions] = useState([]);

  useEffect(() => {
    if (!updated) {
      form.resetFields();
    }
  }, [form, formObject, updated]);

  useEffect(() => {
    if (formObject.year) {
      setSelectedYear(true);
      const filteredGoalStrategies = goalStrategySelectOptions.filter(
        ({ year }) => isNil(year) || year === formObject.year,
      );
      setUsedGoalStrategieSelectOptions(filteredGoalStrategies);
    }
    if (formObject.goalStrategyId) {
      setSelectedGoalStrategy(true);
    }
    if (formObject.total) {
      setAssignedTotalAmount(true);
    }
  }, [formObject, goalStrategySelectOptions]);

  const updateAmountAndPercentageBaseOnGoalStrategy = (goalStrategyId, totalAmount) => {
    const targetGoalStrategy = goalStrategySelectOptions.find(({ id }) => `${id}` === `${goalStrategyId}`);
    const percentages = months.map(month => targetGoalStrategy ? targetGoalStrategy[month] : 0);
    const amounts = months.map((month, index) => totalAmount * (percentages[index] || 0) / 100);

    const updatedFieldsValue = months.reduce((memo, month, index) => ({
      ...memo,
      [month]: amounts[index],
      goalStrategy: {
        ...memo.goalStrategy,
        [month]: percentages[index],
      }
    }), {});
    form.setFieldsValue(updatedFieldsValue);
  };

  const handleValuesChanged = (changedValues, values) => {
    setUpdated(true);

    if (values.year) {
      setSelectedYear(true);
    }
    if (values.goalStrategyId) {
      setSelectedGoalStrategy(true);
    }
    if (values.total) {
      setAssignedTotalAmount(true);
    }

    if (changedValues.year) {
      const filteredGoalStrategies = values.year
        ? goalStrategySelectOptions.filter(({ year }) => isNil(year) || year === values.year)
        : goalStrategySelectOptions;
      setUsedGoalStrategieSelectOptions(filteredGoalStrategies);
    }

    if (
      changedValues.goalStrategyId ||
      changedValues.total && (!!values.goalStrategyId &&
        !!usedGoalStrategieSelectOptions.find(
          ({ id }) => `${id}` === `${values.goalStrategyId}`
        )?.isGlobal === true)
    ) {
      updateAmountAndPercentageBaseOnGoalStrategy(values.goalStrategyId, values.total);
    }

    for (const month of months) {
      if (changedValues[month]) {
        const totalValue = values.total || 0;
        const amountValue = values[month] || 0;
        const percentageValue = round(totalValue ? amountValue * 100 / totalValue : 0, 2);

        form.setFieldsValue({
          goalStrategy: {
            [month]: percentageValue,
          },
        });
      } else if (changedValues.goalStrategy && changedValues.goalStrategy[month]) {
        const totalValue = values.total || 0;
        const percentageValue = changedValues.goalStrategy[month] || 0;
        const amountValue = totalValue * percentageValue / 100;

        form.setFieldsValue({
          [month]: amountValue,
        });
      }
    }

    if (changedValues.organizationId) {
      onChangeOrganization && onChangeOrganization(changedValues.organizationId);
    }
  };

  const handleSubmit = (values) => {
    let totalAmount = 0;
    let totalPercentage = 0;

    months.forEach((month) => {
      totalAmount += values[month] || 0;
      totalPercentage += values.goalStrategy[month] || 0;
    });
    if (totalAmount !== values.total || round(totalPercentage) !== 100) {
      Modal.error({
        title: 'Submission Error',
        content: (
          <Space direction='vertical'>
            <Text>Total amount or percent is incorrect!</Text>
            <Text type="warning">Total amount: {displayCurrency(totalAmount)}</Text>
            <Text type="warning">Total percent: {round(totalPercentage)}%</Text>
          </Space>
        ),
      });
      return;
    }
    onSubmit(values);
  };

  const isEditMode = mode === 'edit';
  const disabledGoalStrategySelect = !selectedYear;
  const disabledTotalAmountInput = !selectedYear || !selectedGoalStrategy;
  const disabledAmountOrPercentageInput =
    !selectedYear ||
    !selectedGoalStrategy ||
    (selectedGoalStrategy &&
      !!usedGoalStrategieSelectOptions.find(
        ({ id }) => `${id}` === `${form.getFieldValue('goalStrategyId')}`
      )?.isGlobal === true) ||
    !assignedTotalAmount;

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={formObject}
      onValuesChange={handleValuesChanged}
      colon={false}
      layout="vertical"
      className="content-container__form"
      hideRequiredMark
      scrollToFirstError
    >
      <ErrorMessage
        errors={formErrors}
      />
      <Paragraph>
        Please input the information below:
      </Paragraph>
      <Form.Item
        name="organizationId"
        label="Contractor"
        rules={[{
          required: true,
          message: 'Contractor is required'
        }]}
        hasFeedback
      >
        <Select
          showSearch
          disabled={!canSelectOrganization}
          placeholder="Select contractor"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
        >
          {organizationSelectOptions.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="userId"
        label="User"
        rules={[{
          required: true,
          message: 'User is required',
        }]}
        hasFeedback
      >
        <Select
          placeholder="Select user"
          optionFilterProp="children"
          filterOption={selectSearchByChildrenText}
          disabled={!canSelectUser || isEditMode}
        >
          {userSelectOptions.map((user) => (
            <Option key={user.id} value={user.id}>
              {user.firstName} {user.lastName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="year"
        label="Year"
        rules={[{ required: true, message: 'Year is required' }]}
        hasFeedback
      >
        <Select
          placeholder="Select year"
          optionFilterProp="children"
          disabled={isEditMode}
        >
          {yearSelectOptions.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="goalStrategyId"
        label="Goal Settings"
        rules={[{ required: true, message: 'Goal settings is required' }]}
        hasFeedback
      >
        <Select
          placeholder="Select goal settings"
          optionFilterProp="children"
          disabled={disabledGoalStrategySelect}
        >
          {usedGoalStrategieSelectOptions.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="total"
        label="Total"
        rules={[
          {
            required: true,
            message: 'Total amount is required',
          },
        ]}
        hasFeedback
        className='form-item-currency'
      >
        <InputNumber
          min={0}
          disabled={disabledTotalAmountInput}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Divider />
      <Title level={4}>Monthly Goals</Title>
      <Row gutter={24}>
        <Col span={12}>
          {months.map((month) => (
            <Form.Item
              key={month}
              name={month}
              label={capitalize(month)}
              rules={[
                {
                  required: true,
                  message: 'Amount value is required',
                },
              ]}
              hasFeedback
              className='form-item-currency'
            >
              <InputNumber
                min={0}
                disabled={disabledAmountOrPercentageInput}
                style={{ width: '100%' }}
              />
            </Form.Item>
          ))}
        </Col>
        <Col span={12}>
          {months.map((month) => (
            <Form.Item
              key={month}
              name={['goalStrategy', month]}
              label={capitalize(month)}
              rules={[
                {
                  required: true,
                  message: 'Percentage value is required',
                },
              ]}
              hasFeedback
              className='form-item-percentage'
            >
              <InputNumber
                min={0}
                disabled={disabledAmountOrPercentageInput}
                style={{ width: '100%' }}
              />
            </Form.Item>
          ))}
        </Col>
      </Row>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {submitTitle || 'Submit'}
          </Button>
          {onCancel && (
            <Button htmlType="button" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

GoalForm.propTypes = {
  submitting: PropTypes.bool,
  submitTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  formObject: PropTypes.object,
  formErrors: PropTypes.arrayOf(PropTypes.string),
  canSelectUser: PropTypes.bool,
  canSelectOrganization: PropTypes.bool,
  userSelectOptions: PropTypes.arrayOf(PropTypes.object),
  goalStrategySelectOptions: PropTypes.arrayOf(PropTypes.object),
  organizationSelectOptions: PropTypes.arrayOf(PropTypes.object),
  onChangeOrganization: PropTypes.func,
  mode: PropTypes.string,
};

export default GoalForm;
